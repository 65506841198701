<template>
  <div class="contact-form-section pb-100">
    <div class="container">
      <div class="contact-form-box">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-12">
            <iframe width="100%" height="100%" id="gmap_canvas"
              src="https://maps.google.com/maps?q=%20PIXSelect%20it%C3%BC&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <div class="contact-form-item desk-pad-left-40">
              <div class="section-title text-start section-title-left">
                <h2>Let's Get In Touch!</h2>
                <p>
                  We are always open to new collaborations and ready to answer any questions you may have.
                </p>
              </div>
              <div class="form-content">
                <div class="form-group mb-20">
                  <label>Name</label>
                  <input type="text" name="name_surname" id="name_surname" v-model="form.name_surname"
                    class="form-control" :class="{ 'is-invalid': v$.form.name_surname.$error }" placeholder="Your Name*" />
                  <div class="input-errors" v-for="(error, index) of v$.form.name_surname.$errors" :key="index">
                    <small class="text-danger">{{
                      error.$message == ("Value is required") ? "Name is required" :
                        error.$message
                    }} </small>
                  </div>
                </div>
                <div class="form-group mb-20">
                  <label>Email</label>
                  <input type="text" name="email" id="email" v-model="form.email" class="form-control" :class="{ 'is-invalid': v$.form.email.$error }"
                    placeholder="Your Email*" />
                  <div class="input-errors" v-for="(error, index) of v$.form.email.$errors" :key="index">
                    <small class="text-danger">{{
                      error.$message == "Value is required" ? "Email is required" :
                        error.$message
                    }}</small>
                  </div>
                </div>
                <div class="form-group mb-20">
                  <label>Phone</label>
                  <input type="text" name="phone" id="phone" v-model="form.phone" class="form-control" :class="{ 'is-invalid': v$.form.phone.$error }"
                    placeholder="Your Phone*" />
                    <div class="input-errors" v-for="(error, index) of v$.form.phone.$errors" :key="index">
                    <small class="text-danger">{{
                      error.$message == ("Value is required") ? "Name is required" :
                        error.$message
                    }} </small>
                  </div>
                </div>
                <div class="form-group mb-20">
                  <label>Subject</label>
                  <input type="text" name="subject" id="subject" v-model="form.subject" class="form-control" :class="{ 'is-invalid': v$.form.subject.$error }"
                    placeholder="Your Subject*" />
                  <div class="input-errors" v-for="(error, index) of v$.form.subject.$errors" :key="index">
                    <small class="text-danger">{{
                      error.$message == "Value is required" ? "Subject is required" :
                        error.$message
                    }}</small>
                  </div>
                </div>
                <div class="form-group mb-20">
                  <label>Question</label>
                  <textarea name="question" class="form-control" :class="{ 'is-invalid': v$.form.question.$error }" id="question" v-model="form.question" rows="3"
                    placeholder="Your Question*"></textarea>
                  <div class="input-errors" v-for="(error, index) of v$.form.question.$errors" :key="index">
                    <small class="text-danger">{{
                      error.$message == "Value is required" ? "Question is required" :
                        error.$message
                    }}</small>
                  </div>
                </div>
                <div class="form-check mb-20">
                  <input v-model="form.isPrivacyPolicy" class="form-check-input" :class="{ 'is-invalid': v$.form.isPrivacyPolicy.$error }" type="checkbox" id="isPrivacyPolicy" />
                  <label for="contact1">
                    <span data-bs-toggle="modal" data-bs-target="#termsOfService" style="color: #1f12fd">Terms of
                      Service</span>
                    <!-- Terms of Service -->
                    <div class="modal fade" id="termsOfService" tabindex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <TermsOfService />
                    </div>
                    and
                    <span data-bs-toggle="modal" data-bs-target="#privacyPolicy" style="color: #1f12fd">Privacy
                      Policy</span>
                    <!-- Privacy Policy -->
                    <div class="modal fade" id="privacyPolicy" tabindex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <PrivacyPolicy />
                    </div>
                  </label>
                  <div class="input-errors" v-for="(error, index) of v$.form.isPrivacyPolicy.$errors" :key="index">
                    <small class="text-danger ms-4">{{
                      error.$message == "The value must be equal to the other value" ?
                        "Please read and click !" : error.$message
                    }}</small>
                  </div>
                </div>
                <div class="form-button">
                  <button class="btn main-btn full-width" type="submit" @click="sendMessageBtn">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MainService from "../../services/Main.service";
import PrivacyPolicy from "../../components/Privacy/PrivacyPolicy.vue";
import TermsOfService from "../../components/Privacy/TermsOfService.vue";
import { mapActions } from "vuex";
import { createToast } from "mosha-vue-toastify";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "ContactForm",
  components: {
    PrivacyPolicy,
    TermsOfService,
  },
  data() {
    return {
      form: {
        name_surname: "",
        phone: "",
        email: "",
        subject: "",
        question: "",
        isPrivacyPolicy: undefined,
      },
      validation_property_errors: []
    };
  },
  validations() {
    return {
      form: {
        name_surname: { required },
        phone: {required },
        email: { required, email },
        subject: { required },
        question: { required },
        isPrivacyPolicy: { sameAs: sameAs(true) }
      },
    };
  },
  computed() { },
  methods: {
    ...mapActions("main", ["postContactForm"]),
    sendMessageBtn() {
      this.v$.$touch()


      if (!this.v$.form.$invalid) {
        this.postContactForm(this.form)
          .then((res) => {
            createToast(res.message, {
              toastBackgroundColor: "#28a745",
              type: "success",
            });
          })
          .then(() => {
            this.form = {
              name_surname: "",
              phone: "",
              email: "",
              subject: "",
              question: "",
              isPrivacyPolicy: undefined,
            };
            this.v$.$reset()
          })
          .catch((res) => {
            createToast(res.response.data.message, {
              toastBackgroundColor: "#dc3545",
              type: "danger",
            });
          });

      }

    },
  },
};
</script>

