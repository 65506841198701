<template>
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <div class="row text-center">
                    <h1 class="modal-title fs-5 " id="exampleModalLabel">
                        Privacy Policy
                    </h1>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-start ">
                <div class="all">
                    <div class="row">
                        <!-- <h5>Privacy Policy</h5> -->
                        <p class="text-start">
                            PIXSelect Tech. Inc built this Platform app as a application. This SERVICE is provided by
                            PIXSelect
                            Technology Inc at no cost and is intended for use as is. This page is used to inform
                            visitors regarding
                            our policies with the collection, use, and disclosure of Personal Information if anyone
                            decided to use
                            our Service. If you choose to use our Service, then you agree to the collection and use of
                            information
                            in relation to this policy. The Personal Information that we collect is used for providing
                            and improving
                            the Service. We will not use or share your information with anyone except as described in
                            this Privacy
                            Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and
                            Conditions,
                            which is accessible at our Platform unless otherwise defined in this Privacy Policy.
                        </p>
                    </div>
                    <div class="row">
                        <h5>Information Collection and Use</h5>
                        <div class="row">
                            <p>
                                For a better experience, while using our Service, we may require you to provide us with
                                certain
                                personally identifiable information, including but not limited to Name, Sirname, Device
                                ID, User
                                Image, Identitity Card Information. The information that we request will be retained by
                                us and used
                                as described in this privacy policy.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Log Data</h5>
                        <div class="row">
                            <p>
                                We want to inform you that whenever you use our Service, in a case of an error in the
                                app we collect
                                data and information (through third party products) on your phone called Log Data. This
                                Log Data may
                                include information such as your device Internet Protocol (“IP”) address, device name,
                                operating
                                system version, the configuration of the app when utilizing our Service, the time and
                                date of your
                                use of the Service, and other statistics.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Information Collection and Use</h5>
                        <div class="row">
                            <p>
                                For a better experience, while using our Service, we may require you to provide us with
                                certain
                                personally identifiable information, including but not limited to Name, Sirname, Device
                                ID, User
                                Image, Identitity Card Information. The information that we request will be retained by
                                us and used
                                as described in this privacy policy.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Cookies</h5>
                        <div class="row">
                            <p>
                                Cookies are files with a small amount of data that are commonly used as anonymous unique
                                identifiers. These are sent to your browser from the websites that you visit and are
                                stored on your
                                device's internal memory. This Service does not use these “cookies” explicitly. However,
                                the app may
                                use third party code and libraries that use “cookies” to collect information and improve
                                their
                                services. You have the option to either accept or refuse these cookies and know when a
                                cookie is
                                being sent to your device. If you choose to refuse our cookies, you may not be able to
                                use some
                                portions of this Service.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Service Providers</h5>
                        <div class="row">
                            <p>
                                We may employ third-party companies and individuals due to the following reasons:
                            </p>
                            <ul>
                                <li>
                                    To facilitate our Service;
                                </li>
                                <li>
                                    To provide the Service on our behalf;
                                </li>
                                <li>
                                    To perform Service-related services; or
                                </li>
                                <li>
                                    To assist us in analyzing how our Service is used.
                                </li>
                            </ul>
                            <p>
                                We want to inform users of this Service that these third parties have access to your
                                Personal
                                Information. The reason is to perform the tasks assigned to them on our behalf. However,
                                they are
                                obligated not to disclose or use the information for any other purpose.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Security</h5>
                        <div class="row">
                            <p>
                                We value your trust in providing us your Personal Information, thus we are striving to
                                use commercially acceptable means of protecting it. But remember that no method of
                                transmission over the internet, or method of electronic storage is 100% secure and
                                reliable, and we cannot guarantee its absolute security.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Links to Other Sites</h5>
                        <div class="row">
                            <p>
                                This Service may contain links to other sites. If you click on a third-party link, you
                                will be directed to that site. Note that these external sites are not operated by us.
                                Therefore, we strongly advise you to review the Privacy Policy of these websites. We
                                have no control over and assume no responsibility for the content, privacy policies, or
                                practices of any third-party sites or services.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Children’s Privacy</h5>
                        <div class="row">
                            <p>
                                These Services do not address anyone under the age of 18. We do not knowingly collect
                                personally identifiable information from children under 18 years of age. In the case we
                                discover that a child under 18 has provided us with personal information, we immediately
                                delete this from our servers. If you are a parent or guardian and you are aware that
                                your child has provided us with personal information, please contact us so that we will
                                be able to do necessary actions..
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Changes to This Privacy Policy</h5>
                        <div class="row">
                            <p>
                                We may update our Privacy Policy from time to time. Thus, you are advised to review this
                                page periodically for any changes. We will notify you of any changes by posting the new
                                Privacy Policy on this page. This policy is effective as of 2021-04-19
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <h5>Contact Us</h5>
                        <div class="row">
                            <p>
                                If you have any questions or suggestions about our Privacy Policy, do not hesitate to
                                contact us at <a href="mailto:solution.center@pixselect.io">solution.center@pixselect.io</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>

</template>

<script>
export default {

}
</script>

<style scoped>
.all {
    color: black;
}

p {
    color: black;
    font-weight: normal !important;
    padding-left: 20px;
    text-align: justify;
}

ul {
    padding-left: 70px;
}

h5 {
    padding-left: 40px;
}
</style>