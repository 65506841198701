<template>
  <div @submit.prevent="onSubmit">
    <div class="row d-flex justify-content-center mt-30">
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label>Email</label
          ><input
            class="form-control"
            placeholder="Enter email"
            type="email"
            v-model="formAcademy.email"
            name="email"
            id="email"
            :class="{ 'is-invalid': v$.formAcademy.email.$error }"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.email.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message == "Value is required"
                ? "Email is require"
                : error.$message ||
                  error.$message == "Value is not a valid email address"
                ? "Email is not a valid email address"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group mb-20">
          <label for=""> Password</label
          ><input
            class="form-control"
            placeholder="Password"
            type="password"
            v-model="formAcademy.password"
            name="password"
            id="password"
            :class="{ 'is-invalid': v$.formAcademy.password.$error }"
          />
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.password.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message ==
              "This field should be at least 8 characters long"
                ? "Password should be at least 8 characters long"
                : error.$message || error.$message == "Value is required"
                ? "Password is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-20">
          <label for="">Confirm Password</label
          ><input
            class="form-control"
            placeholder="Confirm Password"
            type="password"
            v-model="formAcademy.confirmPassword"
            name="confirmPassword"
            id="confirmPassword"
            :class="{ 'is-invalid': v$.formAcademy.confirmPassword.$error }"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.confirmPassword.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message == "The value must be equal to the other value"
                ? "Confirm Password must be equal to the other value"
                : error.$message || error.$message == "Value is required"
                ? "Confirm Password is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <div class="form-check">
            <input
              v-model="formAcademy.isPrivacyPolicy"
              class="form-check-input"
              type="checkbox"
              id="check"
              :class="{ 'is-invalid': v$.formAcademy.isPrivacyPolicy.$error }"
            />
            <label class="form-check-label">
              By clicking the button, you agree to the
              <span
                data-bs-toggle="modal"
                data-bs-target="#termsOfService"
                style="color: #1f12fd"
                >Terms of Service</span
              >
              <!-- Terms of Service -->
              <div
                class="modal fade"
                id="termsOfService"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <TermsOfService />
              </div>
              and
              <span
                data-bs-toggle="modal"
                data-bs-target="#privacyPolicy"
                style="color: #1f12fd"
                >Privacy Policy</span
              >
              <!-- Privacy Policy -->
              <div
                class="modal fade"
                id="privacyPolicy"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <PrivacyPolicy />
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <button
            class="btn demo-request-form-btn"
            type="submit"
            @click="onSubmit"
            :disabled="!formAcademy.isPrivacyPolicy"
          >
            <h5 class="m-0">NEXT</h5>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength, sameAs } from "@vuelidate/validators";
import { mapActions, mapState } from "vuex";

import PrivacyPolicy from "../../components/Privacy/PrivacyPolicy.vue";
import TermsOfService from "../../components/Privacy/TermsOfService.vue";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },

  name: "RegisterForm",
  components: {
    PrivacyPolicy,
    TermsOfService,
  },
  data() {
    return {};
  },

  validations() {
    return {
      formAcademy: {
        email: { required, email },
        password: {
          required,
          min: minLength(8),
        },
        confirmPassword: {
          required,
          sameAs: sameAs(this.formAcademy.password),
        },
        isPrivacyPolicy: { required }
      },
    };
  },

  methods: {
    ...mapActions("main", ["changeTabForm"]),
    onSubmit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.changeTabForm(1);
      }
    },
  },
  computed: {
    ...mapState("main", ["formAcademy"]),
  },
});
</script>
