<template>
  <div class="container pt-60 pb-80">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="mission-card mb-20">
          <img
            src="../../assets/images/saas-candidates/mission.png"
            alt="shape"
          />
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
        <div class="mission-card mb-20">
          <p>
            We're sheddingC traditional hiring and changing it for the better
            for candidates, teams, companies and ourselves.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="mission-card mb-20">
          <img
            src="../../assets/images/saas-candidates/vision.png"
            alt="shape"
          />
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 d-flex align-items-end">
        <div class="mission-card mb-20">
          <p>
            Our vision is to be a reliable technology partner globally by using
            our effective collaboration and inclusive support methods to create
            the best technology teams from Turkey to the global.
          </p>
          <p class="mb-0 mt-20">
            We believe that the recruitment industry can manage it better. We
            adopt modern methods and approaches to challenge the mediocrity of
            our industry.
          </p>
        </div>
      </div>
    </div>
    <div class="row pt-30">
      <div class="col-12 col-md-12 col-lg-6 pt-30">
        <div class="section-title section-title-sm">
          <h2>How Does? “WE”</h2>
        </div>
        <div class="mission-info-card">
          <p>
            We find impressive talents who aspire to be in the top 1% in their
            field or who aspire to be part of the most successful teams from top
            global companies.
          </p>
          <p>
            After filling out a detailed form, we will now know how talented he
            is.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 pt-30">
        <div class="section-title section-title-sm">
          <h2>How Does? “YOU”</h2>
        </div>
        <div class="mission-info-card">
          <p>
            Chances are, you're pretty busy in your technical expertise. You are
            working for yourself or someone else or are actively looking for the
            next step in your career.
          </p>
          <p>
            Maybe you're pretty comfortable in your current role, but you want
            to know how competitive your salary really is...
          </p>
          <p>
            But the thing is, PIXSelect has a career plan that will expand your
            career options.
          </p>
        </div>
      </div>
    </div>
  
  </div>
  <BlogSaasCandidates class="pb-160" />
</template>

<script>
import BlogSaasCandidates from "../SingleBlog/BlogSaasCandidates.vue";
export default {
  name: "SaasCandidatesMission",
  components: { BlogSaasCandidates },
};
</script>
