<template>
    <div class="faq-section pt-100 pb-70">
        <div class="section-title section-title-xl pb-30">
      <h2>FAQ</h2>
    </div>

        <div class="container">
            <accordion>
                <div class="row">
                    <div class="col-lg-6 pb-30">
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    What is AIVISIONTRAINER and what does it do?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    AIVISIONTRAINER is a platform that enables you to efficiently label, train and push visual data sets to edge devices. This platform helps you accelerate your visual AI projects such as image recognition and object detection.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    Do I need technical knowledge to use AIVISIONTRAINER?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    No, AIVISIONTRAINER has a user-friendly interface and you don't need to have a technical background. The platform allows users to easily label, start model training and see results.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    Which data types can I process with AIVISIONTRAINER?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    AIVISIONTRAINER allows you to work on image and video data. It supports a wide range of visual data types that you can use in image recognition, object detection, sentiment analysis and similar projects.
                                </div>
                            </template>
                        </accordion-item>
                       
                    </div>
                    <div class="col-lg-6 pb-30">
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    Can I use AIVISIONTRAINER for free?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    AIVISIONTRAINER offers a free trial version, but there are paid subscription options for more processing capacity and features. The free version is ideal for trying out the platform and building basic projects.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    How can I evaluate results with AIVISIONTRAINER?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    AIVISIONTRAINER provides metrics, visual results and reports to measure the performance of your model. This helps you understand which areas you need to focus on to improve your model.
                                </div>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    How can I train an object recognition model with AIVISIONTRAINER?
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <div class="accordion-body">
                                    AIVISIONTRAINER makes it easy to upload your labeled data, configure your model and start the training process. You can also experiment with different model architectures and observe the results.
                                </div>
                            </template>
                        </accordion-item>
                    </div>
                </div>
            </accordion>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem,
    },
}
</script>