<template>
    <div>
        <Navbar class="bg-white" />
        <page-title-for-academy pageTitle="PIXCoding" />
        <!-- <Projects />
        <Newsletter /> -->
        <pix-academy />
        <Cart />
        <br>
        <academy-blog/>
        <career/>
        <br> <br>
        <process-academy />
        <blog-academy /> <br> <br>
        <clients-academy />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../../Layout/Navbar'
import PageTitleForAcademy from '../../Common/PageTitleForAcademy.vue'
// import Projects from '../Projects/Projects'
// import Newsletter from '../Common/Newsletter'
import PixAcademy from "../../Pages/pixAcademy.vue"
import Footer from '../../Layout/Footer'
import Cart from '../../Cart/cartAcademy.vue'
import academyBlog from "../../Blog/academyBlog.vue"
import Career from "../../Career/careerAcademy.vue"
import ClientsAcademy from "../../OurClients/ClientsAcademy.vue";
import ProcessAcademy from "../../HomeTwo/ProcessAcademy.vue";
import BlogAcademy from "../../SingleBlog/BlogAcademy.vue";

export default {
    name: 'FrontendBackend',
    components: {
        Navbar,
        PageTitleForAcademy,
        Cart,
        PixAcademy,
        academyBlog,
        Career,
        ClientsAcademy,
        ProcessAcademy,
        BlogAcademy,
        // Projects,
        // Newsletter,
        Footer,
    }
}
</script>