<template>
    <div class="container pt-60">
        <div class="section-title section-title-xl pb-30">
            <h2>Why Choose AIVISIONTRAINER?</h2>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Cloud-based Flexibility</h5>
                            <p>
                                As a cloud-based platform, it allows you to reduce your costs as well as speed up your data labeling processes. Without the need for costly infrastructure investment, you can quickly and easily launch your projects and realize end-to-end product creation phases.
                            </p>
                            <br>
                        </div>
                    </div>

                    <img src="../../assets/images/1.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Easy and Secure</h5>
                            <p>
                                It is an application that ensures your data security in data tagging and is extremely simple to use. It enables individuals working on the project to use both photo data and video data, and provides reports on tagging processes.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/2.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Low Cost </h5>
                            <p>
                                In addition to labeling quickly without investing in a server, you can also significantly reduce your costs. After data labeling, you can also perform data training processes from the same platform, making it more efficient and cost-effective, and you can perform more work with fewer resources.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/3.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>End-to-End Workflow</h5>
                            <p>
                                End-to-End Workflow: Enables you to easily create and automate multi-step data tagging projects. It allows you to manage the tagging process end-to-end, such as automatic photo data parsing of videos, distribution of photo aggregate data to the people working on the project, training of the processed data, and transfer of this data to local end devices.
                            </p>

                        </div>
                    </div>
                    <img src="../../assets/images/4.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Easy Integration</h5>
                            <p>
                                AIVISIONTRAINER seamlessly integrates with your existing workflows.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/5.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>

        </div>


    </div>
</template>
  
<script>
export default {
    name: "AiDeveloperAwaitYou",
};
</script>
  