<template>
  <div class="container pt-60">
    <div class="section-title section-title-xl pb-30">
      <h2>What Awaits You During The IT Support Specialist Training Process</h2>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              Maximum 2 hours of project meeting per week (with Academician)
            </h5>
          </div>
          <img
            src="../../assets/images/1.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              Distribution and assignment of team tasks (12 hours - 3
              Supervisors)
            </h5>
          </div>
          <img
            src="../../assets/images/2.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>Technical training application (30 hours - 3 Supervisors)</h5>
          </div>
          <img
            src="../../assets/images/3.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>Daily 15-minute scrum meeting (5 hours) with the team</h5>
          </div>
          <img
            src="../../assets/images/4.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              Interview and challenge avaluation with the students to be
              selected for the project
            </h5>
          </div>
          <img
            src="../../assets/images/5.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>
              Weekly supervision of the codes written within the project by the
              supervisor
            </h5>
          </div>
          <img
            src="../../assets/images/6.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
          >
            <h5>Post-Academy certification</h5>
          </div>
          <img
            src="../../assets/images/7.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features d-flex align-items-center">
            <h5>Providing employment to students who graduate successfully</h5>
          </div>
          <img
            src="../../assets/images/8.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItSupportAwaitYou",
};
</script>
