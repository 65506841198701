<template>
  <div class="pricing-section pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>Use Cases</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase">
            <img src="../../assets/images/usecase-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase-title">
            <h4>Security for ATM Operations</h4>
          </div>
          <div class="pricing-card-usecase-problem">
            <div class="pricing-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="pricing-card-usecase-brief-atm">
              <p>
                According to bank regulation, any cctv record stops and/or scene
                change
                occurs due to an incident; this incident detail has to be
                reported clearly
                defined to Banking Regulations & Supervision Agency, such as it
                happens
                because of video loss, video tamper, video parameter change etc.
              </p>
            </div>
          </div>
          <div class="pricing-card-usecase-solution">
            <div class="pricing-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="pricing-card-usecase-brief-atm">
              <p>
                In order to manage and control the details of these incidents
                from cctv recorders
                and cameras’ security professionals use PIXSVMS CCTV Monitoring
                ATM solution to
                overcome these situations instantly.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase">
            <img src="../../assets/images/usecase-2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase-title">
            <h4>Security for Bank Head Office & Branches</h4>
          </div>
          <div class="pricing-card-usecase-problem">
            <div class="pricing-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="pricing-card-usecase-brief-bank">
              <p>
                Controlling and managing thousands of cameras in bank braches is
                really a problem; also when electronic alarm is triggered,
                security professionals & bank managers have to access cctv
                system for a live footage to verify this incident.
              </p>
            </div>
          </div>
          <div class="pricing-card-usecase-solution">
            <div class="pricing-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="pricing-card-usecase-brief-bank">
              <p>
                Our PIXSVMS ATM’s Bank & Branch CCTV Monitoring allows security
                professionals to control all cctv devices, recorders and
                cameras. In addition to this within hierarchy module bank branch
                managers can access and verify after incident happens.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase">
            <img src="../../assets/images/usecase-3.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="pricing-card-usecase-title">
            <h4>Security for Cash Transfer Vehicle Operations</h4>
          </div>
          <div class="pricing-card-usecase-problem">
            <div class="pricing-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="pricing-card-usecase-brief-transfer">
              <p>
                Managing and planning of cash transfer to ATM’s and branches
                needs a proper daily planning and monitoring.
              </p>
            </div>
          </div>
          <div class="pricing-card-usecase-solution">
            <div class="pricing-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="pricing-card-usecase-brief-transfer">
              <p>
                Our PIXSVMS ATM’s Bank & Branch CCTV Monitoring helps security
                professionals also on mobile site. You can instantly monitor
                mobile cctv recorders and cameras; access to live & record
                footage, within sim card network.
              </p>
              <p>
                Also there is an additional online map and address planning
                module; which you can control all daily operations of cash
                transfer operations.
              </p>
              <p>
                Lastly for logging driver and security personnel inside vehicle
                our PIXPass by ID module also allows you to log personnels after
                every operation. These logs are sent to main central station
                server securely.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardPricingFourth",
};
</script>
