<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleResellerForm />
    <ResellerForm />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTitleResellerForm from "../Reseller/PageTitleResellerForm.vue";
import ResellerForm from "../Reseller/ResellerForm.vue";
import Footer from "../Layout/Footer";

export default {
  name: "ResellerFormPage",
  components: {
    Navbar,
    PageTitleResellerForm,
    ResellerForm,
    Footer,
  },
};
</script>
