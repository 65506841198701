<template>
  <div class="bg-off-white">
    <div class="container pt-60 pb-30">
      <div class="section-title section-title-xl">
        <h2>Benefits for our Authorized Resellers</h2>
        <div class="row pt-30">
          <div class="col-12 col-md-4 col-lg-4">
            <div class="home-page-reseller-card left-position">
              <h3>Comprehensive Products and Solutions</h3>
              <p>
                We offer a full suite of comprehensive products and solutions,
                enabling you to expand your business scope and sell more.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4">
            <div class="home-page-reseller-card center-position">
              <h3>Dedicated Channel Support</h3>
              <p>
                PIXSelect's go-to-market model is based on indirect sales via
                channel partners. We will never be your competitor; rather, we
                will support you all the way.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 col-lg-4">
            <div class="home-page-reseller-card right-position">
              <h3>Marketing Initiatives</h3>
              <p>
                We'll keep you up to date on current trends in technological
                advancements, with multiple industry-leading patented
                technologies around the world.
              </p>
            </div>
          </div>
        </div>
        <div class="row pt-60">
          <div class="col-12 col-md col-lg pt-10">
            <div class="home-page-reseller-info">
              <img src="../../assets/images/homepage/reseller-photo-1.png" alt="shape" />
              <h6>Training</h6>
            </div>
          </div>
          <div class="col-12 col-md col-lg d-flex justify-content-center align-items-center pt-10">
            <img src="../../assets/images/homepage/reseller-photo-line.png" alt="shape" />
          </div>
          <div class="col-12 col-md col-lg pt-10">
            <div class="home-page-reseller-info">
              <img src="../../assets/images/homepage/reseller-photo-2.png" alt="shape" />
              <h6>Cloud Demo</h6>
            </div>
          </div>
          <div class="col-12 col-md col-lg d-flex justify-content-center align-items-center pt-10">
            <img src="../../assets/images/homepage/reseller-photo-line.png" alt="shape" />
          </div>
          <div class="col-12 col-md col-lg pt-10">
            <div class="home-page-reseller-info">
              <img src="../../assets/images/homepage/reseller-photo-3.png" alt="shape" />
              <h6>Marketing Materials</h6>
            </div>
          </div>
        </div>

        <div class="home-page-reseller-schema mt-60">
          <div class="row">

            <div class="col-6 col-md-3 col-lg-3">

              <div>
                <div v-if="benefitsRotationArr[0] == 'ben1'">
                  <div class="home-page-reseller-schema-info schema-position-1">
                    <img src="../../assets/images/homepage/difference-1.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-1.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer continous monitoring <br />
                      SAAS solutions.
                    </h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[0] == 'ben2'">
                  <div class="home-page-reseller-schema-info schema-position-1">
                    <img src="../../assets/images/homepage/difference-2.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-2.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer AI modules within <br />
                      SAAS platform.
                    </h6>
                  </div>

                </div>
                <div v-else-if="benefitsRotationArr[0] == 'ben3'">
                  <div class="home-page-reseller-schema-info schema-position-1">
                    <img src="../../assets/images/homepage/difference-3.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-3.png" alt="shape" class="schema-img" />
                    <h6>Gain continous income</h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[0] == 'ben4'">
                  <div class="home-page-reseller-schema-info schema-position-1">
                    <img src="../../assets/images/homepage/difference-4.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-4.png" alt="shape" class="schema-img" />
                    <h6>
                      Help organizations <br />
                      transforming this <br />
                      digital & AI journey
                    </h6>
                  </div>
                </div>
              </div>


            </div>

            <div class="col-6 col-md-3 col-lg-3">

              <div>
                <div v-if="benefitsRotationArr[1] == 'ben1'">
                  <div class="home-page-reseller-schema-info schema-position-4">
                    <img src="../../assets/images/homepage/difference-1.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-1.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer continous monitoring <br />
                      SAAS solutions.
                    </h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[1] == 'ben2'">
                  <div class="home-page-reseller-schema-info schema-position-4">
                    <img src="../../assets/images/homepage/difference-2.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-2.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer AI modules within <br />
                      SAAS platform.
                    </h6>
                  </div>

                </div>
                <div v-else-if="benefitsRotationArr[1] == 'ben3'">
                  <div class="home-page-reseller-schema-info schema-position-4">
                    <img src="../../assets/images/homepage/difference-3.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-3.png" alt="shape" class="schema-img" />
                    <h6>Gain continous income</h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[1] == 'ben4'">
                  <div class="home-page-reseller-schema-info schema-position-4">
                    <img src="../../assets/images/homepage/difference-4.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-4.png" alt="shape" class="schema-img" />
                    <h6>
                      Help organizations <br />
                      transforming this <br />
                      digital & AI journey
                    </h6>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div class="row">

            <div class="col-6 col-md-3 col-lg-3">

              <div>
                <div v-if="benefitsRotationArr[3] == 'ben1'">
                  <div class="home-page-reseller-schema-info schema-position-3">
                    <img src="../../assets/images/homepage/difference-1.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-1.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer continous monitoring <br />
                      SAAS solutions.
                    </h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[3] == 'ben2'">
                  <div class="home-page-reseller-schema-info schema-position-3">
                    <img src="../../assets/images/homepage/difference-2.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-2.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer AI modules within <br />
                      SAAS platform.
                    </h6>
                  </div>

                </div>
                <div v-else-if="benefitsRotationArr[3] == 'ben3'">
                  <div class="home-page-reseller-schema-info schema-position-3">
                    <img src="../../assets/images/homepage/difference-3.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-3.png" alt="shape" class="schema-img" />
                    <h6>Gain continous income</h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[3] == 'ben4'">
                  <div class="home-page-reseller-schema-info schema-position-3">
                    <img src="../../assets/images/homepage/difference-4.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-4.png" alt="shape" class="schema-img" />
                    <h6>
                      Help organizations <br />
                      transforming this <br />
                      digital & AI journey
                    </h6>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-6 col-md-3 col-lg-3">

              <div>
                <div v-if="benefitsRotationArr[2] == 'ben1'">
                  <div class="home-page-reseller-schema-info schema-position-2">
                    <img src="../../assets/images/homepage/difference-1.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-1.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer continous monitoring <br />
                      SAAS solutions.
                    </h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[2] == 'ben2'">
                  <div class="home-page-reseller-schema-info schema-position-2">
                    <img src="../../assets/images/homepage/difference-2.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-2.png" alt="shape" class="schema-img" />
                    <h6>
                      Offer AI modules within <br />
                      SAAS platform.
                    </h6>
                  </div>

                </div>
                <div v-else-if="benefitsRotationArr[2] == 'ben3'">
                  <div class="home-page-reseller-schema-info schema-position-2">
                    <img src="../../assets/images/homepage/difference-3.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-3.png" alt="shape" class="schema-img" />
                    <h6>Gain continous income</h6>
                  </div>
                </div>
                <div v-else-if="benefitsRotationArr[2] == 'ben4'">
                  <div class="home-page-reseller-schema-info schema-position-2">
                    <img src="../../assets/images/homepage/difference-4.png" alt="shape" />
                    <img src="../../assets/images/homepage/icon-4.png" alt="shape" class="schema-img" />
                    <h6>
                      Help organizations <br />
                      transforming this <br />
                      digital & AI journey
                    </h6>
                  </div>
                </div>
              </div>

            </div>

          </div>

          
        </div>

        <div class="section-title section-title-xl pt-60 m-0">
          <h2>Join Partner Program</h2>
          <div class="home-page-partner">
            <p>
              When you join the PIXSelect Partner Program, you become part of an
              ecosystem.
            </p>
          </div>
          <div class="pb-60">
            <router-link to="/projects" class="btn main-btn-academy" style="
                background-image: linear-gradient(to right, #6852ed, #ba43fc);
              ">
              Apply
            </router-link>
          </div>
          <div id="project-info-image" class="home-page-partner-card">
            <div class="row align-items-center">
              <div class="col-12 col-md col-lg">
                <h3>
                  <Vue3autocounter ref="counter1" :startAmount="0" :endAmount="99.7" :duration="3" suffix="%"
                    separator="." decimalSeparator="." :decimals="1" :autoinit="false" />
                </h3>
                <!-- <h3>99.7 %</h3> -->
                <h6>Satisfied Customers</h6>
              </div>
              <div class="col-12 col-md-1 col-lg-1">
                <img src="../../assets/images/line.png" alt="shape" class="goods-ai-warehouses-info-line" />
              </div>
              <div class="col-12 col-md col-lg">
                <h3>
                  <Vue3autocounter ref="counter2" :startAmount="0" :endAmount="50" :duration="3" :autoinit="false" />
                </h3>
                <!-- <h3>1.4 K</h3> -->
                <h6>Cases Completed</h6>
              </div>
              <div class="col-12 col-md-1 col-lg-1">
                <img src="../../assets/images/line.png" alt="shape" class="goods-ai-warehouses-info-line" />
              </div>
              <div class="col-12 col-md col-lg">
                <h3>
                  <Vue3autocounter ref="counter3" :startAmount="0" :endAmount="20" :duration="3" :autoinit="false" />
                </h3>
                <!-- <h3>112</h3> -->
                <h6>Professional Staff</h6>
              </div>
              <div class="col-12 col-md-1 col-lg-1">
                <img src="../../assets/images/line.png" alt="shape" class="goods-ai-warehouses-info-line" />
              </div>
              <div class="col-12 col-md col-lg">
                <h3>
                  <Vue3autocounter ref="counter4" :startAmount="0" :endAmount="280" :duration="3" suffix="+"
                    :autoinit="false" />
                </h3>
                <!-- <h3>280 +</h3> -->
                <h6>Coffee Spend (Daily)</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setTimeout } from "timers";
import { defineComponent } from "vue";
import Vue3autocounter from "vue3-autocounter";
export default defineComponent({
  components: {
    Vue3autocounter,
  },
  name: "BenefitResellers",
  data() {
    return {
      windowTop: null,
      isVisible: false,
      elemRect: 0,
      benefitsRotationArr: ["ben1", "ben4", "ben2", "ben3"],
      isBenefitClick: false
    };
  },
  methods: {
    reset() {
      if (this.isVisible) {
        this.$refs.counter1.start();
        this.$refs.counter2.start();
        this.$refs.counter3.start();
        this.$refs.counter4.start();
      }
    },
    onScroll() {
      this.windowTop =
        window.top.scrollY; /* or: e.target.documentElement.scrollTop */
      // this.isVisible = this.windowTop -  > 0 ? true : false;
      this.isVisible =
        this.windowTop > this.elemRect - 800 && this.windowTop < this.elemRect;
    },
    rotateBenefits() {
      let lastValue = this.benefitsRotationArr.pop()
      this.benefitsRotationArr.unshift(lastValue)
      setTimeout(() => {
        this.rotateBenefits()
      }, 4000);
    },

  },
  created() {
    this.rotateBenefits()
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.elemRect = document
      .querySelector("#project-info-image")
      .getBoundingClientRect().y;
  },
  watch: {
    isVisible(newVal) {
      if (newVal === true) {
        this.reset();
      }
    },
  },
});
</script>
