<template>
  <div class="container pt-60">
    <div class="demo-request-header">
      <h1>{{ pageTitle }}</h1>
      <p>Please fill in all the fields below completely.</p>
    </div>

    <div class="academy-form-card">
      <b-tabs class="academy-form-tabs" justified="true" v-model="tabIndex">
        <b-tab id="register" title="Register" active>
          <RegisterForm />
        </b-tab>
        <b-tab id="general-info" title="General Info">
          <GeneralInfoForm />
        </b-tab>
        <b-tab id="education-status" title="Education Status">
          <EducationStatusForm />
        </b-tab>
        <b-tab id="working-status" title="Working Status">
          <WorkingStatusForm />
        </b-tab>
        <b-tab id="technical-info" title="Technical Info">
          <TechnicalInfoForm />
          <div class="col-md-12">
            <div class="form-group mb-20">
              <button class="btn demo-request-form-btn" type="submit" @click="onSubmit">
                <h5 class="m-0">NEXT</h5>
              </button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapState, mapMutations } from "vuex";
import RegisterForm from "./RegisterForm.vue";
import GeneralInfoForm from "./GeneralInfoForm.vue";
import EducationStatusForm from "./EducationStatusForm.vue";
import WorkingStatusForm from "./WorkingStatusForm.vue";
import TechnicalInfoForm from "./TechnicalInfoForm.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs, minLength } from "@vuelidate/validators";

import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  props: {
    pageTitle: String
  },
  setup() {
    return { v$: useVuelidate() };
  },
  name: "PIXAcademyForm",
  components: {
    RegisterForm,
    GeneralInfoForm,
    EducationStatusForm,
    WorkingStatusForm,
    TechnicalInfoForm,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  validations() {
    return {
      formAcademy: {
        email: { required, email },
        password: {
          required,
          min: minLength(8),
        },
        confirmPassword: {
          required,
          sameAs: sameAs(this.formAcademy.password),
        },
        name: { required },
        phone: { required },
        birthday: { required },
        gender: { required },
        city: { required },
        educationStatus: { required },
        university: { required },
        departmant: { required },
        gradutationDate: { required },
        englishStatus: { required },
        jobStatus: { required },
        //jobCity: { required },
        experience: { required },
        jobDepartmant: { required },
        contactPlace: { required },
      },
    };
  },
  methods: {
    ...mapActions("main", ["changeTabForm", "postFormAcademy"]),
    ...mapMutations("main", ["setFormAcademy"]),
    onSubmit() {
      this.v$.$touch();
      if (!this.v$.formAcademy.$invalid) {
        const formData = new FormData();
        formData.append("name_surname", this.formAcademy.name);
        formData.append("password", this.formAcademy.password);
        formData.append("email", this.formAcademy.email);
        formData.append("phone", this.formAcademy.phone);
        formData.append("birthday", this.formAcademy.birthday);
        formData.append("education_status", this.formAcademy.educationStatus);
        formData.append("university_id", this.formAcademy.university);
        formData.append("department", this.formAcademy.departmant);
        formData.append("engineer_departmant", this.formAcademy.departmant);
        formData.append("gradutation_date", this.formAcademy.gradutationDate);
        formData.append("gender", this.formAcademy.gender);
        formData.append("city", this.formAcademy.city);
        formData.append("job_status", this.formAcademy.jobStatus);
        formData.append("english_status", this.formAcademy.englishStatus);
        formData.append("experience", this.formAcademy.experience);
        formData.append("github", this.formAcademy.github);
        formData.append("linkedin", this.formAcademy.linkedin);
        formData.append("job_departmant", this.formAcademy.jobDepartmant);
        formData.append("kvkk_approval", this.formAcademy.isPrivacyPolicy);
        formData.append("contact_place", this.formAcademy.contactPlace);
        formData.append("experience1", this.formAcademy.experience1);
        formData.append("experience2", this.formAcademy.experience2);
        formData.append("experience3", this.formAcademy.experience3);
        formData.append("attachments", this.formAcademy.files);


        this.postFormAcademy(formData)
          .then((res) => {
            createToast(res.message, {
              toastBackgroundColor: "#28a745",
              type: "success",
            });
          })
          .then(() => {
            this.changeTabForm(0);
            this.setFormAcademy(
              (this.formAcademy = {
                email: "",
                password: "",
                confirmPassword: "",
                isPrivacyPolicy: false,
                name: "",
                phone: "",
                birthday: "",
                gender: "",
                city: "",
                educationStatus: "",
                university: "",
                departmant: "",
                gradutationDate: "",
                englishStatus: "",
                jobCity: "",
                jobStatus: "",
                experience: "",
                jobDepartmant: "",
                github: "",
                linkedin: "",
                experience1: ["", "", "", ""],
                experience2: ["", "", "", ""],
                experience3: ["", "", "", ""],
                files: [],
              })
            );
            this.v$.$reset();
          })
          .catch((res) => {
            createToast(res.response.data.message, {
              toastBackgroundColor: "#dc3545",
              type: "danger",
            });
          });
      } else {
        createToast("Please make sure all fields are filled", {
          toastBackgroundColor: "#dc3545",
          type: "danger",
        });

      }
    },
  },
  computed: {
    ...mapState("main", ["changeTab", "formAcademy"]),
  },
  watch: {
    changeTab(nw) {
      this.tabIndex = nw;
    },
    tabIndex(nw) {
      this.changeTabForm(nw);
    },
  },
});
</script>
