<template>
  <div class="process-section pt-90 pb-70">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>Why are our solutions different?</h2>
      </div>
      <div class="process-content">
        <div class="row justify-content-center">
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-1.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>In-Depth Training</h3>
                <p>
                  Learning to code means more than memorizing syntax. Instead,
                  we help you think like a real programmer.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-2.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>Offers a Roadmap</h3>
                <p>
                  We offer a useful roadmap. Like a career counselor, we guide
                  you every step of the way. We convey the right information, at
                  the right time, in one place.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 pb-30">
            <div class="process-item process-item-center border">
              <div class="process-thumb">
                <img src="../../assets/images/icon-3.png" alt="shape" />
              </div>
              <div class="process-text">
                <h3>Ready to Work</h3>
                <p>
                  We train with real cases of the business world and gain
                  practical experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Process",
};
</script>
