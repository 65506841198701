<template>
  <div @submit.prevent="onSubmit">
    <div class="row d-flex justify-content-center mt-30">
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label>Education Status</label>
          <select
            name="education_status"
            class="form-control"
            v-model="formAcademy.educationStatus"
            :class="{ 'is-invalid': v$.formAcademy.educationStatus.$error }"
          >
            <option :value="''" style="font-size: 20px">
              Select an education status
            </option>
            <option
              v-for="(item, index) in educationStatus"
              :key="index"
              :value="item.text"
              style="font-size: 20px"
            >
              {{ item.text }}
            </option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.educationStatus.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message == "Value is required"
                ? "Education Status is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label for="">
            If you are continuing your education, in which university do you
            continue your education?</label
          >
          <select
            name="university"
            class="form-control"
            v-model="formAcademy.university"
            :class="{ 'is-invalid': v$.formAcademy.university.$error }"
          >
            <option :value="''" style="font-size: 20px">
              Select an university
            </option>
            <option
              v-for="(item, index) in universities"
              :key="index"
              :value="item.id"
              style="font-size: 20px"
            >
              {{ item.university_name }}
            </option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.university.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message == "Value is required"
                ? "University is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label
            >If you are not continuing your graduate or education, which is your
            department?</label
          >
          <select
            name="departmant"
            class="form-control"
            v-model="formAcademy.departmant"
            :class="{ 'is-invalid': v$.formAcademy.departmant.$error }"
          >
            <option :value="''" style="font-size: 20px">
              Select a departmant
            </option>
            <option
              v-for="(item, index) in departmants"
              :key="index"
              :value="item.text"
              style="font-size: 20px"
            >
              {{ item.text }}
            </option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.departmant.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message == "Value is required"
                ? "Departmant is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label for=""
            >If you are not currently studying, what year and month will you
            graduate?</label
          ><Datepicker
            v-model="formAcademy.gradutationDate"
            class="form-control"
            placeholder="Select a date"
            inputFormat="dd-MM-yyyy"
            :class="{ 'is-invalid': v$.formAcademy.gradutationDate.$error }"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.gradutationDate.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message == "Value is required"
                ? "Date is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label for="">English Knowledge</label>
          <select
            name="english_knowledge"
            class="form-control"
            v-model="formAcademy.englishStatus"
            :class="{ 'is-invalid': v$.formAcademy.englishStatus.$error }"
          >
            <option :value="''" style="font-size: 20px">
              Select a knowledge English
            </option>
            <option
              v-for="(item, index) in englishStatus"
              :key="index"
              :value="item.text"
              style="font-size: 20px"
            >
              {{ item.text }}
            </option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.englishStatus.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message == "Value is required"
                ? "English knowledge is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <button
            class="btn demo-request-form-btn"
            type="submit"
            @click="onSubmit"
          >
            <h5 class="m-0">NEXT</h5>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapState } from "vuex";
import Datepicker from "vue3-datepicker";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },

  name: "EducationStatusForm",
  components: { Datepicker },
  data() {
    return {
      educationStatus: [
        { text: "High School", value: 1 },
        { text: "Associate Degree", value: 2 },
        { text: "Bachelor's Degree", value: 3 },
        { text: "Master's Degree", value: 4 },
        { text: "Doctorate or Professional Degree", value: 5 },
      ],
      // universities: [
      //   { text: "I do not continue.", value: 1 },

      //   { text: "Abant İzzet Baysal University", value: 2 },

      //   { text: "Abdullah Gül University", value: 3 },

      //   { text: "Acıbadem University", value: 4 },

      //   { text: "Adana Bilim ve Teknoloji University", value: 5 },

      //   { text: "Adnan Menderes University", value: 6 },

      //   { text: "Adıyaman University", value: 7 },

      //   { text: "Afyon Kocatepe University", value: 8 },

      //   { text: "Ahi Evran University", value: 9 },

      //   { text: "Akdeniz University", value: 10 },

      //   { text: "Akev University", value: 11 },

      //   { text: "Aksaray University", value: 12 },

      //   { text: "Alanya Alaaddin Keykubat University", value: 13 },

      //   { text: "Alanya Hamdullah Emin Paşa University", value: 14 },

      //   { text: "Amasya University", value: 15 },

      //   { text: "Anadolu University", value: 16 },

      //   { text: "Anka Teknoloji University", value: 17 },

      //   { text: "Ankara Sosyal Bilimler University", value: 18 },

      //   { text: "Ankara University", value: 19 },

      //   { text: "Ardahan University", value: 20 },

      //   { text: "Artvin Çoruh University", value: 21 },

      //   { text: "Atatürk University", value: 22 },

      //   { text: "Atılım University", value: 23 },

      //   { text: "Avrasya University", value: 24 },

      //   { text: "Ağrı İbrahim Çeçen University", value: 25 },

      //   { text: "Bahçeşehir University", value: 26 },

      //   { text: "Balıkesir University", value: 27 },

      //   { text: "Bandırma Onyedi Eylül University", value: 28 },

      //   { text: "Bartın University", value: 29 },

      //   { text: "Batman University", value: 30 },

      //   { text: "Bayburt University", value: 31 },

      //   { text: "Başkent University", value: 32 },

      //   { text: "Beykent University", value: 33 },

      //   { text: "Bezmiâlem Vakıf University", value: 34 },

      //   { text: "Bilecik Şeyh Edebali University", value: 35 },

      //   { text: "Bilkent University", value: 36 },

      //   { text: "Bingöl University", value: 37 },

      //   { text: "Biruni University", value: 38 },

      //   { text: "Bitlis Eren University", value: 39 },

      //   { text: "Bozok University", value: 40 },

      //   { text: "Boğaziçi University", value: 41 },

      //   { text: "Bursa Orhangazi University", value: 42 },

      //   { text: "Bursa Teknik University", value: 43 },

      //   { text: "Bülent Ecevit University", value: 44 },

      //   { text: "Canik Başarı University", value: 45 },

      //   { text: "Celal Bayar University", value: 46 },

      //   { text: "Cumhuriyet University", value: 47 },

      //   { text: "Çanakkale Onsekiz Mart University", value: 48 },

      //   { text: "Çankaya University", value: 49 },

      //   { text: "Çankırı Karatekin University", value: 50 },

      //   { text: "Çağ University", value: 51 },

      //   { text: "Çukurova University", value: 52 },

      //   { text: "Deniz Harp Okulu", value: 53 },

      //   { text: "Dicle University", value: 54 },

      //   { text: "Dokuz Eylül University", value: 55 },

      //   { text: "Doğuş University", value: 56 },

      //   { text: "Dumlupınar University", value: 57 },

      //   { text: "Düzce University", value: 58 },

      //   { text: "Ege University", value: 59 },

      //   { text: "Erciyes University", value: 60 },

      //   { text: "Erzincan University", value: 61 },

      //   { text: "Erzurum Teknik University", value: 62 },

      //   { text: "Eskişehir Osmangazi University", value: 63 },

      //   { text: "Eskişehir Teknik University", value: 64 },

      //   { text: "Fatih Sultan Mehmet University", value: 65 },

      //   { text: "Fatih University", value: 66 },

      //   { text: "Fırat University", value: 67 },

      //   { text: "Galatasaray University", value: 68 },

      //   { text: "Gazi University", value: 69 },

      //   { text: "Gaziantep University", value: 70 },

      //   { text: "Gaziosmanpaşa University", value: 71 },

      //   { text: "Gebze Teknik University", value: 72 },

      //   { text: "Gedik University", value: 73 },

      //   { text: "Gediz University", value: 74 },

      //   { text: "Giresun University", value: 75 },

      //   { text: "Gülhane Askeri Tıp Akademisi", value: 76 },

      //   { text: "Gümüşhane University", value: 77 },

      //   { text: "Hacettepe University", value: 78 },

      //   { text: "Hakkari University", value: 79 },

      //   { text: "Haliç University", value: 80 },

      //   { text: "Harran University", value: 81 },

      //   { text: "Hasan Kalyoncu University", value: 82 },

      //   { text: "Hava Harp Okulu", value: 83 },

      //   { text: "Hitit University", value: 84 },

      //   { text: "Iğdır University", value: 85 },

      //   { text: "Işık University", value: 86 },

      //   { text: "Kadir Has University", value: 87 },

      //   { text: "Kafkas University", value: 88 },

      //   { text: "Kahramanmaraş Sütçü İmam University", value: 89 },

      //   { text: "Kanuni University", value: 90 },

      //   { text: "Kara Harp Okulu", value: 91 },

      //   { text: "Karabük University", value: 92 },

      //   { text: "Karadeniz Teknik University", value: 93 },

      //   { text: "Karamanoğlu Mehmetbey University", value: 94 },

      //   { text: "Karatay University", value: 95 },

      //   { text: "Kastamonu University", value: 96 },

      //   { text: "Kilis 7 Aralık University", value: 97 },

      //   { text: "Kocaeli University", value: 98 },

      //   { text: "Konya Gıda Tarım University", value: 99 },

      //   { text: "Koç University", value: 100 },

      //   { text: "Kırklareli University", value: 101 },

      //   { text: "Kırıkkale University", value: 102 },

      //   { text: "MEF University", value: 103 },

      //   { text: "Maltepe University", value: 104 },

      //   { text: "Mardin University", value: 105 },

      //   { text: "Marmara University", value: 106 },

      //   { text: "Mehmet Akif Ersoy University", value: 107 },

      //   { text: "Melikşah University", value: 108 },

      //   { text: "Mersin University", value: 109 },

      //   { text: "Mevlana University", value: 110 },

      //   { text: "Mimar Sinan Güzel Sanatlar University", value: 111 },

      //   { text: "Murat Hüdavendigar University", value: 112 },

      //   { text: "Mustafa Kemal University", value: 113 },

      //   { text: "Muğla Sıtkı Koçman University", value: 114 },

      //   { text: "Muş Alparslan University", value: 115 },

      //   { text: "Namık Kemal University", value: 116 },

      //   { text: "Necmettin Erbakan University", value: 117 },

      //   { text: "Nevşehir Hacı Bektaş Veli University", value: 118 },

      //   { text: "Niğde University", value: 119 },

      //   { text: "Nişantaşı University", value: 120 },

      //   { text: "Nuh Naci Yazgan University", value: 121 },

      //   { text: "İbn-u Haldun University", value: 122 },

      //   { text: "İnönü University", value: 123 },

      //   { text: "İpek University", value: 124 },

      //   { text: "İskenderun Teknik University", value: 125 },

      //   { text: "İstanbul 29 Mayıs University", value: 126 },

      //   { text: "İstanbul Arel University", value: 127 },

      //   { text: "İstanbul Aydın University", value: 128 },

      //   { text: "İstanbul Bilgi University", value: 129 },

      //   { text: "İstanbul Bilim University", value: 130 },

      //   { text: "İstanbul Esenyurt University", value: 131 },

      //   { text: "İstanbul Gelişim University", value: 132 },

      //   { text: "İstanbul Kemerburgaz University", value: 133 },

      //   { text: "İstanbul Kültür University", value: 134 },

      //   { text: "İstanbul Medeniyet University", value: 135 },

      //   { text: "İstanbul Medipol University", value: 136 },

      //   { text: "İstanbul Rumeli University", value: 137 },

      //   { text: "İstanbul Sabahattin Zaim University", value: 138 },

      //   { text: "İstanbul Teknik University", value: 139 },

      //   { text: "İstanbul Ticaret University", value: 140 },

      //   { text: "İstanbul University", value: 141 },

      //   { text: "İstanbul Şehir University", value: 142 },

      //   { text: "İstinye University", value: 143 },

      //   { text: "İzmir Ekonomi University", value: 144 },

      //   { text: "İzmir Kâtip Çelebi University", value: 145 },

      //   { text: "İzmir Yüksek Teknoloji University", value: 146 },

      //   { text: "İzmir University", value: 147 },

      //   { text: "Okan University", value: 148 },

      //   { text: "Ondokuz Mayıs University", value: 149 },

      //   { text: "Ordu University", value: 150 },

      //   { text: "Orta Doğu Teknik University University", value: 151 },

      //   { text: "Osmaniye Korkut Ata University", value: 152 },

      //   { text: "Özyeğin University", value: 153 },

      //   { text: "Pamukkale University", value: 154 },

      //   { text: "Piri Reis University", value: 155 },

      //   { text: "Recep Tayyip Erdoğan University", value: 156 },

      //   { text: "Sabancı University", value: 157 },

      //   { text: "Sakarya University", value: 158 },

      //   { text: "Sanko University", value: 159 },

      //   { text: "Sağlık Bilimleri University", value: 160 },

      //   { text: "Selahattin Eyyubi University", value: 161 },

      //   { text: "Selçuk University", value: 162 },

      //   { text: "Siirt University", value: 163 },

      //   { text: "Sinop University", value: 164 },

      //   { text: "Süleyman Demirel University", value: 165 },

      //   { text: "Süleyman Şah University", value: 166 },

      //   { text: "Şifa University", value: 167 },

      //   { text: "Şırnak University", value: 168 },

      //   { text: "TED University", value: 169 },

      //   { text: "TOBB Ekonomi ve Teknoloji University", value: 170 },

      //   { text: "Toros University", value: 171 },

      //   { text: "Trakya University", value: 172 },

      //   { text: "Tunceli University", value: 173 },

      //   { text: "Turgut Özal University", value: 174 },

      //   { text: "Türk Alman University", value: 175 },

      //   { text: "Türk Hava Kurumu University", value: 176 },

      //   {
      //     text: "Türkiye Uluslararası İslam, Bilim ve Teknoloji University",
      //     value: 177,
      //   },

      //   { text: "Ufuk University", value: 178 },

      //   { text: "Uludağ University", value: 179 },

      //   { text: "Uluslararası Antalya University", value: 180 },

      //   { text: "Uşak University", value: 181 },

      //   { text: "Üsküdar University", value: 182 },

      //   { text: "Yalova University", value: 183 },

      //   { text: "Yaşar University", value: 184 },

      //   { text: "Yeditepe University", value: 185 },

      //   { text: "Yeni Yüzyıl University", value: 186 },

      //   { text: "Yüksek İhtisas University", value: 187 },

      //   { text: "Yüzüncü Yıl University", value: 188 },

      //   { text: "Yıldırım Beyazıt University", value: 189 },

      //   { text: "Yıldız Teknik University", value: 190 },

      //   { text: "Zirve University", value: 191 },
      // ],
      universities: [],
      departmants: [
        { text: "Computer Engineering", value: 1 },
        { text: "Management Information Systems", value: 2 },
        { text: "Software Engineering", value: 3 },
        { text: "Computer Programming", value: 4 },
        { text: "Electrical Electronics Engineering", value: 5 },
        { text: "Industrial Engineering", value: 6 },
        { text: "Mechanical Engineering", value: 7 },
        { text: "Civil engineering", value: 8 },
        { text: "Statistics", value: 9 },
        { text: "Mathematics / Mathematics Engineering", value: 10 },
        { text: "Business / Economics", value: 11 },
      ],
      englishStatus: [
        { value: 1, text: "A1" },
        { value: 2, text: "A2" },
        { value: 3, text: "B1" },
        { value: 4, text: "B2" },
        { value: 5, text: "C1" },
        { value: 6, text: "C2" },
      ],
    };
  },

  validations() {
    return {
      formAcademy: {
        educationStatus: { required },
        university: { required },
        departmant: { required },
        gradutationDate: { required },
        englishStatus: { required },
      },
    };
  },
  created() {
    this.university();
  },
  methods: {
    ...mapActions("main", ["changeTabForm", "getUniversity"]),
    onSubmit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.changeTabForm(3);
      }
    },
    university() {
      this.universities = [];
      this.getUniversity().then((res) => {
        this.universities.push({
          university_name: "I do not continue.",
          id: 1,
          is_deleted: false,
        });
        res.data.forEach((element) => {
          this.universities.push(element);
        });
      });
    },
  },
  computed: {
    ...mapState("main", ["formAcademy"]),
  },
});
</script>
