<template>
    <div class="container pb-30 pt-60">
      <div class="section-title section-title-lg">
        <h2>SaaS Cloud Tag Pricing Plan</h2>
      </div>
      <div id="generic_price_table">
        <section>
  
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="generic_content clearfix">
                  <div class="generic_head_price clearfix">
                    <div class="generic_head_content clearfix">
                      <div class="head_bg"></div>
                      <div class="head">
                        <span>Saas Cloud Tag Membership</span>
                      </div>
                    </div>
  
                    <!-- <div class="generic_price_tag clearfix">
                      <span class="price">
                        <span class="sign">$</span>
                        <span class="currency">2500</span>
                        <span class="month">/Yearly</span>
                      </span>
                    </div> -->
                  </div>
  
                  <div class="generic_feature_list">
                    <ul>
                      <li><span> <i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp; Cloud tagging program specifically developed for AI Teams</span></li>
                      <li><span><i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp; Single and multiple class imports</span></li>
                      <li><span><i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp; Project team building, tag performance tracking</span></li>
                      <li><span><i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp; Adding photo or video data for tag tagging, automatic assignment of video data to project team</span></li>
                      <li><span><i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp; System usage with authorization</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="generic_content clearfix">
                  <div class="generic_head_price clearfix">
                    <div class="generic_head_content clearfix">
                      <div class="head_bg"></div>
                      <div class="head">
                        <span>Saas CV Membership</span>
                      </div>
                    </div>
  
                    <!-- <div class="generic_price_tag clearfix">
                      <span class="price">
                        <span class="sign">$</span>
                        <span class="currency">2500</span>
                        <span class="month">/Yearly</span>
                      </span>
                    </div> -->
                  </div>
  
                  <div class="generic_feature_list">
                    <ul>
                      <li><span> <i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp;Get access to filtered CVs as needed.</span></li>
                      <li><span><i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp;Gain access to a large pool of candidates from cooperation agreements with universities.</span></li>
                      <li><span><i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp; Access an up-to-date candidate pool through our university student club collaborations.</span></li>
                      <li><span><i style="color:#99ca48" class="flaticon-rounded-check-mark"></i>&nbsp;Delegate your HR Processes, save time and cost.</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <BlogSaasCloud2 />
    </div>
  </template>
  
  <script>
  import BlogSaasCloud2 from "../../SingleBlog/BlogSaasCloud2.vue";
  export default {
    name: "LogisticOurSolution",
    components: {
      BlogSaasCloud2,
    },
  };
  </script>
  
  
  <style lang="scss" scoped>
  #generic_price_table .generic_content {
    background-color: #fff;
  }
  
  #generic_price_table .generic_content .generic_head_price {
    background-color: #f6f6f6;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head_bg {
    border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head
    span {
    color: #525252;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .sign {
    color: #414141;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .currency {
    color: #414141;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .cent {
    color: #414141;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .month {
    color: #414141;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li {
    color: #a7a7a7;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li span {
    color: #414141;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li:hover {
    background-color: #e4e4e4;
    border-left: 5px solid #99ca48;
  }
  
  #generic_price_table
    .generic_content.active
    .generic_head_price
    .generic_head_content
    .head_bg,
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head_bg {
    border-color: #99ca48 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #99ca48;
    color: #fff;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head
    span,
  #generic_price_table
    .generic_content.active
    .generic_head_price
    .generic_head_content
    .head
    span {
    color: #fff;
  }
  
  #generic_price_table {
    margin: 50px 0 50px 0;
  }
  
  .row .table {
    padding: 28px 0;
  }
  
  /*PRICE BODY CODE START*/
  
  #generic_price_table .generic_content {
    overflow: hidden;
    position: relative;
    text-align: center;
  }
  
  #generic_price_table .generic_content .generic_head_price {
    margin: 0 0 20px 0;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content {
    margin: 0 0 50px 0;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head_bg {
    border-style: solid;
    border-width: 90px 1411px 23px 399px;
    position: absolute;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head {
    padding-top: 10px;
    position: relative;
    z-index: 1;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head
    span {
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  
  #generic_price_table .generic_content .generic_head_price .generic_price_tag {
    padding: 0 0 20px;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price {
    display: block;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .sign {
    display: inline-block;
    font-size: 28px;
    font-weight: 400;
    vertical-align: middle;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .currency {
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -2px;
    line-height: 60px;
    padding: 0;
    vertical-align: middle;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .price
    .cent {
    display: inline-block;
    font-size: 24px;
    font-weight: 400;
    vertical-align: bottom;
  }
  
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_price_tag
    .month {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li {
    font-size: 18px;
    padding: 15px 0;
    transition: all 0.3s ease-in-out 0s;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li:hover {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
  }
  
  #generic_price_table .generic_content .generic_feature_list ul li .fa {
    padding: 0 10px;
  }
  
  #generic_price_table .generic_content,
  #generic_price_table .generic_content:hover,
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head_bg,
  #generic_price_table
    .generic_content:hover
    .generic_head_price
    .generic_head_content
    .head_bg,
  #generic_price_table
    .generic_content
    .generic_head_price
    .generic_head_content
    .head
    h2,
  #generic_price_table
    .generic_content:hover
    .generic_head_price
    .generic_head_content
    .head
    h2,
  #generic_price_table .generic_content .price,
  #generic_price_table .generic_content:hover .price,
  #generic_price_table .generic_content .generic_price_btn a,
  #generic_price_table .generic_content:hover .generic_price_btn a {
    transition: all 0.3s ease-in-out 0s;
  }
  
  @media (max-width: 320px) {
  }
  
  @media (max-width: 767px) {
    #generic_price_table .generic_content {
      margin-bottom: 75px;
    }
  }
  
  
  #generic_price_table_home ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: table;
  }
  
  #generic_price_table_home li {
    float: left;
  }
  
  #generic_price_table_home li + li {
    margin-left: 10px;
    padding-bottom: 10px;
  }
  
  #generic_price_table_home li a {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0px;
  }
  
  </style>