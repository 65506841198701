<template>
  <div class="container pt-60 pb-30">
    <div class="section-title section-title-xl">
      <h2>Offer PIXSelect Solutions</h2>
    </div>
    <div class="row">
      <div class="col-12 col-md-5 col-lg-5 pb-30">
        <div class="contact-info-item">
          <div class="offer-solution">
            <img
              src="../../assets/images/partners/difference-1.png"
              alt="shape"
            />
            <div class="offer-solution-text">
              <h5>
                Offer continous monitoring <br />
                SAAS solutions.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-2 col-lg-2 d-flex align-items-center">
        <div class="offer-solution-seperator">
          <img src="../../assets/images/partners/seperator.png" alt="shape" />
        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-5 pb-30">
        <div class="contact-info-item">
          <div class="offer-solution">
            <img
              src="../../assets/images/partners/difference-2.png"
              alt="shape"
            />
            <div class="offer-solution-text">
              <h5>
                Offer AI modules within <br />
                SAAS platform.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-5 col-lg-5 pb-30">
        <div class="contact-info-item">
          <div class="offer-solution">
            <img
              src="../../assets/images/partners/difference-3.png"
              alt="shape"
            />
            <div class="offer-solution-text">
              <h5>Gain continous income.</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-2 col-lg-2 d-flex align-items-center">
        <div class="offer-solution-seperator">
          <img src="../../assets/images/partners/seperator.png" alt="shape" />
        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-5 pb-30">
        <div class="contact-info-item">
          <div class="offer-solution">
            <img
              src="../../assets/images/partners/difference-4.png"
              alt="shape"
            />
            <div class="offer-solution-text">
              <h5>
                Help organizations <br />
                transforming this<br />
                digital & AI journey.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferSolutions",
};
</script>
