<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl mb-0">
        <h2>IT Support Specialist Training and Employment Program</h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            It is an 8-week intensive training and employment program that
            provides the training of experts who solve technical <br />
            problems of companies, maintain hardware and software installations
            and improve IT systems.
          </p>
        </div>
      </div>
      <div class="row pt-60 pb-60">
        <div class="col-12 d-flex justify-content-center">
          <img
            src="../../assets/images/it-support-special/photo-1.png"
            alt="shape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItSupportProgram",
};
</script>
<style scoped></style>
