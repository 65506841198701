<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>What Do We Offer?</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features">
              <h5>Technical Training</h5>
              <p>
                We offer webinars and guides that will empower you in <br />
                your software career.
              </p>
            </div>
            <img
              src="../../assets/images/1.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features">
              <h5>Recruitment</h5>
              <p>
                We match you with job postings tailored to the <br />
                terms you specified.
              </p>
            </div>
            <img
              src="../../assets/images/2.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaasCandidatesOffer",
};
</script>
