<template>
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-lg">
        <h2>Vision AI for Logistics</h2>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="logistic-visionAi-img">
            <img src="../../assets/images/logistic/logistic-vision.png" alt="shape" />
          </div>
        </div>
        <div class="col-lg-6 p-0">
          <div class="logistic-visionAi">
            <div class="logistic-visionAi-title">
              <h5>
                Logistic operations need good plans, and these plans have to be
                instantly monitored and logistics professionals have to make
                quick response to instances.
              </h5>
            </div>
          </div>

          <div class="logistic-visionAi-list pt-20">
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Manage and control of your entrance with our Access log
                solutions.
              </p>
            </div>

            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Manage and control all cctv devices; on entrance, ramps,
                warehouses.
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Instantly monitoring of inventories needed such as shelves
                monitoring and shelves count.
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Instantly monitoring needed about whole operation, workflow such
                as goods acceptance, delivery and restoring in warehouses.
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Evidence snaphots and cctv video footage is binded on every
                stage, for quick controlling your operations.
              </p>
            </div>
            <div class="logistic-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>Automatic AI counting for verifying good acceptance</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "VisionLogistic",
};
</script>
