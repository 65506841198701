<template>
    <div class="container pt-10">
        <div class="section-title section-title-xl pb-30">
            <h2>Features</h2>
            <h5>We focused on the features needed to give you the best tagging experience.</h5>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Organize Your Data Sets</h5>
                            <p>
                                Keeping track of all your data sets can be difficult, so we offer you a service to organize
                                all your machine learning data sets.
                            </p>
                            <br>
                        </div>
                    </div>

                    <img src="../../assets/images/1.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Easily Manage Your Labeling Team </h5>
                            <p>
                                Invite team members, share your datasets and tag them together. There is no limit to the
                                team members you can invite. We support multi-user tagging, you can tag simultaneously on
                                the same dataset.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/2.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Advanced Instant Reporting</h5>
                            <p>
                                Get instant reports on the distribution of your tags, overall progress or the progress of
                                each tagger in your team.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/3.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>End-to-End Integration with our Cloud Servers </h5>
                            <p>
                                Use our cloud servers to integrate processed data directly into your machine learning flows.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/4.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Gain Speed with Dictionaries</h5>
                            <p>
                                Dictionaries can be used to speed up your taggers by pre-tagging. For example, you may want
                                to tag street names and already have a list of names available.
                            </p>

                        </div>
                    </div>
                    <img src="../../assets/images/5.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Manage Your Team Members' Access Roles </h5>
                            <p>
                                Assign roles to each team member according to their job task.
                            </p>
                        </div>
                    </div>
                    <img src="../../assets/images/6.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
export default {
    name: "AiDeveloperAwaitYou",
};
</script>
  