<template>
    <div class="container">
      <div class="section-image">
        <img
          style="max-width: 100%;"
          src="../../assets/images/aivisiontrainer.png"
          alt="shape"
        />
      </div>
      <div class="row pb-60">
        <div class="col-md-12 col-lg-12">
          <div class="it-support-card">
            <h3>
              Do you want to transform your data tagging processes and evaluate your data more efficiently, moving from raw data to meaningful data by storing all stages of the process securely and with peace of mind?
            </h3>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AiDeveloperCard",
  };
  </script>
  