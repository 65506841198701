<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleForRetail pageTitle="PIXSelect Retail Solutions" />
    <VisionRetail />
    <RetailPowerEfficiency />
    <RetailOurSolution />
    <RetailUseCase />
    <SolutionsByIndustriesClients />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageTitleForRetail from "../Retail/PageTitleForRetail.vue";
import VisionRetail from "../Retail/VisionRetail.vue";
import RetailPowerEfficiency from "../Retail/RetailPowerEfficiency.vue";
import RetailOurSolution from "../Retail/RetailOurSolution.vue";
import RetailUseCase from "../Retail/RetailUseCase.vue";
import SolutionsByIndustriesClients from "../OurClients/SolutionsByIndustriesClients.vue";
import Footer from "../Layout/Footer";

export default {
  name: "LogisticPage",
  components: {
    Navbar,
    PageTitleForRetail,
    VisionRetail,
    RetailPowerEfficiency,
    RetailOurSolution,
    RetailUseCase,
    SolutionsByIndustriesClients,
    Footer,
  },
};
</script>
