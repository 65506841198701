<template>
  <div class="partner-section pt-60 pb-60">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>Technology Partners</h2>
      </div>
      <div class="partner-carousel-client">
        <carousel-3d :autoplay-timeout="3000" :autoplay="true" :display="3">
          <slide v-for="(slide, i) in carouselItems" :key="slide.id" :index="i">
            <div class="item">
              <img :src="slide.image" style="width: 260px" alt="partner" />
            </div>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel3d, Slide } from "vue3-carousel-3d";
import "vue3-carousel-3d/dist/index.css";

export default defineComponent({
  name: "SolutionsByIndustriesClients",
  components: {
    Carousel3d,
    Slide,
  },
  data: () => ({
    carouselItems: [
      {
        id: 1,
        image: require("../../assets/images/pricing-partner/hikvision.png"),
      },
      {
        id: 2,
        image: require("../../assets/images/pricing-partner/hanwha-samsung.png"),
      },
      {
        id: 3,
        image: require("../../assets/images/pricing-partner/onvif.png"),
      },
      {
        id: 4,
        image: require("../../assets/images/pricing-partner/zebra.png"),
      },
      {
        id: 5,
        image: require("../../assets/images/pricing-partner/assa_abloy.png"),
      },
    ],
  }),
});
</script>
