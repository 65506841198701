<template>
  <div class="bg-off-white">
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-xl">
        <h2>
          The power and efficiency of PIXSelect Vision AI, provides solutions
          for
          <br />
          finance industry; bank head offices, bank branches, ATM’s such as
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="pricing-card">
            <div class="pricing-card-info-img">
              <img
                src="../../assets/images/finance-card-first.png"
                alt="shape"
              />
            </div>
            <div class="pricing-card-info">
              <h5>Security for ATM Operations</h5>
              <p>
                PIXSVMS Finance allows you to control and log all your CCTV
                monitoring & viewing operations.
                <br />
                <br />
                PIXSVMS Finance is compliant according to Bank Regulations;
                Security Center professionals gets email and instant pop-up
                alarms occur when there is an incident happens.
              </p>
              <img src="../../assets/images/1.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="pricing-card">
            <div class="pricing-card-info-img">
              <img
                src="../../assets/images/finance-card-second.png"
                alt="shape"
              />
            </div>

            <div class="pricing-card-info">
              <h5>Security for Bank Head Office & Branches</h5>
              <p>
                PIXSVMS Finance, manages bank head office cameras and also bank
                branches cameras.
                <br />
                <br />
                With our hierarchy module, bank managers can also access his/her
                cctv cameras and region managers have access to his/her region
                cameras and also reports according to their hierarchy.
                <br />
                <br />
                Within our browser based platform, also managers may have secure
                access to the system both at the branch Office and at their
                homes.
              </p>
              <img src="../../assets/images/2.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="pricing-card">
            <div class="pricing-card-info-img">
              <img
                src="../../assets/images/finance-card-third.png"
                alt="shape"
              />
            </div>

            <div class="pricing-card-info">
              <h5>Security for Cash Transfer Vehicle Operations</h5>
              <p>
                Most banks use their own cash transfer vehicles. These vehicles
                have to be monitored instantly by security professionals,
                PIXSVMS Finance solution allows them to see liev view and
                records online.
                <br />
                <br />
                Additional online map view is also added to this solution.
                Security center professionals tracks their cash transfer fleet
                on the map
                <br />
                <br />
                Instant monitoring alarms sent when incident happens.
              </p>
              <img src="../../assets/images/3.png" alt="shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardPricingSecond",
};
</script>
