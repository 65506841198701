<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>
          How do we overcome this problem with PIXPass by Plate Recognition?
        </h2>
      </div>
      <div class="row pb-60 d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              So our installed AI cameras on facility entrance and on ramp allow
              you to identify and differ the entrance time and docking time.
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              We get duration time of trucks inside the facility and on ramp
              area in order to determine the loading/unloading process of time.
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              We combine plate data with the order details and driver details to
              have a complete security and to get better understanding the
              operation data.
            </h5>
          </div>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>
          Process (Vehicle Entrances & Docking on Ramps by Plate Recognition)
        </h2>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-end">
          <img
            src="../../assets/images/vehicle-entrances-license/schema.png"
            alt="shape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardVehicleEntrancesLicenseSecond",
};
</script>
