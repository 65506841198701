<template>
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>Why Should I Benefit from CV Membership?</h2>
      </div>
  
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features d-flex align-items-center">
              <h5>
                Access filtered CVs tailored to your needs
              </h5>
            </div>
            <img
              src="../../assets/images/1.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features d-flex align-items-center">
              <h5>
                Get access to a large pool of candidates from the cooperation agreement with universities.
              </h5>
            </div>
            <img
              src="../../assets/images/2.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div class="partners-card-features d-flex align-items-center">
              <h5>Get access to an up-to-date candidate pool through our university student club partnerships.</h5>
            </div>
            <img
              src="../../assets/images/3.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="partners-card-features-general">
            <div
              class="partners-card-features d-flex align-items-center justify-content-center"
            >
              <h5>Delegate your HR Processes and save time and costs</h5>
            </div>
            <img
              src="../../assets/images/4.png"
              alt="shape"
              class="partners-card-features-img"
            />
          </div>
        </div>
      </div>
 
    </div>
  </template>
  
  <script>
  export default {
    name: "AiDeveloperAwaitYou",
  };
  </script>
  