<template>
  <div class="container pt-60">
    <div class="demo-request-header">
      <h1>DEMO REQUEST FORM</h1>
      <p>We’ll help you to find the suitable solution and pricing</p>
    </div>

    <div @submit.prevent="submit">
      <div class="demo-request-form-card">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Name and Surname</label>
              <input type="text" id="name" name="name" class="form-control" placeholder="Enter your name" :class="{ 'is-invalid': v$.form.name.$error }"
                v-model="form.name" />
              <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "Value is required" ? "Name and Surname is required" :
                    error.$message
                }} </small>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Phone</label>
              <vue-tel-input v-model="form.phone" mode="international" :preferredCountries="['us', 'gb', 'tr']"
                defaultCountry="us"  name="phone" :class="{ 'red-border': v$.form.phone.$error }" id="phone" required="true" validCharactersOnly="true"></vue-tel-input>
                <div class="input-errors" v-for="(error, index) of v$.form.phone.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "Value is required" ? "Phone number is required" :
                    error.$message
                }} </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Email</label>
              <input v-model="form.eMail" type="email" id="email" name="email" class="form-control" :class="{ 'is-invalid': v$.form.eMail.$error }"
                placeholder="example@mail.com" />
              <div class="input-errors" v-for="(error, index) of v$.form.eMail.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "Value is required" ? "Email is required" :
                    error.$message
                }} </small>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="form-group mb-20">
              <label>Company </label>
              <input v-model="form.company" type="text" id="company" name="company-name" class="form-control" :class="{ 'is-invalid': v$.form.company.$error }"
                placeholder="Enter company name" />
              <div class="input-errors" v-for="(error, index) of v$.form.company.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "Value is required" ? "Company name is required" :
                    error.$message
                }} </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <label>Comments</label>
              <textarea v-model="form.comments" type="text" id="comments" name="comments" class="form-control" :class="{ 'is-invalid': v$.form.comments.$error }"
                placeholder="Please add your comments" rows="4" />
              <div class="input-errors" v-for="(error, index) of v$.form.comments.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "Value is required" ? "Comments is required" :
                    error.$message
                }} </small>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <label>Which sector is your company operating?</label>
              <div class="form-group-btn" >
                <div id="btn-retail" class="btn-multiselect mr-10"  @click="addSector(1)">
                  <h5>Retail</h5>
                </div>
                <div id="btn-logistics" class="btn-multiselect mr-10" @click="addSector(2)">
                  <h5>Logistics</h5>
                </div>
                <div id="btn-finance" class="btn-multiselect" @click="addSector(3)">
                  <h5>Finance</h5>
                </div>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.form.sector.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "Value is required" ? "Sector  is required" :
                    error.$message
                }} </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <label>What do you need?</label>
              <select v-model="form.whatNeed" name="whatNeed" :class="{ 'is-invalid': v$.form.whatNeed.$error }" class="form-control">
                <option style="font-size: 20px" :value="''">
                  Please select an item
                </option>
                <option v-for="(item, index) in needOptions" :key="index" :value="item.text" style="font-size: 20px">
                  {{ item.text }}
                </option>
              </select>
              <div class="input-errors" v-for="(error, index) of v$.form.whatNeed.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "Value is required" ?
                    "Select an item" : error.$message
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <div class="form-check">
                <input v-model="form.isPrivacyPolicy" :class="{ 'is-invalid': v$.form.isPrivacyPolicy.$error }" class="form-check-input" type="checkbox" id="check" />
                <label class="form-check-label">
                  By clicking the button, you agree to the
                  <span data-bs-toggle="modal" data-bs-target="#termsOfService" style="color: #1f12fd;">Terms of
                    Service</span>
                  <!-- Terms of Service -->
                  <div class="modal fade" id="termsOfService" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <TermsOfService />
                  </div>
                  and
                  <span data-bs-toggle="modal" data-bs-target="#privacyPolicy" style="color: #1f12fd;">Privacy
                    Policy</span>
                  <!-- Privacy Policy -->
                  <div class="modal fade" id="privacyPolicy" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <PrivacyPolicy />
                  </div>
                </label>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.form.isPrivacyPolicy.$errors" :key="index">
                <small class="text-danger">{{
                  error.$message == "The value must be equal to the other value" ?
                    "Please read and click !" : error.$message
                }}</small>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="form-group mb-20">
              <button class="btn demo-request-form-btn" type="submit" @click="submit">
                <h5 class="m-0">GET STARTED</h5>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { mapActions } from "vuex";
import { createToast } from "mosha-vue-toastify";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";

import PrivacyPolicy from "../../components/Privacy/PrivacyPolicy.vue"
import TermsOfService from "../../components/Privacy/TermsOfService.vue"

export default defineComponent({
  name: "DemoRequesForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    VueTelInput,
    PrivacyPolicy,
    TermsOfService

  },
  data() {
    return {
      needOptions: [
        { value: 1, text: "Monitoring of cctv records & cameras" },
        { value: 2, text: "Counting visitors / people" },
        { value: 3, text: "Counting goods / inventories by Barcode / QR Code" },
        { value: 4, text: "Counting goods by AI Cameras" },
        { value: 5, text: "Counting goods by AI Cameras on Market Shelves" },
        { value: 6, text: "Logging personnel when entering facilities" },
        {
          value: 7,
          text: "Logging visitors when entering facilities / workplaces",
        },
      ],
      form: {
        name: "",
        phone: "",
        eMail: "",
        company: "",
        comments: "",
        sector: [],
        whatNeed: "",
        isPrivacyPolicy: false,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        phone: { required },
        eMail: { required, email },
        company: { required },
        comments: { required },
        sector: { required },
        whatNeed: { required },
        isPrivacyPolicy: { sameAs: sameAs(true) },
      },
    }
  },
  methods: {
    ...mapActions("main", ["postFormDemoRequest"]),
    submit() {
      this.v$.$touch()
      if (!this.v$.form.$invalid) {
        this.postFormDemoRequest(this.form)
          .then((res) => {
            createToast(res.message, {
              toastBackgroundColor: "#28a745",
              type: 'success',
            });
          })
          .then(() => {
            this.addSector(4);
            this.form = {
              name: "",
              phone: "",
              eMail: "",
              company: "",
              comments: "",
              sector: [],
              whatNeed: "",
              isPrivacyPolicy: false,
            };
            this.v$.$reset()
          })
          .catch((res) => {
            createToast(res.response.data.message, {
              toastBackgroundColor: "#dc3545",
              type: "danger",
            });
          });
      }

    },
    addSector(id) {
      if (id === 1) {
        let el = document.getElementById("btn-retail");
        el.classList.toggle("btn-multiselect-active");
        let isCheck = false;
        for (let i = 0; i < this.form.sector.length; i++) {
          if (this.form.sector[i] == "retail") {
            isCheck = true;
          }
        }
        if (isCheck) {
          this.form.sector = this.form.sector.filter(
            (value) => value !== "retail"
          );
        } else {
          this.form.sector.push("retail");
        }
      } else if (id === 2) {
        let el = document.getElementById("btn-logistics");
        el.classList.toggle("btn-multiselect-active");
        let isCheck = false;
        for (let i = 0; i < this.form.sector.length; i++) {
          if (this.form.sector[i] == "logistics") {
            isCheck = true;
          }
        }
        if (isCheck) {
          this.form.sector = this.form.sector.filter(
            (value) => value !== "logistics"
          );
        } else {
          this.form.sector.push("logistics");
        }
      } else if (id === 3) {
        let el = document.getElementById("btn-finance");
        el.classList.toggle("btn-multiselect-active");
        let isCheck = false;
        for (let i = 0; i < this.form.sector.length; i++) {
          if (this.form.sector[i] == "finance") {
            isCheck = true;
          }
        }
        if (isCheck) {
          this.form.sector = this.form.sector.filter(
            (value) => value !== "finance"
          );
        } else {
          this.form.sector.push("finance");
        }
      } else {
        let el = document.getElementById("btn-retail");
        el.classList.remove("btn-multiselect-active");
        let el1 = document.getElementById("btn-logistics");
        el1.classList.remove("btn-multiselect-active");
        let el2 = document.getElementById("btn-finance");
        el2.classList.remove("btn-multiselect-active");
      }
    },
  },
});
</script>
