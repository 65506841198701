<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleSaasCloudTagMembership />
    <AiDeveloperCardSaasCloudTagMembership />
    <SaasCloudTagMembershipCard />
    <AiDeveloperWhyChoose />
    <br>
    <br>
    <ProcessAcademyForSaasCloud />
    <AiDeveloperWhyChoose2 />
    <SaasCloudOurSolution/>
    <PaymentSaas/>

    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar";
import PageTitleSaasCloudTagMembership from "../../SaasCvMembership/PageTitleSaasCloudTagMembership.vue"
import AiDeveloperCardSaasCloudTagMembership from "../../AiDeveloper/AiDeveloperCardSaasCloudTagMembership.vue";
import AiDeveloperWhyChoose from "../../AiDeveloper/AiDeveloperWhyChoose.vue";
import AiDeveloperWhyChoose2 from "../../AiDeveloper/AiDeveloperWhyChoose2.vue";
import ProcessAcademyForSaasCloud from "../../HomeTwo/ProcessAcademyForSaasCloud.vue"
import SaasCloudTagMembershipCard from "../../SaasEmployers/SaasCloudTagMembershipCard.vue"
import SaasCloudOurSolution from "../../Logistic/SaasCloudOurSolution.vue";
import PaymentSaas from "../PixAcademyPages/PaymentSaas.vue";

import Footer from "../../Layout/Footer";

export default {
  name: "SaasCvMembership",
  components: {
    Navbar,

    PageTitleSaasCloudTagMembership,

    AiDeveloperCardSaasCloudTagMembership,

    AiDeveloperWhyChoose,
    AiDeveloperWhyChoose2,

    ProcessAcademyForSaasCloud,

    SaasCloudTagMembershipCard,
    PaymentSaas,
    SaasCloudOurSolution,

    Footer,
  },
};
</script>
