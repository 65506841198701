<template>
  <div class="bg-off-white">
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-xl">
        <h2>About Us</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6">
          <div class="about-card mb-24">
            <img src="../../assets/images/about/photo-card-1.png" alt="shape" />
          </div>
          <div class="about-card pb-15">
            <h4>What do we propose?</h4>
            <p>- A Start For Digital & AI Based Transformation</p>
            <p>- Fully autonomous control of workflow</p>
            <p>- Continuous improving platform</p>
            <p>- Optional On Cloud & On Premise Architecture</p>
            <p>- Satisfies hardware needs according to price and eligibility</p>
            <p>
              - Opportunity to buy different hardware trademark options which is
              fully integrated & detailed tested
            </p>
            <p>- Opportunity to integrate your existing cctv hardware</p>
            <p>
              - Integration capability to existing systems with many options
            </p>
            <p>
              - Full legal compatibility according to GDPR & Finance, Retail &
              Logistics Authorities
            </p>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6">
          <div class="about-card pb-38">
            <h4>What is our main focus?</h4>
            <p>
              PIXSelect offers an AI transformation to customers in Finance,
              Retail, Logistics. Within PIXSelect AI offerings any business
              owner may have this AI transformation according to their
              operations. Also existing infrastructure can be used or newly
              system architecture may have been provided. This allows business
              owners the opportunity to use existing cameras apart from security
              usage.
            </p>
            <br />
            <p>
              PIXSelect provides a continuous improving Software as a Service
              platform according to industry workflow and also provides a
              software modules according to company divisions. So anyone in
              these divisions can easily connect and simplify their organization
              needs with this cutting edge tech platform.
            </p>
            <br />
            <p>
              We provide a solution for businesses in industries according to
              their workflow such as;
            </p>
            <br />
            <p>
              Entrance - Production/Sales(Core Operation) Workflow - Logistics
              Operations Workflow
            </p>
            <br />
            <p>
              At the entrance area we provide solutions for Access Control -
              PIXPass, PIXCount People
            </p>
            <br />
            <p>
              At the production area we provide solutions for Operations -
              PIXCount Goods by Barcode&QR
            </p>
            <br />
            <p>
              At the logistics operation area we provide solutions for
              Businesses - PIXCount Goods by Barcode&QR, PIXCount Goods by AI
              Cameras
            </p>
            <br />
            <p>
              Our aim is to control, manage and operate an excellence business
              with our AI modules attached to our Vision Control System -
              PIXSVMS
            </p>
            <br />
            <p>
              Work with us, we can improve your workflow together with our
              Artificial Intelligence Based Monitoring Platform.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAbout",
};
</script>
