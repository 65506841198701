<template>
  <BlogSaasEmployers class="pt-100" />
  <div class="container pt-60 pb-60">
    <div class="row pt-60">
      <div class="col-12 col-md-12 col-lg-6 pt-30">
        <div class="section-title section-title-xl">
          <h2>Why Should I Prefer PIXSelect Talent Solutions?</h2>
        </div>
        <div class="contact-info-card">
          <p>
            14 days - We shortlist the candidate for the job position within 14
            days. We follow the pre-interview and skill / competence processes.
            No need to waste time with random applications from recruitment
            services.
          </p>
          <p>
            Affordable - You don't need to allocate large amounts of money to
            recruitment services even though you receive random applications.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 pt-30">
        <div class="section-title section-title-sm">
          <h2>What are we doing</h2>
        </div>
        <div class="contact-info-card">
          <p>
            Our only mission is to ensure that the software community in Turkey
            grows and achieves its goals.
          </p>
          <p>
            PIXSelect's modern, filtered and reliable approach meets your
            expectations, adds value to your team and enables you to scale.
          </p>
          <p>
            We see that we save time and cost with better filters instead of
            traditional recruitment methods, and we focus on improving the
            software community across Turkey.
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from "vue";
// import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { mapActions } from "vuex";
import { createToast } from "mosha-vue-toastify";
import BlogSaasEmployers from "../SingleBlog/BlogSaasEmployers.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";



export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  name: "SaasEmployersContact",
  components: {
    BlogSaasEmployers,
    // VueTelInput,

  },
  data() {
    return {
      form: {
        name_surname: "",
        phone: "",
        email: "",
        company: "",
        comments: "",
        isPrivacyPolicy: false,
      },
    };
  },
  validations() {
    return {
      form: {
        name_surname: { required },
        phone: { required },
        email: { required, email },
        company: { required },
        comments: { required },
        isPrivacyPolicy: { sameAs: sameAs(true) },
      },
    }
  },
  methods: {
    ...mapActions("main", ["postContactAcademyForm"]),
    submit() {
      this.v$.$touch()
      if (this.form.isPrivacyPolicy == true) {
        this.postContactAcademyForm(this.form)
          .then((res) => {
            createToast(res.message, {
              toastBackgroundColor: "#28a745",
              type: "success",
            });
          })
          .then(() => {
            this.form = {
              name_surname: "",
              phone: "",
              email: "",
              company: "",
              comments: "",
              isPrivacyPolicy: false,
            };
            this.v$.$reset()
          })
          .catch((res) => {
            createToast(res.response.data.message, {
              toastBackgroundColor: "#dc3545",
              type: "danger",
            });
          });
      }
    },
  },
});
</script>
