<template>
  <div class="container pt-60 pb-60">
    <SystemArchitecture :title="title" :imageUrl="url" />

    <div class="section-title section-title-xl pt-60">
      <h2>Supported Hardware</h2>
    </div>
    <div class="goods-ai-warehouses-info">
      <div class="row">
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>4MP DeepinView Indoor Moto Varifocal Dome Camera</h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>4MP DeepinView Indoor Moto Varifocal Dome Camera</h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>4MP DeepinView Indoor Moto Varifocal Dome Camera</h6>
        </div>
      </div>
    </div>

    <div class="section-title section-title-xl pt-60">
      <h2>Which hardware do you need to apply our solution?</h2>
    </div>

    <div class="turnstiles-info-solution">
      <div class="row align-items-center">
        <div class="col-12 col-md col-lg">
          <h6>For Only Turnstile Access:</h6>
          <p>A turnstile reader with face terminal for every turnstile</p>
        </div>
        <div class="col-12 col-md-1 col-lg-1 d-flex justify-content-center">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md col-lg">
          <h6>For Only Door Access:</h6>
          <p>A Door Reader (Face Terminal Model) for every door</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SystemArchitecture from "../Common/SystemArchitecture.vue";

export default defineComponent({
  name: "CareerTurnstilesFaceRecognition",
  components: {
    SystemArchitecture,
  },
  data() {
    return {
      url: require("../../assets/images/turnstiles-face-recognition/isometric.png"),
      title: "PIXPass Access & Log Solutions System Architecturee",
    };
  },
});
</script>
