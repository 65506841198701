<template>
  <div class="container pb-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 pt-120">
        <div id="card-employers" class="saas-card-active" style="cursor:pointer" @click="goToSaasEmployers">
          <!-- @click="activeCard(1)" -->
          <img class="saas-card-img" src="../../assets/images/saas-candidates/photo-card-1.png" />
          <div class="saas-card-text">
            <h5>Looking For New Talents</h5>
            <p>Employers</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 pt-120">
        <div id="card-candidates" class="saas-card" @click="goToSaasCandidates" style="cursor:pointer">
          <img class="saas-card-img" src="../../assets/images/saas-candidates/photo-card-2.png" />
          <div class="saas-card-text">
            <h5>Looking For a New Career</h5>
            <p>Candidates</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showPage === 'candidates'">
    <SaasCandidates />
  </div>
  <div v-if="showPage === 'employers'">
    <SaasEmployers />
  </div>
</template>

<script>
import SaasCandidates from "../Pages/PixAcademyPages/SaasCandidates.vue";
import SaasEmployers from "../Pages/PixAcademyPages/SaasEmployers.vue";

export default {
  components: {
    SaasCandidates,
    SaasEmployers,
  },
  name: "SaasCvMembershipCard",
  data() {
    return {
      showPage: "",
    };
  },
  methods: {
    activeCard(id) {
      if (id === 1) {
        this.showPage = "employers";
        let el = document.getElementById("card-employers");
        el.classList.add("saas-card-active");
        el.classList.remove("saas-card");
        let el2 = document.getElementById("card-candidates");
        el2.classList.add("saas-card");
        el2.classList.remove("saas-card-active");
        el.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (id === 2) {
        this.showPage = "candidates";
        let el2 = document.getElementById("card-candidates");
        el2.classList.add("saas-card-active");
        el2.classList.remove("saas-card");
        let el = document.getElementById("card-employers");
        el.classList.remove("saas-card-active");
        el.classList.add("saas-card");
        el2.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    goToSaasEmployers() {
      window.open('/saas-employers', '_blank');
    },
    goToSaasCandidates() {
      window.open('/saas-candidates', '_blank');
    }
  },
};
</script>
