<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>What capabilities are we working on?</h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            We support the software ecosystem with PIXSelect's filtered talent
            management system and development routes <br />
            that comprehensively support its community.
          </p>
        </div>
      </div>
      <div class="row pb-60">
        <div class="col-12">
          <img
            src="../../assets/images/capabilities.png"
            alt="shape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaasEmployersCardSecond",
};
</script>
