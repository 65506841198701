<template>
  <div class="service-section pt-min-90 pb-70 bg-off-white">
    <div class="container">
      <br />
      <div class="section-title section-title-lg">
        <h2>PIXSelect Talent Solutions</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>We are in the Software Industry</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                We are known as an R&D company that has developed 10 products in
                the field of artificial intelligence, meeting the needs of
                qualified software developers of the software industry.
              </p>
              <div
                style="
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  top: 165px;
                  left: 363px;
                "
              >
                <img src="../../assets/images/1.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>We are in talents</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                We have two offices within ITU and MEF Technology Development
                Center. We are aware of the needs in the field of access to
                qualified workforce.
              </p>
              <div
                style="
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  top: 165px;
                  left: 363px;
                "
              >
                <img src="../../assets/images/2.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>Delegate your processes</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                Reaching talents, filtering <br />
                Recruiting <br />
                Orientation / onboard
              </p>
              <div
                style="
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  top: 165px;
                  left: 363px;
                "
              >
                <img src="../../assets/images/3.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>Alumni Network</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                We offer Follow Up programs, which are our current training
                support, for their current employees to the institutions we
                cooperate with.
              </p>
              <div
                style="
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  top: 165px;
                  left: 363px;
                "
              >
                <img src="../../assets/images/4.png" alt="shape" />
              </div>
            </div>
          </div>
        </div> <br><br>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>BOOTCAMP</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                We offer an 8-week intensive training program that <br />
                trains software developers according to the needs of
                institutions.
              </p>
              <div
                style="
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  top: 165px;
                  left: 363px;
                "
              >
                <img src="../../assets/images/5.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h5>Benefits of your CV Pool</h5>
            </div>
            <div class="academy-card-brief">
              <p>
                We provide access to a filtered CV talent pool to the
                institutions with whom we collaborate with the program.
              </p>
              <div
                style="
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  top: 165px;
                  left: 363px;
                "
              >
                <img src="../../assets/images/6.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
