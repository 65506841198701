<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>PIXSVMS Retail CCTV Monitoring Features</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Health Report Analysis</h5>
              <p>
                You can query how many cameras are connected in the system, how
                many are active, how many of them are recording, as well as the
                most basic information, also can access the record days info and
                how may days left in your Hard Drive. You can see your health
                report instant or a specific time range.
              </p>
            </div>
            <img src="../../assets/images/1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Instant Alarms & Logs</h5>
              <p>
                The log records on your devices will be stored for a
                retrospective year and the instant notification of the logs
                generated by the alarm to the user. Device logs include; who
                accessed the devices, who downloaded records, who has added any
                user in the system etc.
              </p>
            </div>
            <img src="../../assets/images/2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Remote DVR-NVR Management, Batch Operations</h5>
              <p>
                You can change camera display settings, can enable and draw,
                mask, motion area,etc remotely. Also , you can to change the
                password of your recorders, enable or disable alarms.
              </p>
            </div>
            <img src="../../assets/images/3.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Automatic Control & Task Management</h5>
              <p>
                You can easily create a task for future downloading your
                recorded local files or snapshots. At the same time you can
                create a task for updating your DVR’s time.
              </p>
            </div>
            <img src="../../assets/images/4.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Smart Alarm Notifications</h5>
              <p>
                Our platform refines all the information from the DVR’s/NVR’s
                and turns these refined information into alarms. You can even
                get whatever alarms needed according to your company needs.
              </p>
            </div>
            <img src="../../assets/images/5.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">User Authorization & Delegation</h5>
              <p>
                Our platform also works integrated with your company Active
                Directory and also our authorization and delegation architecture
                gives us the opportunity to have a specialized user
                authorization system.
              </p>
            </div>
            <img src="../../assets/images/6.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row pb-30">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Integrations with your Cashier & POS Data</h5>
              <p>
                Your Cashier & POS Data is integrated with our cctv platform.
                You can easily detect return processes and investigate via cctv
                footage in details(with electronic zoom capabilities) instantly.
              </p>
            </div>
            <img src="../../assets/images/7.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Scene Change Detection by AI Algorithms</h5>
              <p>
                From every camera you can get scene change alarms automatically
                by taking snapshots of every camera; comparing with its default
                image by AI algorithms.
              </p>
            </div>
            <img src="../../assets/images/8.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="bank-cctv-card-features-general">
            <div class="retail-cctv-card">
              <h5 class="mt-20">Count People by AI Algorithms</h5>
              <p>
                On every retail entrance or in store areas such as changing room
                entrance, you can count people and get these refined data by our
                AI cameras integrated with your cctv system.Also your cctv IP
                cameras can be used with our AI processors.
              </p>
            </div>
            <img src="../../assets/images/9.png" alt="shape" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardRetailCctvThird",
};
</script>
