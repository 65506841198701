<template>
  <div class="container pt-60">
    <div class="section-title section-title-lg">
      <h2>Usage Areas</h2>
    </div>
    <div class="row goods-ai-warehouses-info-text pb-60">
      <div class="col-12 col-md-6 col-lg-6 pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/warehouse-cctv/photo-1.png"
            alt="partner"
          />
          <h6>Factories</h6>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/warehouse-cctv/photo-2.png"
            alt="partner"
          />
          <h6>Warehouses</h6>
        </div>
      </div>
    </div>
    <BlogCountPeople />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";

export default defineComponent({
  name: "ClientsWarehousesCctv",
  components: {
    BlogCountPeople,
  },
});
</script>
