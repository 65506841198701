<template>
  <div class="container pt-60 pb-60">
    <SystemArchitecture :title="title" :imageUrl="url" />

    <div class="section-title section-title-xl pt-60">
      <h2>Supported Hardware</h2>
    </div>
    <div class="goods-ai-warehouses-info">
      <div class="row">
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>4MP DeepinView Indoor Moto Varifocal Dome Camera</h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>4MP DeepinView Indoor Moto Varifocal Dome Camera</h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <img
            src="../../assets/images/goods-cameras-warehouses/photo-4.png"
            alt="shape"
          />
          <h6>4MP DeepinView Indoor Moto Varifocal Dome Camera</h6>
        </div>
      </div>
    </div>

    <div class="section-title section-title-xl pt-60">
      <h2>Which hardware do you need to apply our solution?</h2>
    </div>
    <div class="goods-ai-warehouses-info-solution">
      <div class="row">
        <div class="col-12 col-md-12 col-lg">
          <h6>
            First Option:
            <span>
              You have existing CCTV(see approved hardware). Our system
              professionals check your installed hardware if we can use them. If
              available; only our processors with our installed AI algorithms
              will be enough to get ready!
            </span>
          </h6>
        </div>
        <div class="col-12 col-md-12 col-lg-1">
          <img
            src="../../assets/images/line.png"
            alt="shape"
            class="goods-ai-warehouses-info-line"
          />
        </div>
        <div class="col-12 col-md-12 col-lg">
          <h6>
            Second Option:
            <span>
              Newly AI camera will be installed to prepare your counting area.
            </span>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SystemArchitecture from "../Common/SystemArchitecture.vue";

export default defineComponent({
  name: "CareerCountPeople",
  components: {
    SystemArchitecture,
  },
  data() {
    return {
      url: require("../../assets/images/count-people/isometric.png"),
      title: "PIXCount People System Architecture",
    };
  },
});
</script>
