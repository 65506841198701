<template>
  <div>
    <Navbar class="bg-white" />
<PageTitleSaasCandidates/>

    <SaasCandidatesCard />
    <SaasCandidatesOffer />
    <SaasCandidatesMission />
    <ClientsAcademy />
    <Footer />

  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar";
import PageTitleSaasCandidates from "../../SaasCvMembership/PageTitleSaasCandidates.vue";

import SaasCandidatesCard from "../../SaasCandidates/SaasCandidatesCard.vue";
import SaasCandidatesOffer from "../../SaasCandidates/SaasCandidatesOffer.vue";
import SaasCandidatesMission from "../../SaasCandidates/SaasCandidatesMission.vue";
import ClientsAcademy from "../../OurClients/ClientsAcademy.vue";
import Footer from "../../Layout/Footer";


export default {
  name: "SaasCandidates",
  components: {
    Navbar,
    PageTitleSaasCandidates,
    SaasCandidatesCard,
    SaasCandidatesOffer,
    SaasCandidatesMission,
    ClientsAcademy,
    Footer
  },
};
</script>
