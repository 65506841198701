<template>
    <div class="inner-page-header">
      <div class="container">
        <div class="row">
          <div class="col-sm d-flex align-items-center">
            <div class="inner-header-content-academy">
              <h1>
                PIXSVMS Retail CCTV  <br />
                Monitoring
              </h1>
              <br />
              <h3>PIXSVMS CCTV Monitoring Solutions</h3>
              <br />
              <div>
                <router-link to="/demo-request" class="btn main-btn-academy">
                  DEMO REQUEST
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-sm d-flex justify-content-end">
            <img
              class="goods-ai-warehouses-page-header-img"
              src="../../assets/images/bank-cctv/header.png"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PageTitleBankCctv",
  };
  </script>
  