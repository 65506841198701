<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>How do we overcome this problem with PIXPass by ID?</h2>
      </div>
      <div class="row pb-60 d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              No operation is needed when National ID cards are used in the
              system. We can get all information to let both visitors and
              personnel working in that building from their ID cards. So no need
              a distributing and collecting operation; this will be much more
              efficient and cheap.
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              We get all needed information according to GPDR issues and this
              data is not stored on the edge device; it is securelt transmitted
              to your on premise and/or cloud server. Data is securely kept with
              no penalty risk.
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              Within our software system, you can have options to have timely
              permissions to visitors, online/offline accessing senarios and
              detailed reports.
            </h5>
          </div>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>Process (Security Desk)</h2>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-end">
          <img
            src="../../assets/images/turnstiles-id-cards/schema.png"
            alt="shape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTurnstilesIDCardsSecond",
};
</script>
