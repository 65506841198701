<template>
    <div class="container pt-10">
        <div class="section-title section-title-xl pb-30">
            <h2>Key Features</h2>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Data Labeling</h5>
                            <p>
                                Easily annotate and make sense of your data with an intuitive interface.
                            </p>
                            <br>
                        </div>
                    </div>

                    <img src="../../assets/images/1.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Model Training</h5>
                            <p>
                                Train your own custom models using leading open-source models.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/2.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Deployment</h5>
                            <p>
                                Prepare and scale your customized models for production-ready use.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/3.png" alt="shape" class="partners-card-features-img" />
                </div>
                
            </div>
            <div class="col-12 col-md-6 col-lg-6">
                <div class="partners-card-features-general">
                    <div class="partners-card-features d-flex align-items-center">
                        <div>
                            <h5>Integration</h5>
                            <p>
                                Seamlessly integrate with important infrastructure and services.
                            </p>
                            <br>
                        </div>
                    </div>
                    <img src="../../assets/images/4.png" alt="shape" class="partners-card-features-img" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "AiDeveloperAwaitYou",
};
</script>
  