<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>Use Cases</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>Security for Whole Logistics Operations</h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="logistic-card-usecase-security">
              <p>
                The Ministry requests notification when registration is stopped
                in Warehouses. In order to notify these notifications on time,
                according to the regulation, a software is needed that will
                automatically follow it.
              </p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="logistic-card-usecase-security">
              <p>
                In order to manage the cctvs of warehouses and warehouses under
                Customs regulation with cloud software and receive instant
                alarms; PIXSVMS-Warehouse Monitoring
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>Ramp operations using Barcode & QR Technology</h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="logistic-card-usecase-ramp-barcode">
              <p>
                The entry of the trucks to the warehouse area and the
                loading&unloading of goods are done manually, which creates
                problems.
              </p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="logistic-card-usecase-ramp-barcode">
              <p>
                It digitizes the entire process, every parcel/palette/product
                can be counted by a Barcode Reader and also in these processes
                cctv videos are linked by the start-end time of each count
                operation. Also when counted one by one photos are recorded to
                the barcode ID for evidence; instant logs, reports are easily
                created by our platform.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-3.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>Ramp operations using AI Cameras</h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="logistic-card-usecase-ramp-cameras">
              <p>Managing performance and workflow by vision cameras.</p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="logistic-card-usecase-ramp-cameras">
              <p>
                It is our platform that monitors and logs every entrance of
                trucks in warehouses. On ramps trucks are logged according to
                states such as truck entered, truck doors closed, truck doors
                opened, goods started to unload, goods count System is for
                acceptance of goods so that instantly detects and counts
                pallette/packages over existing cctv images. Also we can work
                with all onvif cameras and also may provide IP cameras.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase">
            <img src="../../assets/images/logistic/usecase-4.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="logistic-card-usecase-title">
            <h4>Warehouse Operations</h4>
          </div>
          <div class="logistic-card-usecase-problem">
            <div class="logistic-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="logistic-card-usecase-warehouse">
              <p>
                Managing and checking whether goods are ready on delivery on
                warehouse area is a tricky issue and also managing shelves,
                placement and transfering pallettes, packages inside or outside
                warehouses needs to have a good workflow and controlling
                capabilities.
              </p>
            </div>
          </div>
          <div class="logistic-card-usecase-solution">
            <div class="logistic-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="logistic-card-usecase-warehouse">
              <p>
                Our platform can detect every object, such as goods on shelves,
                pallettes, packages. On every frequency system checks planogram
                and if sees any difference or if sees any gap on the shelf, an
                alarm is generated to warehouse operation personnel. Integrated
                with order system, our vision AI checks whether there is wrong
                delivery or not.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogisticUseCase",
};
</script>
