<template>
    <div>
        <Preloader v-if="isLoading" />
        <router-view />
        <ScrollTop />
        <ChatBot />
    </div>
</template>

<script>
import Preloader from './components/Layout/Preloader'
import ScrollTop from './components/Layout/ScrollTop'
import ChatBot from './components/Layout/ChatBot' 

export default {
    name: 'App',
    components: {
    Preloader,
    ScrollTop,
    ChatBot
},
    data() {
        return {
            isLoading: true
        }
    },
    mounted() {
        document.body.style.zoom = "90%";
        setTimeout(() => {
            this.isLoading = false
        }, 1000)
    }
}
</script>