import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomePage from "../components/Pages/HomePage";
// import HomePageOne from "../components/Pages/HomePageOne";
// import HomePageTwo from "../components/Pages/HomePageTwo";
// import HomePageThree from "../components/Pages/HomePageThree";
import AboutPage from "../components/Pages/AboutPage";
// import ServicesPage from "../components/Pages/ServicesPage";
// import SingleServicePage from "../components/Pages/SingleServicePage";
import PricingPage from "../components/Pages/PricingPage";
import LogisticPage from "../components/Pages/LogisticPage";
import RetailPage from "../components/Pages/RetailPage";
import CareersPage from "../components/PIXSelectCareers/Careers";
// import FaqPage from "../components/Pages/FaqPage";
// import TeamPage from "../components/Pages/TeamPage";
// import ProjectsPage from "../components/Pages/ProjectsPage";
// import SingleProjectsPage from "../components/Pages/SingleProjectsPage";
// import CasesStudyPage from "../components/Pages/CasesStudyPage";
// import CasesStudyTwoPage from "../components/Pages/CasesStudyTwoPage";
// import SingleCasesStudyPage from "../components/Pages/SingleCasesStudyPage";
// import TestimonialPage from "../components/Pages/TestimonialPage";
// import OurClientsPage from "../components/Pages/OurClientsPage";
// import CareersPage from "../components/Pages/CareersPage";
// import ProductsPage from "../components/Pages/ProductsPage";
//  import SingleProductsPage from "../components/Pages/SingleProductsPage";
// import CartPage from "../components/Pages/CartPage";
// import CheckoutPage from "../components/Pages/CheckoutPage";
// import AuthenticationPage from "../components/Pages/AuthenticationPage";
// import ForgetPasswordPage from "../components/Pages/ForgetPasswordPage";
// import MyAccountPage from "../components/Pages/MyAccountPage";
// import MyOrdersPage from "../components/Pages/MyOrdersPage";
// import MyAddressesPage from "../components/Pages/MyAddressesPage";
// import DemoProductPage from "../components/Pages/DemoProductPage";
// import FeedbackPage from "../components/Pages/FeedbackPage";
// import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
// import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
// import SearchPage from "../components/Pages/SearchPage";
import ErrorPage from "../components/Pages/ErrorPage";
// import ComingSoonPage from "../components/Pages/ComingSoonPage";
// import BlogPage from "../components/Pages/BlogPage";
// import BlogRightSidebarPage from "../components/Pages/BlogRightSidebarPage";
// import SingleBlogPage from "../components/Pages/SingleBlogPage";
 import ContactPage from "../components/Pages/ContactPage";
import GoodsByBarcodeOnRamps from "../components/Pages/ProductsPage/PixAi/GoodsByBarcodeOnRamps";
import GoodsByBarcodeInWarehouses from "../components/Pages/ProductsPage/PixAi/GoodsByBarcodeInWarehouses";
import GoodsByAICamerasInWarehouses from "../components/Pages/ProductsPage/PixAi/GoodsByAICamerasInWarehouses";
import GoodsByAICamerasOnRamps from "../components/Pages/ProductsPage/PixAi/GoodsByAICamerasOnRamps";
import ShelvesByAICamerasInStores from "../components/Pages/ProductsPage/PixAi/ShelvesByAICamerasInStores";
import ShelvesByAICamerasInWarehouses from "../components/Pages/ProductsPage/PixAi/ShelvesByAICamerasInWarehouses";
import PIXCountPeople from "../components/Pages/ProductsPage/PixAi/PIXCountPeople";
import BankCctv from "../components/Pages/ProductsPage/PixSvms/BankCctv";
import RetailCctv from "../components/Pages/ProductsPage/PixSvms/RetailCctv";
import WarehousesCctv from "../components/Pages/ProductsPage/PixSvms/WarehousesCctv";
import TurnstilesFaceRecognition from "../components/Pages/ProductsPage/PixPass/TurnstilesFaceRecognition";
import TurnstilesIDCards from "../components/Pages/ProductsPage/PixPass/TurnstilesIDCards";
import VehicleEntrancesLicense from "../components/Pages/ProductsPage/PixPass/VehicleEntrancesLicense";
import Partners from "../components/Pages/Partners";
import DemoRequest from "../components/Pages/DemoRequest";
import PIXAcademyEntry from "../components/Pages/PIXAcademyEntry";
import FrontendBackend from "../components/Pages/PixAcademyPages/FrontendBackend";
import ItSupportSpecial from "../components/Pages/PixAcademyPages/ItSupportSpecial";
import AiDeveloper from "../components/Pages/PixAcademyPages/AiDeveloper";
import ResellerFormPage from "../components/Pages/ResellerFormPage";
// import SaasCandidates from "../components/Pages/PixAcademyPages/SaasCandidates"
// import SaasEmployers from "../components/Pages/PixAcademyPages/SaasEmployers"
import SaasCvMembership from "../components/Pages/PixAcademyPages/SaasCvMembership"
import SaaSCloudTagMembership from "../components/Pages/PixAcademyPages/SaaSCloudTagMembership"
import SaaSCloudFullTagMembership from "../components/Pages/PixAcademyPages/SaaSCloudFullTagMembership"
import Partner from "../components/Pages/PartnerRequest.vue";
import SaasEmployers from "../components/Pages/PixAcademyPages/SaasEmployers.vue";
import SaasCandidates from "../components/Pages/PixAcademyPages/SaasCandidates.vue";
import AIVisionTrainer from "../components/AIVISIONTRAINER/index.vue";

const routes = [
  { path: "/", component: HomePage },
  // { path: "/home-one", component: HomePageOne },
  // { path: "/home-two", component:  HomePageTwo },
  // { path: "/home-three", component: HomePageThree },
  { path: "/about-us", component: AboutPage },
  // { path: "/services", component: ServicesPage },
  // { path: "/single-services", component: SingleServicePage },
  { path: "/finance", component: PricingPage },
  { path: "/logistic", component: LogisticPage },
  { path: "/retail", component: RetailPage },
  { path: "/careers", component: CareersPage },
  // { path: "/faqs", component: FaqPage },
  // { path: "/our-team", component: TeamPage },
  { path: "/PIXCoding-frontend-backend", component: FrontendBackend },
  { path: "/PIXCoding-IT-support", component: ItSupportSpecial },
  { path: "/ai-followup-programme", component: AiDeveloper },
  // { path: "/single-project", component: SingleProjectsPage },
  // { path: "/cases", component: CasesStudyPage },
  // { path: "/cases-two-columns", component: CasesStudyTwoPage },
  // { path: "/single-case", component: SingleCasesStudyPage },
  // { path: "/testimonial", component: TestimonialPage },
  // { path: "/our-clients", component: OurClientsPage },
  // { path: "/careers", component: CareersPage },
  //  { path: "/products", component: ProductsPage },
  //  { path: "/single-product", component: SingleProductsPage },
  // { path: "/cart", component: CartPage },
  // { path: "/checkout", component: CheckoutPage },
  // { path: "/authentication", component: AuthenticationPage },
  // { path: "/forget-password", component: ForgetPasswordPage },
  // { path: "/my-account", component: MyAccountPage },
  // { path: "/my-orders", component: MyOrdersPage },
  // { path: "/my-addresses", component: MyAddressesPage },
  // { path: "/demo-product", component: DemoProductPage },
  // { path: "/feedback", component: FeedbackPage },
  // { path: "/terms-of-service", component: TermsOfServicePage },
  // { path: "/privacy-policy", component: PrivacyPolicyPage },
  // { path: "/search-page", component: SearchPage },
  { path: "/:pathMatch(.*)*", component: ErrorPage },
  // { path: "/coming-soon", component: ComingSoonPage },
  // { path: "/blogs", component: BlogPage },
  // { path: "/blogs-right-sidebar", component: BlogRightSidebarPage },
  // { path: "/single-blog", component: SingleBlogPage },
  { path: "/contact", component: ContactPage },
  { path: "/goods-barcode-ramps", component: GoodsByBarcodeOnRamps },
  { path: "/goods-barcode-warehouses", component: GoodsByBarcodeInWarehouses },
  { path: "/goods-cameras-ramps", component: GoodsByAICamerasOnRamps },
  {
    path: "/goods-cameras-warehouses",
    component: GoodsByAICamerasInWarehouses,
  },
  { path: "/shelves-cameras-stores", component: ShelvesByAICamerasInStores },
  {
    path: "/shelves-cameras-warehouses",
    component: ShelvesByAICamerasInWarehouses,
  },
  { path: "/pixcount-people", component: PIXCountPeople },
  { path: "/bank-cctv", component: BankCctv },
  { path: "/retail-cctv", component: RetailCctv },
  { path: "/warehouses-cctv", component: WarehousesCctv },
  { path: "/turnstiles-id-cards", component: TurnstilesIDCards },
  {
    path: "/turnstiles-face-recognition",
    component: TurnstilesFaceRecognition,
  },
  { path: "/vehicle-entrances-license", component: VehicleEntrancesLicense },
  { path: "/partners", component: Partners },
  { path: "/demo-request", component: DemoRequest },
  { path: "/pixacademy-registration", component: PIXAcademyEntry },
  { path: "/reseller-form", component: ResellerFormPage },
  // { path: "/saas-candidates", component: SaasCandidates },
  // { path: "/saas-employers", component: SaasEmployers },
  { path: "/saas-cv-membership", component: SaasCvMembership },
  { path: "/saas-cloud-tag-membership", component: SaaSCloudTagMembership },
  { path: "/saas-cloud-tag-full-membership", component: SaaSCloudFullTagMembership },
  { path: "/partner-form", component: Partner },
  { path : "/saas-employers", component : SaasEmployers},
  { path : "/saas-candidates", component : SaasCandidates},
  { path: "/aivisiontrainer", component: AIVisionTrainer },

];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
