<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleGoodsBarcodeWarehouses />
    <CardGoodsBarcodeWarehouses />
    <CardGoodsBarcodeWarehousesSecond />
    <CareerGoodsBarcodeWarehouses />
    <CardGoodsBarcodeWarehousesThird />
    <ClientsGoodsBarcodeWarehouses />
    <BlogGoodsBarcodeWarehouses />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";
import PageTitleGoodsBarcodeWarehouses from "../../../GoodsByBarcodeInWarehouses/PageTitleGoodsBarcodeWarehouses.vue";
import CardGoodsBarcodeWarehouses from "../../../GoodsByBarcodeInWarehouses/CardGoodsBarcodeWarehouses.vue";
import CardGoodsBarcodeWarehousesSecond from "../../../GoodsByBarcodeInWarehouses/CardGoodsBarcodeWarehousesSecond.vue";
import CareerGoodsBarcodeWarehouses from "../../../GoodsByBarcodeInWarehouses/CareerGoodsBarcodeWarehouses.vue";
import CardGoodsBarcodeWarehousesThird from "../../../GoodsByBarcodeInWarehouses/CardGoodsBarcodeWarehousesThird.vue";
import ClientsGoodsBarcodeWarehouses from "../../../GoodsByBarcodeInWarehouses/ClientsGoodsBarcodeWarehouses.vue";
import BlogGoodsBarcodeWarehouses from "../../../GoodsByBarcodeInWarehouses/BlogGoodsBarcodeWarehouses.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "GoodsByBarcodeInWarehouses",
  components: {
    Navbar,
    PageTitleGoodsBarcodeWarehouses,
    CardGoodsBarcodeWarehouses,
    CardGoodsBarcodeWarehousesSecond,
    CareerGoodsBarcodeWarehouses,
    CardGoodsBarcodeWarehousesThird,
    ClientsGoodsBarcodeWarehouses,
    BlogGoodsBarcodeWarehouses,
    Footer,
  },
};
</script>
