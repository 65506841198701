<template>
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-lg">
        <h2>Vision AI for Finance</h2>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="pricing-card-visionAi-img">
            <img src="../../assets/images/pricing-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-lg-6 p-0">
          <div class="pricing-card-visionAi">
            <div class="pricing-card-visionAi-title">
              <h5>
                Securing ATM’s and bank branches needs a focus and detailed
                monitoring. Our CCTV monitoring platform satisfies these needs
                parallel with regulations.
              </h5>
            </div>
          </div>
          <div class="pricing-card-visionAi-list pt-20">
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Manage and control of your bank head Office entrance with our
                Access log solutions.
              </p>
            </div>

            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Manage and control all cctv devices; in bank branches, on ATM’s.
              </p>
            </div>
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Instantly monitoring of cctv recorders and cameras such as video
                loss, video tamper, video parameter change alarms.
              </p>
            </div>
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                AI Scene change module is attached to the central monitoring
                software.
              </p>
            </div>
            <div class="pricing-card-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                AI Human Occupancy Detection around ATM’s modüle is also
                attached to the central monitoring software.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "Pricing",
};
</script>
