<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>PIXCount People Features</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Multiple count places</h5>
              <p>You can count with multiple lines on one camera.</p>
            </div>
            <img
              src="../../assets/images/1.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Two-Way Counting</h5>
              <p>You can count people on two ways, either inside & outside.</p>
            </div>
            <img
              src="../../assets/images/2.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Traffic Analytics</h5>
              <p>
                You can differ your store’s measurement whether it is a Street
                store or in a Mall. You can see traffic data with respect to
                your store revenue.
              </p>
            </div>
            <img
              src="../../assets/images/3.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Weather Analytics</h5>
              <p>
                If it is a rainy day, your store’s performance will be affected
                whether it is a Street Store. You can see performance and
                weather data relatively.
              </p>
            </div>
            <img
              src="../../assets/images/4.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Cashier Analytics</h5>
              <p>
                Worried if your employer is all the time at your cashier desk?
                No pb, our algorithm defines an alarm when your employer is not
                at the cashier desk.
              </p>
            </div>
            <img
              src="../../assets/images/5.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Queuing Analytics</h5>
              <p>
                Can easily set an alarm when a queuing is happening at your
                stores. Also queue metric can be defined.
              </p>
            </div>
            <img
              src="../../assets/images/6.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Inventory Count</h5>
              <p>
                Our platform enables counting inventories. Our vision technology
                enables to read QR and barcodes with our mobile device; also
                counting process is logged by ID cards & cctv video footage is
                binded.
              </p>
            </div>
            <img
              src="../../assets/images/7.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Store Heat Map Analytics</h5>
              <p>
                You can easily view heatmap of a specific area in your store
                where a time period stands are visited most. This allows you to
                monitor performance of stand layouts and visitor flow in your
                store.
              </p>
            </div>
            <img
              src="../../assets/images/8.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="count-people-card-features-general">
            <div class="count-people-card-features">
              <h5>Integration with Cashier Data</h5>
              <p>
                Integration of your store cashier data, you can easily manage &
                control all shopping processes. You can easily see the
                conversion of data and see the specific problem when a decrease
                in a specific store.
              </p>
            </div>
            <img
              src="../../assets/images/9.png"
              alt="shape"
              class="count-people-card-features-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCountPeopleThird",
};
</script>
