<template>
    <div :class="['fixed-top', { 'non-fixed': isSticky }]">
        <div class="topbar">
            <div class="container">
                <div class="topbar-inner">
                    <div
                        class="row justify-content-center justify-content-lg-between"
                    >
                        <div class="topbar-item topbar-left">
                            <ul class="topbar-list">
                                <li>
                                    <i class="icofont-headphone"></i>
                                    <a href="tel:(+90)-850-225-04-49"
                                        >+90 850 225 04 49</a
                                    >
                                </li>
                                <li>
                                    <i class="icofont-ui-message"></i>
                                    <a href="mailto:info@pixselect.io"
                                        >info@pixselect.io</a
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="topbar-item topbar-right">
                            <ul class="topbar-list">
                                <li>
                                    <i class="icofont-paper-plane"></i>
                                    <router-link to="/contact"
                                        >Request a Call</router-link
                                    >
                                </li>
                                <li
                                    class="search-option"
                                    @click="overlay = !overlay"
                                    :aria-pressed="overlay ? 'true' : 'false'"
                                    v-bind:class="{
                                        overlay: button_overlay_state,
                                    }"
                                    v-on:click="
                                        button_overlay_state =
                                            !button_overlay_state
                                    "
                                >
                                    <i class="icofont-search-1"></i>
                                    <a href="#" class="search-popup">Search</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div :class="['navbar-area sticky-black', { 'is-sticky': isSticky }]">
            <div class="main-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-md navbar-light">
                        <router-link class="navbar-brand" to="/">
                            <img
                                src="../../assets/images/pixselect_logo.png"
                                alt="logo"
                                class="logo"
                            />
                        </router-link>

                        <div
                            class="navbar-toggler"
                            @click="active = !active"
                            :aria-pressed="active ? 'true' : 'false'"
                            v-bind:class="{ active: button_active_state }"
                            v-on:click="
                                button_active_state = !button_active_state
                            "
                        >
                            <i class="icofont-navigation-menu"></i>
                            <i class="icofont-close"></i>
                        </div>

                        <div
                            class="collapse navbar-collapse"
                            :class="{ show: active }"
                        >
                            <ul class="navbar-nav mx-auto">
                                <li class="nav-item">
                                    <router-link to="/" class="nav-link">
                                        HOME
                                    </router-link>
                                </li>
                                <!-- <li class="nav-item">
                                    <router-link to="/about-us" class="nav-link">
                                        About Us
                                    </router-link>
                                </li> -->
                                <li class="nav-item">
                                    <a href="#" class="nav-link dropdown-toggle"
                                        >PRODUCTS</a
                                    >
                                    <ul
                                        class="dropdown-menu dropdown-menu-products"
                                    >
                                        <div class="row m-4 lh-lg">
                                            <div class="col-sm">
                                                <li class="nav-item">
                                                    <a
                                                        href="#"
                                                        class="nav-link font-weight-bold"
                                                    >
                                                        <span class="fw-bold"
                                                            >PIXSVMS CCTV
                                                            Monitoring
                                                            Solutions</span
                                                        >
                                                    </a>
                                                    <ul>
                                                        <li class="nav-item">
                                                            <router-link
                                                                to="/bank-cctv"
                                                                class="nav-link"
                                                            >
                                                                - ATM's & Bank
                                                                Branch CCTV
                                                                Monitoring
                                                            </router-link>
                                                        </li>
                                                        <li class="nav-item">
                                                            <router-link
                                                                to="/retail-cctv"
                                                                class="nav-link"
                                                            >
                                                                - Retail CCTV
                                                                Monitoring
                                                            </router-link>
                                                        </li>
                                                        <li class="nav-item">
                                                            <router-link
                                                                to="/warehouses-cctv"
                                                                class="nav-link"
                                                            >
                                                                - Warehouse &
                                                                Factory CCTV
                                                                Monitoring
                                                            </router-link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </div>
                                            <div class="col-sm">
                                                <li class="nav-item">
                                                    <a
                                                        href="#"
                                                        class="nav-link"
                                                    >
                                                        <span class="fw-bold"
                                                            >PIXPass Access &
                                                            Log Solutions</span
                                                        >
                                                    </a>
                                                    <ul>
                                                        <li class="nav-item">
                                                            <a
                                                                href="#"
                                                                class="nav-link"
                                                            >
                                                                - Turnstiles &
                                                                Doors Access
                                                            </a>
                                                            <ul class="ms-4">
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/turnstiles-id-cards"
                                                                        class="nav-link"
                                                                    >
                                                                        by ID
                                                                        Cards
                                                                    </router-link>
                                                                </li>
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/turnstiles-face-recognition"
                                                                        class="nav-link"
                                                                    >
                                                                        by Face
                                                                        Recognition
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="#"
                                                                class="nav-link"
                                                            >
                                                                - Vehicle
                                                                Entrances &
                                                                Ramps
                                                            </a>
                                                            <ul class="ms-4">
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/vehicle-entrances-license"
                                                                        class="nav-link"
                                                                    >
                                                                        by
                                                                        License
                                                                        Plate
                                                                        Recognition
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </div>
                                            <!-- <div class="col-sm">
                                                <li class="nav-item">
                                                    <a
                                                        href="/aivisiontrainer"
                                                        class="nav-link"
                                                    >
                                                        <span class="fw-bold"
                                                            >AIVISIONTRAINER</span
                                                        >
                                                    </a>
                                                    <ul>
                                                        <li class="nav-item">
                                                            <a
                                                                href="/aivisiontrainer"
                                                                class="nav-link"
                                                            >
                                                                - SaaS CV
                                                                Membership
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="/aivisiontrainer"
                                                                class="nav-link"
                                                            >
                                                                - SaaS Cloud Tag
                                                                Membership
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="/aivisiontrainer"
                                                                class="nav-link"
                                                            >
                                                                - SaaS Cloud Tag
                                                                Full Membership
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="/aivisiontrainer"
                                                                class="nav-link"
                                                            >
                                                                - AI Follow-up
                                                                Programme
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </div> -->
                                            <div class="col-sm">
                                                <li class="nav-item">
                                                    <a
                                                        href="#"
                                                        class="nav-link"
                                                    >
                                                        <span class="fw-bold"
                                                            >PIXAI Artificial
                                                            Intelligence
                                                            Solutions</span
                                                        >
                                                    </a>
                                                    <ul>
                                                        <li class="nav-item">
                                                            <router-link
                                                                to="/pixcount-people"
                                                                class="nav-link"
                                                            >
                                                                - PIXCount
                                                                People
                                                            </router-link>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="#"
                                                                class="nav-link"
                                                            >
                                                                - PIXCount Goods
                                                                by Barcode & QR
                                                            </a>
                                                            <ul class="ms-4">
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/goods-barcode-warehouses"
                                                                        class="nav-link"
                                                                    >
                                                                        In
                                                                        Warehouses
                                                                    </router-link>
                                                                </li>
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/goods-barcode-ramps"
                                                                        class="nav-link"
                                                                    >
                                                                        On Ramps
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="#"
                                                                class="nav-link"
                                                            >
                                                                - PIXCount Goods
                                                                by AI Cameras
                                                            </a>
                                                            <ul class="ms-4">
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/goods-cameras-warehouses"
                                                                        class="nav-link"
                                                                    >
                                                                        In
                                                                        Warehouses
                                                                    </router-link>
                                                                </li>
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/goods-cameras-ramps"
                                                                        class="nav-link"
                                                                    >
                                                                        On Ramps
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a
                                                                href="#"
                                                                class="nav-link"
                                                            >
                                                                - PIXCount
                                                                Shelves by AI
                                                                Cameras
                                                            </a>
                                                            <ul class="ms-4">
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/shelves-cameras-warehouses"
                                                                        class="nav-link"
                                                                    >
                                                                        In
                                                                        Warehouses
                                                                    </router-link>
                                                                </li>
                                                                <li
                                                                    class="nav-item"
                                                                >
                                                                    <router-link
                                                                        to="/shelves-cameras-stores"
                                                                        class="nav-link"
                                                                    >
                                                                        In
                                                                        Stores
                                                                    </router-link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="#"
                                        class="nav-link dropdown-toggle"
                                    >
                                        SOLUTIONS BY INDUSTRIES
                                    </a>
                                    <ul
                                        class="dropdown-menu dropdown-menu-solution-by-ınd"
                                    >
                                        <div class="row m-4">
                                            <div class="col-sm">
                                                <li class="nav-item text-end">
                                                    <router-link
                                                        to="/finance"
                                                        class="nav-link"
                                                    >
                                                        <span class="fw-bold"
                                                            >Finance</span
                                                        >
                                                    </router-link>
                                                </li>
                                            </div>
                                            <div class="col-sm">
                                                <li
                                                    class="nav-item text-center"
                                                >
                                                    <router-link
                                                        to="/logistic"
                                                        class="nav-link"
                                                    >
                                                        <span class="fw-bold"
                                                            >Logistic</span
                                                        >
                                                    </router-link>
                                                </li>
                                            </div>
                                            <div class="col-sm">
                                                <li class="nav-item">
                                                    <router-link
                                                        to="/retail"
                                                        class="nav-link"
                                                    >
                                                        <span class="fw-bold"
                                                            >Retail</span
                                                        >
                                                    </router-link>
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a
                                        href="https://aivisiontrainer.com"
                                        target="_blank"
                                        class="nav-link"
                                        >AIVISIONTRAINER</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <router-link to="/partners" class="nav-link"
                                        >PARTNERS</router-link
                                    >
                                </li>
                            </ul>
                        </div>
                        <div class="navbar-option">
                            <div class="navbar-option-item">
                                <router-link
                                    to="/demo-request"
                                    class="btn main-btn"
                                >
                                    Demo Request
                                </router-link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>

        <div class="search-overlay" :class="{ search: overlay }">
            <div class="search-close" @click="overlay = !overlay">
                <i class="icofont-close"></i>
            </div>
            <div class="search-form-area">
                <div class="search-area-logo">
                    <router-link to="/">
                        <img
                            src="../../assets/images/pixselect-white.png"
                            alt="logo"
                        />
                    </router-link>
                </div>
                <form>
                    <div class="form-group search-form-group">
                        <input
                            type="text"
                            placeholder="Search..."
                            class="form-control"
                            autofocus="autofocus"
                        />
                        <button>
                            <i class="icofont-search-1"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Navbar",
        data() {
            return {
                isSticky: false,
                isMenu: false,
                active: false,
                button_active_state: false,
                overlay: false,
                button_overlay_state: false,
            };
        },
        mounted() {
            const that = this;
            window.addEventListener("scroll", () => {
                let scrollPos = window.scrollY;
                if (scrollPos >= 100) {
                    that.isSticky = true;
                } else {
                    that.isSticky = false;
                }
            });
        },
    };
</script>

<style scoped>
    .btn-check:active + .btn,
    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:active {
        color: #fff;
        background-color: #1f12fd;
        border-color: #1f12fd;
    }
    .navbar {
        box-shadow: 0 0px 0px 0;
    }
</style>
