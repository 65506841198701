<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>How do we overcome this problem with PIXPass by Face?</h2>
      </div>
      <div class="row pb-60 d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              No operation is needed when Face data are used in the system. We
              can verify personnel’s faces (by getting their GDPR approval with
              their signatures on their company contract) when entering
              buildings, meeting rooms or specific rental areas. So no need any
              distributing and collecting operation of proximity cards; this
              will be much more efficient and cheap.
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              We get all needed face data according to GPDR issues and this data
              is stored securely with 256 bit SSL encryption on our servers; it
              is also securely transmitted by encryption method to your on
              premise and/or cloud server. Data is securely kept with no penalty
              risk.
            </h5>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-4">
          <div class="turnstiles-overcome-card">
            <h5>
              Within our software system, you can have options to have timely
              permissions to visitors, online/offline accessing senarios and
              detailed reports.
            </h5>
          </div>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>Process (Turnstile & Door Access by Face)</h2>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-end">
          <img
            src="../../assets/images/turnstiles-face-recognition/schema.png"
            alt="shape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTurnstilesFaceRecognitionSecond",
};
</script>
