<template>
    <div class="preloader">
        <div class="position-relative loader-position">
            <div class="position-absolute top-50 start-50 translate-middle">
                <div class="boxes">
                    <div class="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="box">
                        <div>f</div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div class="box">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <!-- dribbble -->
                <a class="dribbble" href="https://dribbble.com/shots/5533600-Loading-boxes" target="_blank"><img
                        src="https://cdn.dribbble.com/assets/dribbble-ball-mark-2bd45f09c2fb58dbbfb44766d5d1d07c5a12972d602ef8b32204d28fa3dda554.svg"
                        alt=""></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script>