<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleItSupport />
    <ItSupportCard />
    <ItSupportProgram />
    <ItSupportAwaitYou />
    <ItSupportAcademyTeam />
    <ItSupportContributions />
    <ClientsAcademy />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar";
import PageTitleItSupport from "../../ItSupportSpecial/PageTitleItSupport.vue";
import ItSupportCard from "../../ItSupportSpecial/ItSupportCard.vue";
import ItSupportProgram from "../../ItSupportSpecial/ItSupportProgram.vue";
import ItSupportAwaitYou from "../../ItSupportSpecial/ItSupportAwaitYou.vue";
import ItSupportAcademyTeam from "../../ItSupportSpecial/ItSupportAcademyTeam.vue";
import ItSupportContributions from "../../ItSupportSpecial/ItSupportContributions.vue";
import ClientsAcademy from "../../OurClients/ClientsAcademy.vue";
import Footer from "../../Layout/Footer";

export default {
  name: "ItSupportSpecial",
  components: {
    Navbar,
    PageTitleItSupport,
    ItSupportCard,
    ItSupportProgram,
    ItSupportAwaitYou,
    ItSupportAcademyTeam,
    ItSupportContributions,
    ClientsAcademy,
    Footer,
  },
};
</script>
