<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>Who does the academy team consist of?</h2>
      </div>

      <div class="row pb-60">
        <div class="col-12">
          <img
            src="../../assets/images/it-support-special/academy-team.png"
            alt="shape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItSupportAcademyTeam",
};
</script>
