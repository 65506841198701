<template>
  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>Develop, Train, and Deploy Computer Vision Models with Ease</h4>
      </div>
      <div class="contact-info-card">
        <p>
          Are you looking to build and deploy powerful computer vision models?
          Look no further! Introducing AIVISIONTRAINER, the ultimate tool for
          creating, training, and deploying computer vision models.
        </p>
      </div>
    </div>
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>Unleash the Power of Computer Vision</h4>
      </div>
      <div class="contact-info-card">
        <p>
          With AIVISIONTRAINER, you can give your software the ability to see
          objects in images and videos.
        </p>
      </div>
    </div>
  </div>
  <br />

  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>Success, Now in Your Hands</h4>
      </div>
      <div class="contact-info-card">
        <p>
          AIVISIONTRAINER empowers you to curate, manage, and annotate visual
          data effortlessly. With our user-friendly interface and AI-assisted
          labeling, you can speed up your annotation workflows and achieve
          accurate and precise results.
        </p>
      </div>
    </div>
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>Custom Models, Hassle-Free</h4>
      </div>
      <div class="contact-info-card">
        <p>
          Training accurate models has never been easier. AIVISIONTRAINER
          provides you with the tools to develop, improve, and manage the
          lifecycle of your models. Leverage our hosted GPUs, bring your own
          models, or take advantage of our vast library of pre-trained models to
          save time and money.
        </p>
      </div>
    </div>
  </div>
  <br />

  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>Deploy Anywhere, Anytime</h4>
      </div>
      <div class="contact-info-card">
        <p>
          Our managed infrastructure allows you to deploy your custom models or
          choose from a variety of foundation models as a hosted API endpoint.
          Whether it's NVIDIA Jetson, or the browser, you have the flexibility
          to deploy anywhere, with device-optimized containers and field-tested
          SDKs.
        </p>
      </div>
    </div>
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>Streamline Collaboration and Project Management</h4>
      </div>
      <div class="contact-info-card">
        <p>
          Collaboration made simple! AIVISIONTRAINER offers streamlined
          workflows, job assignments, labeling instructions, and notifications,
          making it easy to manage annotation projects across multiple work
          streams. With secure role-based access, your data is always safe.
        </p>
      </div>
    </div>
  </div>
  <br />

  <div class="row pt-40 justify-content-center">
    <div class="col-md-5 col-lg-5 pt-30">
      <div class="section-title section-title-xl">
        <h4>Seamless Integration, Open APIs</h4>
      </div>
      <div class="contact-info-card">
        <p>
          Integrate AIVISIONTRAINER into any part of your pipeline with ease.
          Our open APIs, SDKs, and integrated developer tools allow you to
          customize, automate, and extend your pipeline to other applications
          seamlessly.
        </p>
        <p>
          Ready to take your computer vision projects to the next level? Try AIVISIONTRAINER now and see the difference for yourself.
        </p>
        <p>
          AIVISIONTRAINER is an easy way to manage, label, train, and deploy
          your image data. The ecosystem of this platform includes all the tools
          that enable any business to enhance its success by building the best
          computer vision models.
        </p>
      </div>
    </div>
  </div>

  <br />
</template>
<style scoped>
.contact-info-card {
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  min-height: 235px !important;
  display: flex; /* Kartları yatayda hizalar */
  flex-direction: column; /* İçerikleri dikeyde hizalar */
  justify-content: space-between; /* İçerikleri yukarıdan ve aşağıdan dağıtır */
}
</style>