<template>
  <div @submit.prevent="onSubmit">
    <div class="row justify-content-center mt-30">
      <div class="col-md-6">
        <div class="form-group mb-20">
          <label>Name and Surname</label><input class="form-control" placeholder="Enter your name" type="text"
            v-model="formAcademy.name" name="name" id="name" :class="{ 'is-invalid': v$.formAcademy.name.$error }"/>
          <div class="input-errors" v-for="(error, index) of v$.formAcademy.name.$errors" :key="index">
            <small class="text-danger">{{
              error.$message ==
                "Value is required"
                ? "Name and Surname are required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-20">
          <label for="">Phone</label><vue-tel-input v-model="formAcademy.phone" :class="{ 'red-border': v$.formAcademy.phone.$error }" mode="international"
            :preferredCountries="['us', 'gb', 'tr']" defaultCountry="us" name="phone" id="phone"
            validCharactersOnly></vue-tel-input>
          <div class="pre-icon os-icon os-icon-fingerprint"></div>
          <div class="input-errors" v-for="(error, index) of v$.formAcademy.phone.$errors" :key="index">
            <small class="text-danger">{{
              error.$message ==
                "Value is required"
                ? "Phone number is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-20">
          <label for="">Date of Birth</label>
          <Datepicker v-model="formAcademy.birthday" class="form-control" :class="{ 'is-invalid': v$.formAcademy.birthday.$error }" placeholder="Select a date"
            inputFormat="dd-MM-yyyy" />
          <div class="input-errors" v-for="(error, index) of v$.formAcademy.birthday.$errors" :key="index">
            <small class="text-danger">{{
              error.$message ==
                "Value is required"
                ? "Date of birth is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-20">
          <label for="">Gender</label>
          <select v-model="formAcademy.gender" name="country" class="form-control"  :class="{ 'is-invalid': v$.formAcademy.gender.$error }">
            <option :value="''" style="font-size: 20px">Select a gender</option>
            <option v-for="(item, index) in genders" :key="index" :value="item.text" style="font-size: 20px">
              {{ item.text }}
            </option>
          </select>
          <div class="input-errors" v-for="(error, index) of v$.formAcademy.gender.$errors" :key="index">
            <small class="text-danger">{{
              error.$message ==
                "Value is required"
                ? "Gender is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label for="">Which City Do You Live In</label>
          <select v-model="formAcademy.city" name="country" :class="{ 'is-invalid': v$.formAcademy.city.$error }" class="form-control">
            <option :value="''" style="font-size: 20px">Select a city</option>
            <option v-for="(item, index) in cities" :key="index" :value="item.text" style="font-size: 20px">
              {{ item.text }}
            </option>
          </select>
          <div class="input-errors" v-for="(error, index) of v$.formAcademy.city.$errors" :key="index">
            <small class="text-danger">{{
              error.$message ==
                "Value is required"
                ? "City is required"
                : error.$message
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group mb-20">
          <button class="btn demo-request-form-btn" type="submit" @click="onSubmit">
            <h5 class="m-0">NEXT</h5>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vue3-datepicker";
export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Datepicker,
  },
  name: "GeneralInfoForm",
  data() {
    return {
      genders: [
        { value: 0, text: "Male" },
        { value: 1, text: "Female" },
      ],
      cities: [
        { value: "1", text: "ADANA" },
        { value: "2", text: "ADIYAMAN" },
        { value: "3", text: "AFYONKARAHİSAR" },
        { value: "4", text: "AĞRI" },
        { value: "5", text: "AMASYA" },
        { value: "6", text: "ANKARA" },
        { value: "7", text: "ANTALYA" },
        { value: "8", text: "ARTVİN" },
        { value: "9", text: "AYDIN" },
        { value: "10", text: "BALIKESİR" },
        { value: "11", text: "BİLECİKK" },
        { value: "12", text: "BİNGÖL" },
        { value: "13", text: "BİTLİS" },
        { value: "14", text: "BOLU" },
        { value: "15", text: "BURDUR" },
        { value: "16", text: "BURSA" },
        { value: "17", text: "ÇANAKKALE" },
        { value: "18", text: "ÇANKIRI" },
        { value: "19", text: "ÇORUM" },
        { value: "20", text: "DENİZLİ" },
        { value: "21", text: "DİYARBAKIR" },
        { value: "22", text: "EDİRNE" },
        { value: "23", text: "ELAZIĞ" },
        { value: "24", text: "ERZİNCAN" },
        { value: "25", text: "ERZURUM" },
        { value: "26", text: "ESKİŞEHİR" },
        { value: "27", text: "GAZİANTEP" },
        { value: "28", text: "GİRESUN" },
        { value: "29", text: "GÜMÜŞHANE" },
        { value: "30", text: "HAKKARİ" },
        { value: "31", text: "HATAY" },
        { value: "32", text: "ISPARTA" },
        { value: "33", text: "MERSİN" },
        { value: "34", text: "İSTANBUL" },
        { value: "35", text: "İZMİR" },
        { value: "36", text: "KARS" },
        { value: "37", text: "KASTAMONU" },
        { value: "38", text: "KAYSERİ" },
        { value: "39", text: "KIRKLARELİ" },
        { value: "40", text: "KIRŞEHİR" },
        { value: "41", text: "KOCAELİ" },
        { value: "42", text: "KONYA" },
        { value: "43", text: "KÜTAHYA" },
        { value: "44", text: "MALATYA" },
        { value: "45", text: "MANİSA" },
        { value: "46", text: "KAHRAMANMARAŞ" },
        { value: "47", text: "MARDİN" },
        { value: "48", text: "MUĞLA" },
        { value: "49", text: "MUŞ" },
        { value: "50", text: "NEVŞEHİR" },
        { value: "51", text: "NİĞDE" },
        { value: "52", text: "ORDU" },
        { value: "53", text: "RİZE" },
        { value: "54", text: "SAKARYA" },
        { value: "55", text: "SAMSUN" },
        { value: "56", text: "SİİRT" },
        { value: "57", text: "SİNOP" },
        { value: "58", text: "SİVAS" },
        { value: "59", text: "TEKİRDAĞ" },
        { value: "60", text: "TOKAT" },
        { value: "61", text: "TRABZON" },
        { value: "62", text: "TUNCELİ" },
        { value: "63", text: "ŞANLIURFA" },
        { value: "64", text: "UŞAK" },
        { value: "65", text: "VAN" },
        { value: "66", text: "YOZGAT" },
        { value: "67", text: "ZONGULDAK" },
        { value: "68", text: "AKSARAY" },
        { value: "69", text: "BAYBURT" },
        { value: "70", text: "KARAMAN" },
        { value: "71", text: "KIRIKKALE" },
        { value: "72", text: "BATMAN" },
        { value: "73", text: "ŞIRNAK" },
        { value: "74", text: "BARTIN" },
        { value: "75", text: "ARDAHAN" },
        { value: "76", text: "IĞDIR" },
        { value: "77", text: "YALOVA" },
        { value: "78", text: "KARABüK" },
        { value: "79", text: "KİLİS" },
        { value: "80", text: "OSMANİYE" },
        { value: "81", text: "DÜZCE" },
      ],
    };
  },

  validations() {
    return {
      formAcademy: {
        name: { required },
        phone: { required },
        birthday: { required },
        gender: { required },
        city: { required },
      },
    };
  },

  methods: {
    ...mapActions("main", ["changeTabForm"]),
    onSubmit() {
      this.v$.$touch();
      if (!this.v$.formAcademy.$invalid) {
        this.changeTabForm(2);
      }
    },
  },
  computed: {
    ...mapState("main", ["formAcademy"]),
  },
});
</script>
