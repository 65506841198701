<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-lg">
        <h2>How to become an “authorized reseller”?</h2>
      </div>
      <div class="row authorized-reseller-height">
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-1.png" alt="shape" />
            <p>Fill in the form after touching "Apply" button below.</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-2.png" alt="shape" />
            <p>Send us appropriate documents and your company details.</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-3.png" alt="shape" />
            <p>
              After reviewing your application, your application will be ready
              for demonstration.
            </p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
          <div class="authorized-reseller-card">
            <img src="../../assets/images/partners/card-4.png" alt="shape" />
            <p>
              You will have access to our marketing materials and customer
              contract documents which will be signed during sales closing.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AuthorizedReseller",
});
</script>
