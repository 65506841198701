<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          How do we overcome this problem with PIXSVMS Warehouse & Factory CCTV
          Monitoring?
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            With your PIXSVMS Warehouse & Factory CCTV Monitoring software, our
            detailed algorithms, it is easy to detect your cctv alarms
            instantly. These alarms are;
          </p>
        </div>
      </div>
      <div class="row pb-60">
        <div class="col-12 col-md-6 col-lg-1 pb-30"></div>
        <div class="col-12 col-md-8 col-lg-5 pb-30">
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/1.png" alt="shape" />
            <h5 class="mt-20">Video Loss</h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/2.png" alt="shape" />
            <h5 class="mt-20">Video Tamper</h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/3.png" alt="shape" />
            <h5 class="mt-20">Video Record has stopped</h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/4.png" alt="shape" />
            <h5 class="mt-20">Video Parameters have changed</h5>
          </div>
          <div class="bank-cctv-card-overcome">
            <img src="../../assets/images/bank-cctv/5.png" alt="shape" />
            <h5 class="mt-20">Scene Change On Camera</h5>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 pb-30">
          <div class="bank-cctv-card-info">
            <p>
              Our software is fully integrated with Hikvision, Samsung Hanwha
              and Dahua Cameras; so that by the product manufacturers’ SDK, we
              have a detailed access of every cctv devices.
            </p>
          </div>
          <div class="bank-cctv-card-info">
            <p>
              Our on premise web browser solution allows you to have an account
              that every personnel apart form security division may use this
              platform according to their operation needs.
            </p>
          </div>
          <div class="bank-cctv-card-info">
            <p>
              There is a hierarchical access on the system that allows you get
              access as a client and every process is logged.
            </p>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-1 pb-30"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWarehousesCctvSecond",
};
</script>
