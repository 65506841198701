<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/warehouse-cctv/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXSVMS Warehouse & Factory CCTV Monitoring?</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              It has been created as a sophisticated solution in Warehouses &
              Factories for professionals; to detect and control all cctv
              devices and to log every operations done on these devices. Also
              for inventory management and monitoring of every operation,
              PIXSVMS Warehouse & Factory CCTV Monitoring enables many features
              such as live and record viewing, electronic zoom, snapshot and
              logs with snapshot options.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>
              Why do you need PIXSVMS Warehouse & Factory CCTV Monitoring?
            </h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This software product is needed in Warehouses and Factories. Every
              security operators have to control every cctv devices and when
              there is an incident happens, according to Trade Ministry; they
              have to report these incidents.
            </p>
            <br />
            <p>
              This operation is really complicated without a non-compliant
              software.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/warehouse-cctv/photo-card-2.png"
          alt="shape"
        />
      </div>

      <div class="row pt-30">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-1.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>All cctv alarms have to be monitored instantly</h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-3.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  Many users should have access to the system without affecting
                  the security system architecture and mechanism.
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-2.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  All cameras can be monitored hierarchically and not only been
                  used by security professionals but can also be used by
                  operation personnel such as warehouse, factory operations
                  officers with their individual account.
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img
                src="../../assets/images/goods-cameras-ramps/difference-4.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  You have to define and report the exact and detailed
                  diagnostic when there is;
                </h6>
                <p>
                  - Video Loss <br />
                  - Video Tamper<br />
                  - Video Record has stopped<br />
                  - Video Parameters have changed<br />
                  - Scene Change On Camera
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWarehousesCctv",
};
</script>
