<template>
    <div class="container pt-60 pb-60">
      <div class="section-title section-title-lg">
        <h2>Vision AI for Retail</h2>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="retail-visionAi-img">
            <img
              src="../../assets/images/retail/retail-vision.png"
              alt="shape"
            />
          </div>
        </div>

        <div class="col-lg-6 p-0">
          <div class="retail-visionAi">
            <div class="retail-visionAi-title">
              <h5>
                Every Retail owners need vision for security. And also they need
                data for performance management.
              </h5>
            </div>
          </div>

          <div class="retail-visionAi-list pt-20">
            <div class="retail-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Manage and control all your branches, CCTV devices.
              </p>
            </div>

            <div class="retail-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Control and get all your logs of visitors & personnel.
              </p>
            </div>
            <div class="retail-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Manage visitor data performance and compare your branch performance.
              </p>
            </div>
            <div class="retail-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Manage your store layout by our heatmap solution.
              </p>
            </div>
            <div class="retail-visionAi-title">
              <img src="../../assets/images/final-state.png" alt="shape" />
              <p>
                Control & verify your inventories by our solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "VisionRetail",
};
</script>
