<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
        <h2>
          PIXPass Access & Log Solutions
          <br />
          Turnstiles and Door Access by Face Features
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>Health Report Analysis</h5>
              <p>
                You can query how many turnstiles, face terminals are connected
                in the system, how many are active, facing any network and/or
                electrical problems. You can see your health report instant or a
                specific time range.
              </p>
            </div>
            <img
              src="../../assets/images/1.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>Instant Alarms & Logs</h5>
              <p>
                The log records on your devices will be stored for a
                retrospective year and the instant notification of the logs
                generated by the alarm to the user. Device logs include; who
                accessed the devices, blacklist attemps, unexpected access
                attemps, capacity over exceeded alarm etc.
              </p>
            </div>
            <img
              src="../../assets/images/2.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>Smart Alarm Notifications</h5>
              <p>
                Our platform refines all the information from the turnstiles &
                doors and turns these refined information into alarms. You can
                even get whatever alarms needed according to your company needs.
              </p>
            </div>
            <img
              src="../../assets/images/3.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>User Authorization & Delegation</h5>
              <p>
                Our platform also works integrated with your company Active
                Directory and also our authorization and delegation architecture
                gives us the opportunity to have a specialized user
                authorization system.
              </p>
            </div>
            <img
              src="../../assets/images/4.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>Integrations with your CRM Data</h5>
              <p>
                Your CRM Data is integrated with our Access & log platform. With
                smart loyalty cards integrated to our platform, you can easily
                get instant sms when someone in your expectance list comes into
                anyone of your building as a visitor and/or customer.
              </p>
            </div>
            <img
              src="../../assets/images/5.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="turnstiles-card-features-general">
            <div class="turnstiles-card-features">
              <h5>Snapshot binded with Access Log</h5>
              <p>
                From every camera when passing through a turnstile or when
                entering a meeting room, our integrated cctv can bundle a
                snapshot for evidence.
              </p>
            </div>
            <img
              src="../../assets/images/6.png"
              alt="shape"
              class="turnstiles-card-features-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTurnstilesFaceRecognitionThird",
};
</script>
