<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>PIXCount Goods by Barcode & QR Features</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Login with National ID Cards</h5>
              <p>
                The entry of each personnel into the system is made with new
                generation identity cards.
              </p>
            </div>
            <img src="../../assets/images/1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Registered Personnel Control</h5>
              <p>
                No transactions can be made outside of registered personnel.
              </p>
            </div>
            <img src="../../assets/images/2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>User Friendly Mobile Interface</h5>
              <p>There are options for a new count or to continue counting.</p>
            </div>
            <img src="../../assets/images/3.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Matching Processes with CCTV Footage</h5>
              <p>
                The counting place is matched with the CCTV camera, so the
                process of the counting process is matched with the CCTV video
                link.
              </p>
            </div>
            <img src="../../assets/images/4.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Evidence Photos On Every Counting</h5>
              <p>
                In case the counting is done one by one, evidence is created by
                photographing the outer appearance of the counted parcel or
                pallet.
              </p>
            </div>
            <img src="../../assets/images/5.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Automatic Plate Recognition</h5>
              <p>
                Optional Automatic Plate Recognition Camera can be added to your
                system.
              </p>
            </div>
            <img src="../../assets/images/6.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row pb-30">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Multiple Search Options on Web Dashboard</h5>
              <p>
                Web dashboard search options by worker id, by plate, by company
                delivering goods, by ramp id, by time.
              </p>
            </div>
            <img src="../../assets/images/7.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Data For Whole Operation with CCTV Videos</h5>
              <p>
                Every acceptance operation is seen on web dashboard with all
                data matched with snapshots of each good and with entire process
                cctv footage.
              </p>
            </div>
            <img src="../../assets/images/8.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card goods-barcode-features">
              <h5>Worker Performance Logs</h5>
              <p>
                You can check every worker performance duration time on ramp
                areas.
              </p>
            </div>
            <img src="../../assets/images/9.png" alt="shape" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsBarcodeWarehousesThird",
};
</script>
