<template>
  <div class="container pt-60 pb-60">
    <div class="section-title section-title-lg">
      <h2>PIXSelect All Service Offerings</h2>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>PIXCount People</h3>
          </div>
          <div class="academy-card-brief">
            <p>Visitor counting</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>PIXPass by ID</h3>
          </div>
          <div class="academy-card-brief">
            <p>Access logs of visitors & staff</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>PIXSVMS</h3>
          </div>
          <div class="academy-card-brief">
            <p>
              - ATM's Bank Branch CCTV Monitoring <br />
              - Retail Branch CCTV Monitoring <br />
              - Warehouse & Factory CCTV Monitoring
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>PIXCount Goods by QR/Barcode</h3>
          </div>
          <div class="academy-card-brief">
            <p>Object Counting</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>PIXCount Goods by AI Cameras</h3>
          </div>
          <div class="academy-card-brief">
            <p>Object counting with AI cameras</p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4 pb-30">
        <div class="academy-card">
          <div class="academy-card-title">
            <h3>PIXCount Shelves by AI Cameras</h3>
          </div>
          <div class="academy-card-brief">
            <p>Product counting on warehouse / market shelves</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogCountPeople",
};
</script>
