<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          How do we overcome this problem with PIXCount Shelves by AI Cameras?
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            With your PIXCount Shelves by AI Cameras, your software with cutting
            edge technology AI algorithms, it is easy to detect your goods in
            stores shelves. Newly installed AI cameras on shelves allows you to
            monitor your goods on shelves. This AI process helps you
            automatically detect every product, shelf planogram at the same
            time. Also you can manage your shelves’ availability and warehouse
            and order process optimization.
          </p>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>Process of Shelf Layout Detection In Stores</h2>
      </div>

      <div class="form-check form-switch form-switch-lg justify-content-center">
        <p :class="!this.toggle ? 'active' : 'passive'">SHELF LAYOUT</p>
        <input class="form-check-input" type="checkbox" v-model="toggle" />
        <p :class="this.toggle ? 'active' : 'passive'">SHELF COUNT</p>
      </div>
      <div class="row pb-60">
        <div
          class="col-12 col-md-12 col-lg-12 d-flex justify-content-start"
          v-if="!this.toggle"
        >
          <img
            src="../../assets/images/shelves-cameras-stores/schema-1.png"
            alt="shape"
          />
        </div>
        <div
          class="col-12 col-md-12 col-lg-12 d-flex justify-content-start"
          v-if="this.toggle"
        >
          <img
            src="../../assets/images/shelves-cameras-stores/schema-2.png"
            alt="shape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardShelvesCamerasStoresSecond",
  data() {
    return {
      toggle: false,
    };
  },
};
</script>

<style scoped>
.active {
  color: rgb(237, 91, 177);
}
.passive {
  color: rgb(1, 1, 1);
}
</style>
