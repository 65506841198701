<template>
  <div class="inner-page-header">
    <div class="container">
      <div class="row">
        <div class="col-sm-7 d-flex align-items-center">
          <div class="inner-header-content-academy">
            <h1>Demo Request</h1>
            <br />
            <h2>Please tell us about your business</h2>
            <br />
          </div>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <img
            class="goods-ai-warehouses-page-header-img"
            src="../../assets/images/demo-request-header.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitleForDemoRequest",
  props: ["pageTitle"],
};
</script>
