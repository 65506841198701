<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXCount Goods by AI Cameras?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              It has been created as a practical solution for counting and
              control processes during the transport of goods from one place to
              another, especially in warehouses and production lines such as
              factories.
            </p>
            <br />
            <p>
              In our system, which is compatible with the ERP system, the
              details of the goods counting processes are presented in detail on
              our web panel.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>Why do you need PIXCount Goods by AI Cameras?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This product is needed in warehouses, retail warehouses and
              factories. Inventory management and counting is a work process in
              these areas.
            </p>
            <br />
            <p>
              However counting inventories manually is becoming a mess if you
              have no answer to this following questions;
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-2.png"
          alt="shape"
          style="padding: 0px 0px 0px 18px"
        />
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/goods-cameras-warehouses/photo-card-3.png"
          alt="shape"
          style="padding: 0px 18px 0px 0px"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-text">
            <p style="line-height: 50px">
              Another problem is when accepting goods manually, a warehouse
              worker has to stand and monitor all pallettes, packages.
            </p>
            <br />
            <p style="line-height: 50px">
              This is sometimes a problematic process; i.e your worker has many
              tasks at the same time. So entire counting goods processes may not
              be completed with your warehouse workers.
            </p>
            <br />
            <p style="line-height: 50px">
              Also sometimes because of trust, no one may stand at work during
              these processes.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60 pl-40">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>Who did this process?</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>When did this entire process happen?</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-60 pl-40">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>How long was it?</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-6">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-4.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Do we have evidences of this <br />
                process (snapshot & video etc)?
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsCamerasWarehouses",
};
</script>
