<template>
  <div @submit.prevent="onSubmit">
    <div class="row d-flex justify-content-center mt-30">
      <div class="col-md-12">
        <div class="form-group mb-20">
          <label>Your current job situation?</label><br />
          <div
            v-for="(item, index) in jobStatus"
            :key="index"
            class="d-flex align-items-center mt-10"
          >
            <input
              type="radio"
              id="one"
              :value="item.text"
              v-model="formAcademy.jobStatus"
              style="width: 1.2em; height: 1.2em"
            />
            <label class="ml-10 mb-0">{{ item.text }}</label>
            <br />
          </div>
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.jobStatus.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message ==
              "Value is required"
                ? "Current job situation is required"
                : error.$message 
            }}</small>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12">
        <div class="form-group mb-20">
          <label>In which city are you open to work?</label>
          <select
            name="job_city"
            class="form-control"
            v-model="formAcademy.jobCity"
            :class="{ 'is-invalid': v$.formAcademy.jobCity.$error }"
          >
            <option :value="''" style="font-size: 20px">Select a city</option>
            <option
              v-for="(item, index) in jobCities"
              :key="index"
              :value="item.text"
              style="font-size: 20px"
            >
              {{ item.text }}
            </option>
          </select>
          <div
            class="input-errors"
            v-for="(error, index) of v$.formAcademy.jobCity.$errors"
            :key="index"
          >
            <small class="text-danger">{{
              error.$message ==
              "Value is required"
                ? "City is required"
                : error.$message 
            }}</small>
          </div>
        </div>
      </div> -->
      <div class="col-md-12">
        <div class="form-group mb-20">
          <button
            class="btn demo-request-form-btn"
            type="submit"
            @click="onSubmit"
          >
            <h5 class="m-0">NEXT</h5>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  name: "WorkingStatusForm",
  data() {
    return {
      jobCities: [
        { text: "İstanbul", value: 1 },
        { text: "Ankara", value: 2 },
        { text: "İzmir", value: 3 },
        { text: "Any City", value: 4 },
        { text: "Other", value: 5 },
      ],
      jobStatus: [
        { text: "I am a graduate and not working", value: 1 },
        { text: "I'm a university student and I don't work", value: 2 },
        { text: "I work full time (in the field of technology)", value: 3 },
        { text: "I work full time (outside the tech field)", value: 4 },
        { text: "I am doing an internship / part time / freelance (in the field of technology)", value: 5 },
        { text: "I am doing an internship / part time / freelance (outside the tech field)", value: 6 },
      ],
    };
  },

  validations() {
    return {
      formAcademy: {
        jobStatus: { required },
        //jobCity: { required },
      },
    };
  },

  methods: {
    ...mapActions("main", ["changeTabForm"]),
    onSubmit() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.changeTabForm(4);
      }
    },
  },
  computed: {
    ...mapState("main", ["formAcademy"]),
  },
});
</script>
