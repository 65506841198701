<template>
  <div class="home-page-solution-background">
    <div class="container pt-60">
      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-7">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="home-page-solution-card mb-30">
                <div v-if="arr[0] == 'card1'">
                  <div id="card-1" @click="changeCard(1)">
                    <img src="../../assets/images/homepage/solution-card-2.png" alt="shape" />
                    <h3>Finance</h3>
                    <p>
                      Our aim is to help finance & audit professionals to gain
                      control on all operations on ATM’s, branch ATM’s
                    </p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[0] == 'card2'">
                  <div id="card-2" @click="changeCard(2)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>Logistics</h3>
                    <p>
                      Our aim is to help logistic professionals to gain control on
                      all logistics operations
                    </p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[0] == 'card3'">
                  <div id="card-3" @click="changeCard(3)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>Retail</h3>
                    <p>We help Retail professionals to increase their income.</p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="home-page-solution-card pb-30">
                <div v-if="arr[1] == 'card1'">
                  <div id="card-1" @click="changeCard(1)">
                    <img src="../../assets/images/homepage/solution-card-2.png" alt="shape" />
                    <h3>Finance</h3>
                    <p>
                      Our aim is to help finance & audit professionals to gain
                      control on all operations on ATM’s, branch ATM’s
                    </p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[1] == 'card2'">
                  <div id="card-2" @click="changeCard(2)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>Logistics</h3>
                    <p>
                      Our aim is to help logistic professionals to gain control on
                      all logistics operations
                    </p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[1] == 'card3'">
                  <div id="card-3" @click="changeCard(3)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>Retail</h3>
                    <p>We help Retail professionals to increase their income.</p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center mt-30 mb-30">
              <div class="home-page-solution-card active-solution">
                <div v-if="arr[2] == 'card1'">
                  <div id="card-1" @click="changeCard(1)">
                    <img src="../../assets/images/homepage/solution-card-2.png" alt="shape" />
                    <h3>Finance</h3>
                    <p>
                      Our aim is to help finance & audit professionals to gain
                      control on all operations on ATM’s, branch ATM’s
                    </p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[2] == 'card2'">
                  <div id="card-2" @click="changeCard(2)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>Logistics</h3>
                    <p>
                      Our aim is to help logistic professionals to gain control on
                      all logistics operations
                    </p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
                <div v-else-if="arr[2] == 'card3'">
                  <div id="card-3" @click="changeCard(3)">
                    <img src="../../assets/images/homepage/solution-card-1.png" alt="shape" />
                    <h3>Retail</h3>
                    <p>We help Retail professionals to increase their income.</p>
                    <div class="home-page-solution-arrow">
                      <h5>Read more</h5>
                      <img src="../../assets/images/homepage/arrow-right.png" alt="shape" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-5 d-flex align-items-center">
          <div class="home-page-solution-detail" v-if="this.card1">
            <h5>VISION AI FOR FINANCE</h5>
            <p>
              Securing ATM’s and bank branches needs a focus and detailed
              monitoring. Our CCTV monitoring platform satisfies these needs
              parallel with regulations.
            </p>
            <div>
              <router-link to="/finance" class="btn main-btn-academy" style="
                  background-image: linear-gradient(to right, #6852ed, #ba43fc);
                ">
                More Info
              </router-link>
            </div>
          </div>
          <div class="home-page-solution-detail" v-else-if="this.card2">
            <h5>VISION AI FOR LOGISTICS</h5>
            <p>
              Logistic operations need good plans, and these plans have to be
              instantly monitored and logistics professionals have to make quick
              response to instances.
            </p>
            <div>
              <router-link to="/logistic" class="btn main-btn-academy" style="
                  background-image: linear-gradient(to right, #6852ed, #ba43fc);
                ">
                More Info
              </router-link>
            </div>
          </div>
          <div class="home-page-solution-detail" v-else-if="this.card3">
            <h5>VISION AI FOR RETAIL</h5>
            <p>
              Every Retail owners need vision for security. And also they need
              data for performance management.
            </p>
            <div>
              <router-link to="/retail" class="btn main-btn-academy" style="
                  background-image: linear-gradient(to right, #6852ed, #ba43fc);
                ">
                More Info
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "SolutionsByIndustries",
  data() {
    return {
      card1: false,
      card2: false,
      card3: true,
      arr: ["card1", "card2", "card3"]
    };
  },
  methods: {
    changeCard(id) {
      if (id === 1) {
        this.card1 = true;
        this.card2 = false;
        this.card3 = false;
        this.arr = ["card2", "card3", "card1"]
      } else if (id === 2) {
        this.card1 = false;
        this.card2 = true;
        this.card3 = false;
        this.arr = ["card3", "card1", "card2"]
      } else {
        this.card1 = false;
        this.card2 = false;
        this.card3 = true;
        this.arr = ["card1", "card2", "card3"]
      }
    },
  },
});
</script>
