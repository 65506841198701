<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>PIXCount Shelves by AI Cameras In Stores Features</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="goods-ai-warehouses-card">
            <div class="info-card shelves-features">
              <h5>Automatic Shelf Layout Detection</h5>
              <p>Automatic shelf layout (planogram) detection capability.</p>
            </div>
            <img src="../../assets/images/1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="goods-ai-warehouses-card">
            <div class="info-card shelves-features">
              <h5>Automatic Shelf Count</h5>
              <p>Automatic shelf count within minutes interval.</p>
            </div>
            <img src="../../assets/images/2.png" alt="shape" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="goods-ai-warehouses-card">
            <div class="info-card shelves-features">
              <h5>Cost Saving</h5>
              <p>
                Reduces the number of personnel responsible for checking the
                store shelves.
              </p>
            </div>
            <img src="../../assets/images/3.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="goods-ai-warehouses-card">
            <div class="info-card shelves-features">
              <h5>Stock & Order Tracking</h5>
              <p>Stock & Order tracking with integration capability.</p>
            </div>
            <img src="../../assets/images/4.png" alt="shape" />
          </div>
        </div>
      </div>
      <div class="row pb-30">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="goods-ai-warehouses-card">
            <div class="info-card shelves-features">
              <h5>Instant Warning System</h5>
              <p>
                An Instant Warning such as e-mail and sms will be sent to
                warehouse operators.
              </p>
            </div>
            <img src="../../assets/images/5.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="goods-ai-warehouses-card">
            <div class="info-card shelves-features">
              <h5>Daily Reports</h5>
              <p>
                A daily report system by email is sent to warehouse operators.
              </p>
            </div>
            <img src="../../assets/images/6.png" alt="shape" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardShelvesCamerasStoresThird",
};
</script>
