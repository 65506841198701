<template>
  <div>
    <Navbar class="bg-white" />
    <page-title-for-count-people pageTitle="PIXCount People" />
    <CardCountPeople />
    <CardCountPeopleSecond />
    <CareerCountPeople />
    <CardCountPeopleThird />
    <ClientsCountPeople />
    <BlogCountPeople />

    <!-- <card-count-people />
      <faq-count-people /> <br />
      <career-count-people /><br />
      <card-count-people-2 />
      <clients-people />
      <card-count-people-3 /><br>
      <blog-count-people-2 /> -->
    <!-- <clients-people2 /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar";
import PageTitleForCountPeople from "../../../PIXCountPeople/PageTitleForCountPeople.vue";
import CardCountPeople from "../../../PIXCountPeople/CardCountPeople.vue";
import CardCountPeopleSecond from "../../../PIXCountPeople/CardCountPeopleSecond.vue";
import CareerCountPeople from "../../../PIXCountPeople/CareerCountPeople.vue";
import CardCountPeopleThird from "../../../PIXCountPeople/CardCountPeopleThird.vue";
import ClientsCountPeople from "../../../PIXCountPeople/ClientsCountPeople.vue";
import BlogCountPeople from "../../../PIXCountPeople/BlogCountPeople.vue";

//   import FaqCountPeople from "../../../Faq/FaqCountPeople.vue";
//   import CardCountPeople from "../../../Cart/cardCountPeople.vue";
//   import CardCountPeople2 from "../../../Cart/cardCountPeople2.vue";
//   import CareerCountPeople from "../../../Career/careerCountPeople.vue";
//   import ClientsPeople from "../../../OurClients/ClientsCountPeople.vue";
//   import CardCountPeople3 from "../../../Cart/cardCountPeople3.vue";
//   import BlogCountPeople2 from "../../../Blog/BlogCountPeople2.vue"
// import ClientsPeople2 from "../OurClients/ClientsCountPeople2.vue";
import Footer from "../../../Layout/Footer";

export default {
  name: "PIXCountPeople",
  components: {
    Navbar,
    PageTitleForCountPeople,
    CardCountPeople,
    CardCountPeopleSecond,
    CareerCountPeople,
    CardCountPeopleThird,
    ClientsCountPeople,
    BlogCountPeople,

    //   CardCountPeople,
    //   CardCountPeople2,
    //   FaqCountPeople,
    //   CareerCountPeople,
    //   ClientsPeople,
    //   CardCountPeople3,
    //   BlogCountPeople2,
    // ClientsPeople2,
    Footer,
  },
};
</script>
