<template>
  <div class="container pt-60">
      <div class="section-title section-title-xl pb-30">
          <h2>What Does the AI Follow-up Program Provide?</h2>
      </div>

      <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>Customized Training Plans </h5>
                          <p>
                            You can maximize your team members' development by creating customized AI projects and training plans in the cloud, tailored to their needs and levels.
                          </p>
                          <br>
                      </div>
                  </div>

                  <img src="../../assets/images/1.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>Effective Monitoring and Evaluation </h5>
                          <p>
                            By tracking training progress and achievements instantly, you can monitor the development of your team members and provide them with feedback.
                          </p>
                          <br>
                      </div>
                  </div>
                  <img src="../../assets/images/2.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>Data Driven Decisions </h5>
                          <p>
                            By analyzing the data obtained through the portal, you can continuously optimize your training programs and make corporate decisions based on data.
                          </p>
                          <br>
                      </div>
                  </div>
                  <img src="../../assets/images/3.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>Flexible and Adaptive Learning </h5>
                          <p>
                            Your team members have the opportunity to learn whenever and wherever they want through an online and user-friendly platform, while at the same time being trained in line with their business processes.
                          </p>

                      </div>
                  </div>
                  <img src="../../assets/images/4.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>

            <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>Practical Application and Project Management</h5>
                          <p>
                            The portal allows your team members to reinforce their theoretical knowledge with real-world projects and interactive applications. They graduate with micro projects.
                          </p>
                          <br>
                      </div>
                  </div>
                  <img src="../../assets/images/5.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>Training Modules  </h5>
                          <p>
                            We offer a wide range of training modules starting from basic AI concepts to advanced applications.
                          </p>

                      </div>
                  </div>
                  <img src="../../assets/images/6.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
              <div class="partners-card-features-general">
                  <div class="partners-card-features d-flex align-items-center">
                      <div>
                          <h5>Certification and Motivation </h5>
                          <p>
                            Certificates based on their achievements will increase the motivation of your team members and allow them to document their expertise.
                          </p>

                      </div>
                  </div>
                  <img src="../../assets/images/7.png" alt="shape" class="partners-card-features-img" />
              </div>
          </div>
      </div>


  </div>
</template>

<script>
export default {
  name: "AiDeveloperAwaitYou",
};
</script>
