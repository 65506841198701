<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleSaasCloudTagMembership />

    <AiDeveloperCardSaasCloudTagMembership />
    <SaasCloudTagMembershipCard /><br /><br />
    <SaasCloudTagMembershipCard2 />
    <AiDeveloperWhyChoose />
    <ProcessAcademyForSaasCloud />
    <AiDeveloperWhyChoose2 />
    <br />
    <AiDeveloperWhyChoose4 />
    <AiDeveloperWhyChoose5 />
    <faq />
    <PageTitleSaasCVMembership />
    <SaasCvMembershipCard />
    <PageTitleSaasCVMembership3 />
    <!-- <SaasEmployersCard2 /> -->
    <!-- 
      <br>
      <br>
       <SaasCloudOurSolution/> 
      <AiDeveloperContributions2/>
      <PaymentFullTag/>  -->

    <Footer />
  </div>
</template>
  
  <script>
import Navbar from "../Layout/Navbar";

import PageTitleSaasCloudTagMembership from "../SaasCvMembership/PageTitleSaasCloudTagFullMembership.vue";
import AiDeveloperCardSaasCloudTagMembership from "../AiDeveloper/AiDeveloperCardSaasCloudTagFullMembership.vue";
import SaasCloudTagMembershipCard from "../SaasEmployers/SaasCloudTagFullMembershipCard.vue";
import SaasCloudTagMembershipCard2 from "../SaasEmployers/SaasCloudTagMembershipCard.vue";
import AiDeveloperWhyChoose from "../AiDeveloper/AiDeveloperWhyChoose.vue";
import ProcessAcademyForSaasCloud from "../HomeTwo/ProcessAcademyForSaasCloud.vue";

import AiDeveloperWhyChoose2 from "../AiDeveloper/AiDeveloperWhyChoose2.vue";
import AiDeveloperWhyChoose4 from "../AiDeveloper/AiDeveloperWhyChoose4.vue";
import AiDeveloperWhyChoose5 from "../AiDeveloper/AiDeveloperWhyChoose5.vue";
import Faq from "../Faq/Faq.vue";
import PageTitleSaasCVMembership from "../SaasCvMembership/PageTitleSaasCVMembership2.vue";
import PageTitleSaasCVMembership3 from "../SaasCvMembership/PageTitleSaasCVMembership3.vue";
import SaasCvMembershipCard from "../SaasCvMembership/SaasCvMembershipCard.vue";
// import SaasEmployersCard2 from "../SaasEmployers/SaasEmployersCard2.vue"
//   import AiDeveloperContributions2 from "../../AiDeveloper/AiDeveloperContributions2.vue";
// //   import SaasCloudOurSolution from "../../Logistic/SaasCloudOurSolution.vue";
//   import PaymentFullTag from "../PixAcademyPages/PaymentFullTag.vue";

import Footer from "../Layout/Footer";

export default {
  name: "SaasCvMembership",
  components: {
    Navbar,
    PageTitleSaasCloudTagMembership,
    AiDeveloperCardSaasCloudTagMembership,
    SaasCloudTagMembershipCard,
    SaasCloudTagMembershipCard2,
    AiDeveloperWhyChoose,
    AiDeveloperWhyChoose2,
    AiDeveloperWhyChoose4,
    AiDeveloperWhyChoose5,
    Faq,
    PageTitleSaasCVMembership,
    PageTitleSaasCVMembership3,
    ProcessAcademyForSaasCloud,
    //   AiDeveloperContributions2,
    //   PaymentFullTag,
    //   SaasCloudOurSolution,
    SaasCvMembershipCard,
    // SaasEmployersCard2,
    Footer,
  },
};
</script>
  