<template>
    <div class="container">
      <div class="section-image">
        <img
          style="max-width: 60%"
          src="../../assets/images/pixselect-academy-logo.svg"
          alt="shape"
        />
      </div>
      <div class="row pb-60">
        <div class="col-md-12 col-lg-12">
          <div class="it-support-card">
            <h3>
                Do you want to transform your data tagging processes and evaluate your data more efficiently, moving from raw data to meaningful data by storing all stages of the process securely and with peace of mind? SaaS Cloud Tag Membership is an advanced platform equipped with the latest artificial intelligence technology. It offers all the tools needed for data analysis, learning and more.
            </h3>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AiDeveloperCard",
  };
  </script>
  