<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleSaasCvMembership />
    <SaasCvMembershipCard />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar";
import PageTitleSaasCvMembership from "../../SaasCvMembership/PageTitleSaasCvMembership.vue";
import SaasCvMembershipCard from "../../SaasCvMembership/SaasCvMembershipCard.vue";
import Footer from "../../Layout/Footer";

export default {
  name: "SaasCvMembership",
  components: {
    Navbar,
    PageTitleSaasCvMembership,
    SaasCvMembershipCard,
    Footer,
  },
};
</script>
