<template>
  <div>
    <Navbar class="bg-white" />
    <PageTitleWarehousesCctv />
    <CardWarehousesCctv />
    <CardWarehousesCctvSecond />
    <CareerWarehousesCctv />
    <CardWarehousesCctvThird />
    <ClientsWarehousesCctv />
    <BlogWarehousesCctv />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../Layout/Navbar.vue";

import PageTitleWarehousesCctv from "../../../WarehousesCctv/PageTitleWarehousesCctv.vue";
import CardWarehousesCctv from "../../../WarehousesCctv/CardWarehousesCctv.vue";
import CardWarehousesCctvSecond from "../../../WarehousesCctv/CardWarehousesCctvSecond.vue";
import CareerWarehousesCctv from "../../../WarehousesCctv/CareerWarehousesCctv.vue";
import CardWarehousesCctvThird from "../../../WarehousesCctv/CardWarehousesCctvThird.vue";
import ClientsWarehousesCctv from "../../../WarehousesCctv/ClientsWarehousesCctv.vue";
import BlogWarehousesCctv from "../../../WarehousesCctv/BlogWarehousesCctv.vue";

import Footer from "../../../Layout/Footer";

export default {
  name: "WarehousesCctv",
  components: {
    Navbar,
    PageTitleWarehousesCctv,
    CardWarehousesCctv,
    CardWarehousesCctvSecond,
    CareerWarehousesCctv,
    CardWarehousesCctvThird,
    ClientsWarehousesCctv,
    BlogWarehousesCctv,
    Footer,
  },
};
</script>
