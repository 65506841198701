<template>
  <div class="container pb-30">
    <div class="row">
      <div class="section-image">
        <img
          style="max-width: 60%"
          src="../../assets/images/pixselect-academy-logo.svg"
          alt="shape"
        />
      </div>
    </div>
  </div>
  <div class="retail-section-card-our-solution">
    <div class="container pb-30">
      <div class="row">
        <div class="col-12">
          <div class="saas-info-card">
            <h5>
              Your business is growing fast, you need new talent to keep up with
              your pace.
            </h5>
            <h5>
              PIXSelect Academy brings together the most compatible technology
              talents with your needs with its active and <br />
              talented software community.
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaasEmployersCard",
};
</script>
