<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 count-people-card-info-img">
        <img
          src="../../assets/images/turnstiles-face-recognition/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXPass by Face?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              It has been created as a sophisticated software service (combined
              with hardware) solution to get logs when entering – exiting areas.
              By Face Recognition, passing turnstiles or entering a meeting room
              is logged by our hardware face recognition terminals.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>

    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>Why do you need PIXPass by Face?</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This software product is needed in every turnstiles and doors.
              Every security operators have to control his/her building
              entrances and places such as meeting rooms, rental spaces etc.
              Often proximity cards are used on turnstiles.
            </p>
            <p>Using proximity cards often comes with problems such as;</p>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-12 col-lg-6 count-people-card-info-img d-flex justify-content-end"
      >
        <img
          src="../../assets/images/turnstiles-face-recognition/photo-card-2.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Easy operation, no need to send/receive proximity cards to
                employees.
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Timely access permission visitors by name within time period.
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Digital and secure data collection, high security entrances with
                face verification.(Only applicable to employees)
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item pt-10">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/difference-4.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Cost effective using Face verification modules, no proximity
                cards or cargo fees.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTurnstilesFaceRecognition",
};
</script>
