<template>
  <div class="service-section pt-min-90 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg"> <br>
        <h2>Frontend - How to Train a Backend Expert?</h2>
      </div>
      <div
        class="about-section-item about-item-image text-center desk-pad-right-70"
      >
        <img src="../../assets/images/academy2.png" alt="shape" />
      </div><br>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
};
</script>
