<template>
  <div class="container pt-10">
    <div class="section-title section-title-xl pb-30">
      <h2>Use Cases</h2>
    </div>

    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleBankingCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'banking-card': isBankingCardOpen }"
          >
            <div>
              <h5>Banking</h5>
              <p>
                Optimize your banking operations and unlock the potential to
                increase efficiency.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleRetailCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'retail-card': isRetailCardOpen }"
          >
            <div>
              <h5>Retail</h5>
              <p>
                Optimize your retail operations and meet the potential to
                increase efficiency.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleLogisticsCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'logistics-card': isLogisticsCardOpen }"
          >
            <div>
              <h5>Logistics</h5>
              <p>
                Optimize your logistics operations and unlock the potential to
                increase efficiency.
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <b-collapse class="mt-2" v-model="isBankingCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #56c5b2">Fraud and Security Monitoring</h5>
                  <p>
                    Banks can use AIVISIONTRAINER to prevent fraud in places
                    like ATMs and bank branches by integrating it with security
                    cameras. The platform can identify suspicious activity and
                    send automated alerts.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #56c5b2">Document and Authentication</h5>
                  <p>
                    AIVISIONTRAINER can be used to automatically verify
                    customers' identity documents and other necessary documents.
                    This speeds up customer approval processes and increases
                    security.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/2.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #56c5b2">
                    ATM and Branch Traffic Management
                  </h5>
                  <p>
                    AIVISIONTRAINER can monitor traffic at ATMs and branches
                    to identify peak hours and days. This provides guidance to
                    banks for staff management and resource allocation
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/3.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isRetailCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    Product Stock and Shelf Management
                  </h5>
                  <p>
                    Retail companies can use AIVISIONTRAINER to automatically
                    recognize and count products on shelves. This helps them
                    optimize inventory management, reduce stock shortages, and
                    respond faster to customer demands.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    Personalized Product Recommendations
                  </h5>
                  <p>
                    AIVISIONTRAINER can offer personalized product
                    recommendations by tracking customers' movements in-store.
                    This enhances the shopping experience and can increase
                    sales.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/2.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">Theft and Fraud Prevention</h5>
                  <p>
                    Stores can prevent theft and fraud by integrating AI Vision
                    Trainer with security cameras. The platform can detect
                    suspicious behavior and send alerts instantly.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/3.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    Product and Shelf Arrangement Optimization
                  </h5>
                  <p>
                    Retail companies can use AIVISIONTRAINER to optimize
                    product and shelf arrangements. In-store images can be
                    analyzed to understand which products attract more attention
                    and which arrangements encourage more sales.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/4.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #ba4cf9">
                    Cash Automation and Queue Management
                  </h5>
                  <p>
                    AIVISIONTRAINER can optimize checkout automation and queue
                    management by monitoring customer checkout lines. This
                    reduces customer waiting times and improves the shopping
                    experience.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/5.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isLogisticsCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    Automatic Product Recognition and Tracking
                  </h5>
                  <p>
                    Logistics companies can automatically recognize and track
                    products using AIVISIONTRAINER. This helps them optimize
                    their warehouse management, inventory tracking, and
                    logistics operations. They can instantly track where
                    products are and manage shipments more efficiently.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    Safety Inspection for Transportation Vehicles
                  </h5>
                  <p>
                    AIVISIONTRAINER can be used to monitor the safety and
                    maintenance of transportation vehicles through cameras.
                    Factors such as the operating status of vehicles, driver
                    behavior and load safety can be analyzed, which can help
                    prevent accidents and reduce transportation costs.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/2.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    Traffic and Transportation Management
                  </h5>
                  <p>
                    Logistics companies can use AIVISIONTRAINER for traffic
                    and transportation management. By monitoring traffic flow
                    through traffic cameras and sensors, they can optimize
                    routes and improve delivery times.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/3.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">Customs and Border Control</h5>
                  <p>
                    AIVISIONTRAINER can be used in customs and border controls
                    to facilitate the inspection of shipments and vehicles.
                    Technologies such as automatic license plate recognition and
                    cargo scanning can speed up customs processes and improve
                    security.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/4.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1f12fd">
                    Warehouse Management and Organization
                  </h5>
                  <p>
                    Logistics companies can use AIVISIONTRAINER to optimize
                    in-warehouse operations. They can monitor the placement and
                    inventory of products in the warehouse and organize products
                    according to demand. This reduces inventory losses and
                    enables faster order filling.
                  </p>
                  <br />
                </div>
              </div>
              <img
                src="../../assets/images/5.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleCommerceCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center"
            :class="{ 'commerce-card': isCommerceCardOpen }"
          >
            <div>
              <h5>E-Commerce</h5>
              <p>Revolutionizing E-commerce: AI-Powered Product Catalog Management</p>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleProductionCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'production-card': isProductionCardOpen }"
          >
            <div>
              <h5>Production</h5>
              <p>Streamlining Product Quality Control with AI Vision Trainer</p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleManagementCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'management-card': isManagementCardOpen }"
          >
            <div>
              <h5>Management</h5>
              <p>Enhancing City Management and Safety with AI-Powered Camera Surveillance</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-collapse class="mt-2" v-model="isCommerceCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #e8c91e">E-Commerce</h5>
                  <p>
                    E-commerce companies have to manage their catalogs
                    containing thousands of products. AIVISIONTRAINER enables
                    automatic labeling and classification of products. This can
                    increase sales by providing customers with better search
                    results and product recommendations.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isProductionCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #308cd3">
                    Product Quality Control and Early Detection of Defects
                  </h5>
                  <p>
                    AIVISIONTRAINER is ideal for manufacturing companies
                    looking to automate their product quality control processes.
                    Thanks to its image processing capabilities, you can
                    instantly assess the quality of products and detect defects
                    early. This helps businesses reduce the production of
                    defective products and increase customer satisfaction.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isManagementCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #1509d8">
                    City Monitoring with Traffic and Security Cameras
                  </h5>
                  <p>
                    Municipalities and city governments may want to monitor
                    their cities and improve safety through traffic and security
                    cameras. By analyzing data from these cameras, AI Vision
                    Trainer can be used to monitor traffic flow, detect safety
                    violations and improve city planning.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
    <br />
    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleAgricultureCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'agriculture-card': isAgricultureCardOpen }"
          >
            <div>
              <h5>Agriculture</h5>
              <p>
                Optimizing Agriculture: AI-Powered Harvest Time Prediction
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-12 col-md-6 col-lg-4"
        style="cursor: pointer"
        @click="toggleMedicalCard"
      >
        <div class="partners-card-features-general">
          <div
            class="partners-card-features d-flex align-items-center justify-content-center"
            :class="{ 'medical-card': isMedicalCardOpen }"
          >
            <div>
              <h5>Medical</h5>
              <p>Advancing Healthcare with AI: Medical Image Analysis and Diagnosis Enhancement</p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-collapse class="mt-2" v-model="isAgricultureCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #99ca48">Agriculture</h5>
                  <p>
                    In the agriculture sector, predicting the correct harvest
                    time can improve crop productivity and quality. AI Vision
                    Trainer can perform image analysis to monitor the growth
                    status of plants and determine the harvest time. This can
                    help agribusinesses to increase productivity.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

    <b-collapse class="mt-2" v-model="isMedicalCardOpen">
      <div class="container pt-10">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4">
            <div class="partners-card-features-general">
              <div class="partners-card-features d-flex align-items-center">
                <div>
                  <h5 style="color: #dc3545">
                    Medical Image Analysis and Diagnostics Support
                  </h5>
                  <p>
                    In the healthcare industry, AIVISIONTRAINER can be used to
                    analyze medical images. By working on images such as X-rays,
                    MRI and CT scans, it can speed up the diagnostic process for
                    doctors and help them achieve more accurate results.
                  </p>
                  <br />
                </div>
              </div>

              <img
                src="../../assets/images/1.png"
                alt="shape"
                class="partners-card-features-img"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

  </div>
</template>
  
<script>
export default {
  name: "AiDeveloperAwaitYou",
  data() {
    return {
      isBankingCardOpen: false,
      isRetailCardOpen: false,
      isLogisticsCardOpen: false,
      isCommerceCardOpen: false,
      isProductionCardOpen: false,
      isManagementCardOpen: false,
      isAgricultureCardOpen: false,
      isMedicalCardOpen: false,
    };
  },
  methods: {
    toggleBankingCard() {
      this.isBankingCardOpen = !this.isBankingCardOpen;
      if (this.isBankingCardOpen) {
        this.isRetailCardOpen =  false;
        this.isLogisticsCardOpen =  false;
        this.isCommerceCardOpen =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleRetailCard() {
      this.isRetailCardOpen = !this.isRetailCardOpen;
      if (this.isRetailCardOpen) {
        this.isBankingCardOpen =  false;
        this.isLogisticsCardOpen =  false;
        this.isCommerceCardOpen =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleLogisticsCard() {
      this.isLogisticsCardOpen = !this.isLogisticsCardOpen;
      if (this.isLogisticsCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.isCommerceCardOpen =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleCommerceCard() {
      this.isCommerceCardOpen = !this.isCommerceCardOpen;
      if (this.isCommerceCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleProductionCard() {
      this.isProductionCardOpen = !this.isProductionCardOpen;
      if (this.isProductionCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isManagementCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleManagementCard() {
      this.isManagementCardOpen = !this.isManagementCardOpen;
      if (this.isManagementCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isProductionCardOpen = false;
        this.isAgricultureCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },
    toggleAgricultureCard() {
      this.isAgricultureCardOpen = !this.isAgricultureCardOpen;
      if (this.isAgricultureCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen =  false;
        this.isMedicalCardOpen = false;
      }
    },

    toggleMedicalCard() {
      this.isMedicalCardOpen = !this.isMedicalCardOpen;
      if (this.isMedicalCardOpen) {
        this.isBankingCardOpen =  false;
        this.isRetailCardOpen =  false;
        this.toggleLogisticsCard =  false;
        this.isCommerceCardOpen = false;
        this.isProductionCardOpen = false;
        this.isManagementCardOpen =  false;
        this.isAgricultureCardOpen = false;
      }
    },
  },
};
</script>

<style>
.banking-card h5 {
  color: #56c5b2;
}
.retail-card h5 {
  color: #ba4cf9;
}

.logistics-card h5 {
  color: #1f12fd;
}
.commerce-card h5 {
  color: #e8c91e;
}
.production-card h5 {
  color: #308cd3;
}
.management-card h5 {
  color: #1509d8;
}
.agriculture-card h5 {
  color: #99ca48;
}
.medical-card h5 {
  color: #dc3545;
}
</style>

