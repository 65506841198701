<template>
  <div class="container">
    <div class="section-image">
      <img
        style="max-width: 60%"
        src="../../assets/images/pixselect-academy-logo.svg"
        alt="shape"
      />
    </div>
    <div class="row pb-60">
      <div class="col-md-12 col-lg-12">
        <div class="it-support-card">
          <h3>
            It is the program we run in the financing of our business partners
            with whom we have agreed to help young talents
            who have not started their software career or have just started in
            line with the needs of the industry.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItSupportCard",
};
</script>
