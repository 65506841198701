<template>
    <div class="logistic-section-card-our-solution">
      <div class="container pb-20 pt-60">
        <div class="section-title section-title-lg">
          <h2>How It Works</h2>
        </div>
        <div class="row mb-100">
          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="logistic-info-our-solution">
                <img
                  src="../../assets/images/pricing-difference-1.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-1.png"
                  alt="shape"
                  class="logistic-img"
                />
                <div class="logistic-info-our-solution-text">
                  <h6>Import</h6>
                  <p>
                    Upload your data to our platform via API or Interface.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="logistic-info-our-solution">
                <img
                  src="../../assets/images/pricing-difference-2.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-2.png"
                  alt="shape"
                  class="logistic-img"
                />
                <div class="logistic-info-our-solution-text">
                  <h6>Training</h6>
                  <p>
                    We support different types of tagging and provide the interface to scale your data.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-8 col-lg-4 pb-30">
            <div class="contact-info-item">
              <div class="logistic-info-our-solution">
                <img
                  src="../../assets/images/pricing-difference-3.png"
                  alt="shape"
                />
                <img
                  src="../../assets/images/pricing-difference-icon-3.png"
                  alt="shape"
                  class="logistic-img"
                />
                <div class="logistic-info-our-solution-text">
                  <h6>Export</h6>
                  <p>
                    Use our export API or the export UI to integrate your data into your ML flows.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
