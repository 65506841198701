<template>
  <div>
    <Navbar class="bg-white" />
<PageTitleSaasEmployers/>

    <SaasEmployersCard />
    <SaasEmployersCardSecond />
    <SaasEmployersContact />
    <AiDeveloperCVMembership/>
    <PaymentCV/>
    <ClientsAcademy />
    <Footer />

  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar";
import PageTitleSaasEmployers from "../../SaasCvMembership/PageTitleSaasEmployers.vue";

import SaasEmployersCard from "../../SaasEmployers/SaasEmployersCard.vue";
import SaasEmployersCardSecond from "../../SaasEmployers/SaasEmployersCardSecond.vue";
import SaasEmployersContact from "../../SaasEmployers/SaasEmployersContact.vue";
import ClientsAcademy from "../../OurClients/ClientsAcademy.vue";
import AiDeveloperCVMembership from "../../AiDeveloper/AiDeveloperCVMembership.vue";
import PaymentCV from "../PixAcademyPages/PaymentCV.vue";
import Footer from "../../Layout/Footer";


export default {
  name: "SaasEmployers",
  components: {
    Navbar,
    PageTitleSaasEmployers,
    SaasEmployersCard,
    SaasEmployersCardSecond,
    SaasEmployersContact,
    AiDeveloperCVMembership,
    ClientsAcademy,
    PaymentCV,
    Footer
  },
};
</script>
