<template>
  <div class="inner-page-header">
    <div class="container">
      <div class="row">
        <div class="col-sm d-flex align-items-center">
          <div class="inner-header-content-academy">
            <h1>
              Turnstiles and Door Access <br />
              by Face
            </h1>
            <br />
            <h2>PIXPass Access & Log Solutions</h2>
            <br />
            <div>
              <router-link to="/demo-request" class="btn main-btn-academy">
                DEMO REQUEST
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <img
            class="goods-ai-warehouses-page-header-img"
            src="../../assets/images/count-people-header.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitleForTurnstilesFaceRecognition",
  props: ["pageTitle"],
};
</script>
