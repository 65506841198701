<template>
<div class="container">
        <div class="section-title section-title-lg">
        <h2>What Skills Do PIXSelect Academy Graduates Have?</h2>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h3>Full Range of Technical Skills</h3>
            </div>
            <div class="academy-card-brief">
              <p>
               HTML and CSS, JavaScript and OOP, Reactjs, Node.js, MongoDB, Testing, Asynchronous programming
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h3>Employability Skills</h3>
            </div>
            <div class="academy-card-brief">
              <p>
                Teamwork, Adaptation, Problem Solving, Communication and Continuous Learning Skills
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="academy-card">
            <div class="academy-card-title">
              <h3>Project Delivery Experience</h3>
            </div>
            <div class="academy-card-brief">
              <p>
               Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt, architecto?
              </p>
            </div>
          </div>
        </div>


  
      </div>
</div>
      
</template>

<script>
export default {

}
</script>

<style>

</style>