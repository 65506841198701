<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl mb-0">
        <h2>How do we overcome this problem with PIXCount People?</h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            With your PIXCount People platform, it is easy to count people in
            respect to get answer to above questions in retail stores, malls
            etc. Installed integrated CCTV cameras(see Approved Hardware
            section) with our GPU processors that work with AI algorithms or our
            newly installed AI cameras help you count people.
          </p>
        </div>
      </div>
      <div class="row pb-60">
        <div id="count-people-1" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-top">
            <img
              src="../../assets/images/goods-cameras-warehouses/left-top.png"
              alt="shape"
              class="icon-1"
            />
            <h6>
              Set a count area on the web dashboard where people have to be
              counted.
            </h6>
            <img
              src="../../assets/images/count-people/card-1.png"
              alt="shape"
              class="card-photo"
            />
          </div>
        </div>
        <div id="count-people-2" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-bottom">
            <div class="count-people-schema-card-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/left-bottom.png"
                alt="shape"
                class="icon-1"
              />
              <h6>AI cameras detect and count people.</h6>
            </div>
            <img
              src="../../assets/images/count-people/card-2.png"
              alt="shape"
            />
          </div>
        </div>
        <div id="count-people-3" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-top">
            <img
              src="../../assets/images/goods-cameras-warehouses/right-top.png"
              alt="shape"
              class="icon-2"
            />
            <h6>
              Every count data sent to central web server on a specific time
              interval.
            </h6>
            <img
              src="../../assets/images/count-people/card-3.png"
              alt="shape"
              class="card-photo"
            />
          </div>
        </div>
        <div id="count-people-4" class="col-8 col-md-6 col-lg-3">
          <div class="count-people-schema-card-bottom">
            <img
              src="../../assets/images/goods-cameras-warehouses/right-bottom.png"
              alt="shape"
              class="icon-2"
            />
            <h6>
              Clients with different hiearchy level enter and get their reports
            </h6>
            <img
              src="../../assets/images/count-people/card-4.png"
              alt="shape"
              class="card-photo-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCountPeopleSecond",
};
</script>
<style scoped></style>
