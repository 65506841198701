<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          How do we overcome this problem with PIXCount Shelves by AI Cameras?
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            With your PIXCount Shelves by AI Cameras, your software with cutting
            edge technology AI algorithms, it is easy to detect your goods in
            warehouses’, factories’ shelves. Newly installed AI cameras on
            shelves allows you monitor your goods on shelves after acceptance of
            these goods. This AI process helps you automatically detect every
            pallette, package, box.
          </p>
        </div>
      </div>
      <div class="section-title section-title-xl">
        <h2>Process of Shelf Monitoring In Warehouses</h2>
      </div>

      <div class="row pb-60">
        <div class="col-12 col-md-12 col-lg-12 d-flex justify-content-end">
              <img
                src="../../assets/images/shelves-cameras-warehouses/schema.png"
                alt="shape"
              />
        </div>
      </div>
  

    </div>
  </div>
</template>

<script>
export default {
  name: "CardShelvesCamerasWarehousesSecond",
};
</script>
