<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-xl">
        <h2>
          The power and efficiency of PIXSelect Vision AI, provides solutions
          for
          <br />
          logistics industry; factories, warehouses such as
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="logistic-card">
            <div class="logistic-card-info-img">
              <img
                src="../../assets/images/logistic/power-efficiency-card-1.png"
                alt="shape"
              />
            </div>
            <div class="logistic-card-info">
              <h5>Security for Whole Logistics Operations</h5>
              <p>
                PIXPass allows you to control and log your personnel, truck
                driver, truck plates.

                <br />
                <br />
                PIXSVMS Logistics allows you to control all cctv devices and
                recordings of your devices instantly. Also you can instantly
                monitor your recordings & live footage in details with
                electronic zoom option.
              </p>
              <img src="../../assets/images/1.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="logistic-card">
            <div class="logistic-card-info-img">
              <img
                src="../../assets/images/logistic/power-efficiency-card-2.png"
                alt="shape"
              />
            </div>

            <div class="logistic-card-info">
              <h5>Ramp Operations</h5>
              <p>
                PIXCount Goods by Barcode&QR allows you to control and log your
                inventories during acceptance/delivery operation.
                <br />
                <br />
                PIXCount Goods by AI Cameras helps you to automate counting of
                goods during acceptance of inventories on ramps. Also snapshots
                are recorded for evidence.
              </p>
              <img src="../../assets/images/2.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="logistic-card">
            <div class="logistic-card-info-img">
              <img
                src="../../assets/images/logistic/power-efficiency-card-3.png"
                alt="shape"
              />
            </div>

            <div class="logistic-card-info">
              <h5>Warehouse Operations</h5>
              <p>
                PIXCount Goods by Barcode&QR allows you to control and log your
                inventories during restorage on warehouse stage.
                <br />
                <br />
                PIXCount Goods on Shelves allows you to monitör your inventories
                online and helps logistic professionals to do their operations
                without any fault. System instantly checks and monitors
                inventories by vision AI.
              </p>
              <img src="../../assets/images/3.png" alt="shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogisticPowerEfficiency",
};
</script>
