<template>
    <div>
      <Navbar class="bg-white" />
      <PageTitleSaasCloudTagMembership />
      <AiDeveloperCardSaasCloudTagMembership />
      <SaasCloudTagMembershipCard />
      <AiDeveloperWhyChoose />
      <br>
      <br>
      <ProcessAcademyForSaasCloud />
      <!-- <SaasCloudOurSolution/> -->
      <AiDeveloperContributions2/>
      <AiDeveloperWhyChoose2 />
      <PaymentFullTag/>
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../../Layout/Navbar";
  import PageTitleSaasCloudTagMembership from "../../SaasCvMembership/PageTitleSaasCloudTagFullMembership.vue"
  import AiDeveloperCardSaasCloudTagMembership from "../../AiDeveloper/AiDeveloperCardSaasCloudTagFullMembership.vue";
  import SaasCloudTagMembershipCard from "../../SaasEmployers/SaasCloudTagFullMembershipCard.vue"
  import AiDeveloperWhyChoose from "../../AiDeveloper/AiDeveloperWhyChoose3.vue";
  import AiDeveloperWhyChoose2 from "../../AiDeveloper/AiDeveloperWhyChoose2.vue";
  import AiDeveloperContributions2 from "../../AiDeveloper/AiDeveloperContributions2.vue";
  import ProcessAcademyForSaasCloud from "../../HomeTwo/ProcessAcademyForSaasCloud.vue"
//   import SaasCloudOurSolution from "../../Logistic/SaasCloudOurSolution.vue";
  import PaymentFullTag from "../PixAcademyPages/PaymentFullTag.vue";
  
  import Footer from "../../Layout/Footer";
  
  export default {
    name: "SaasCvMembership",
    components: {
      Navbar,
  
      PageTitleSaasCloudTagMembership,
  
      AiDeveloperCardSaasCloudTagMembership,
  
      AiDeveloperWhyChoose,
      AiDeveloperWhyChoose2,
      AiDeveloperContributions2,
      ProcessAcademyForSaasCloud,
  
      SaasCloudTagMembershipCard,
      PaymentFullTag,
    //   SaasCloudOurSolution,
  
      Footer,
    },
  };
  </script>
  