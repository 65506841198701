<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 count-people-card-info-img">
        <img
          src="../../assets/images/count-people/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXCount People?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p style="line-height: 2.1">
              It has been created as a practical solution for counting people to
              have a performance indicator in retail stores. In our system,
              which is compatible with the Cashier-POS system data, the process
              workflow of data such as flow of people outside, the flow inside,
              fitting room and cashier data is seen to manage every process.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>Why do you need PIXCount People?</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This product is needed in retail stores to increase sales figures
              and revenue. The only data is taken from Cashier&Pos Systems
              however retail managers are wishing to see the problems of the
              workflow. Is Retail Branch location right or not? - They have to
              get flow data outside the brach. Is there a display window
              problem, are there enough people entering inside? - They have to
              get this count data. Are sales people performing well, entering
              people performance is good but trial numbers are less - They have
              to get data from this fitting room count data. People trial
              numbers is ok but conversion to sales figures is less. Any pricing
              problem occurs on this region? -They have to get fittingvroom
              entrance data and compare it with cashier-pos sales figures.
            </p>
          </div>
        </div>
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/1.png"
              alt="shape"
            />
            <div class="count-people-info-header-text">
              <h6>Potential data of people passing by the store?</h6>
            </div>
          </div>
        </div>
     
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/3.png"
              alt="shape"
            />
            <div class="count-people-info-header-text">
              <h6>People actual data passing fitting room area?</h6>
            </div>
          </div>
        </div>
     
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/5.png"
              alt="shape"
            />
            <div class="count-people-info-header-text">
              <h6>Time spent in store’s specific areas.</h6>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-12 col-lg-6"
      >
        <img
          src="../../assets/images/count-people/photo-card-2.png"
          alt="shape"
          style="padding: 20px 0px 0px 0px; height: 725px"
        />
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/2.png"
              alt="shape"
            />
            <div class="count-people-info-header-text">
              <h6>People actual data entering inside the store?</h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item pt-20">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-ramps/4.png"
              alt="shape"
            />
            <div class="count-people-info-header-text">
              <h6>
                Cashier - POS sales data in details combined with<br />
                above data
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCountPeople",
};
</script>
