<template>
    <div class="process-section pt-90 pb-70">
      <div class="container">
        <div class="section-title section-title-lg">
          <h2>Who is it Made for? </h2>
        </div>
        <div class="process-content">
          <div class="row justify-content-center">
            <div class="col-md-6 col-lg-4 pb-30">
              <div class="process-item process-item-center border">
                <div class="process-thumb">
                  <img src="../../assets/images/icon-1.png" alt="shape" />
                </div>
                <div class="process-text">
                  <h3>SME and SME Technology Development Center Companies</h3>
                  <p>
                    They will have low-cost options such as cloud-based AI product labeling, product development, hosting their data and uploading these products to our 'GPU-powered edge devices'.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 pb-30">
              <div class="process-item process-item-center border">
                <div class="process-thumb">
                  <img src="../../assets/images/icon-2.png" alt="shape" />
                </div>
                <div class="process-text">
                  <h3>Startups</h3>
                  <p>
                    They will have low-cost options such as cloud-based AI product labeling, product development and uploading these products to our 'GPU-powered edge devices'.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 pb-30">
              <div class="process-item process-item-center border">
                <div class="process-thumb">
                  <img src="../../assets/images/icon-3.png" alt="shape" />
                </div>
                <div class="process-text">
                  <h3>Universities</h3>
                  <p>
                    They will be able to take their projects from tagging, data training, installing software products on devices as an end-to-end cloud solution or On Premise solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Process",
  };
  </script>
  
  <style scoped>
.process-item.border {
  padding: 30px;
  background-color: #fff;
  max-height: 300px;
  height: 350px !important;
  border-radius: 10px;
}

  </style>