<template>
  <div class="container pt-60 pb-60">
    <div class="section-title section-title-xl">
      <h2>PRODUCTS</h2>
      <div class="home-page-partner">
        <p>
          As PIXSelect, we determine the video needs of our customers and
          introduce our smart management platform through existing CCTV systems.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-product-card">
          <img
            src="../../assets/images/homepage/product-item-1.png"
            alt="shape"
          />
          <img
            src="../../assets/images/homepage/product-item-1-hover.png"
            alt="shape"
            class="hover-icon"
          />
          <h5>PIXSVMS CCTV Monitoring Solutions</h5>
          <p>
            It has been created as a sophisticated solution in Retail for
            professionals; to detect and control all cctv devices and to log
            every operations done on these devices.
          </p>
          <img
            src="../../assets/images/homepage/product-item-line.png"
            alt="shape"
            class="line-icon"
          />
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-product-card">
          <img
            src="../../assets/images/homepage/product-item-2.png"
            alt="shape"
          />
          <img
            src="../../assets/images/homepage/product-item-2-hover.png"
            alt="shape"
            class="hover-icon"
          />
          <h5>PIXPass Access & Log Solutions</h5>
          <p>
            It has been created as a sophisticated software service (combined
            with hardware) solution to get logs when entering – exiting areas.
          </p>
          <img
            src="../../assets/images/homepage/product-item-line.png"
            alt="shape"
            class="line-icon"
          />
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-4 mb-20">
        <div class="home-page-product-card">
          <img
            src="../../assets/images/homepage/product-item-3.png"
            alt="shape"
          />
          <img
            src="../../assets/images/homepage/product-item-3-hover.png"
            alt="shape"
            class="hover-icon"
          />
          <h5>PIXAI Artificial Intelligence Solutions</h5>
          <p>
            It has been created as a practical solution for counting and control
            processes during the transport of goods from one place to another.
          </p>
          <img
            src="../../assets/images/homepage/product-item-line.png"
            alt="shape"
            class="line-icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Products",
};
</script>
