<template>
  <div class="retail-section-card-our-solution">
    <div class="container pt-60 pb-30">
      <div class="section-title section-title-lg">
        <h2>Our Solutions Are Different</h2>
      </div>
      <div class="row mb-100">
        <div class="col-12 col-sm-8 col-lg-4 pb-30">
          <div class="contact-info-item">
            <div class="retail-info-our-solution">
              <img
                src="../../assets/images/pricing-difference-1.png"
                alt="shape"
              />
              <img
                src="../../assets/images/pricing-difference-icon-1.png"
                alt="shape"
                class="retail-img"
              />
              <div class="retail-info-our-solution-text">
                <h6>Easy Integration</h6>
                <p>
                  You can integrate your cashier & pos data with CCTV records of
                  every operation.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-8 col-lg-4 pb-30">
          <div class="contact-info-item">
            <div class="retail-info-our-solution">
              <img
                src="../../assets/images/pricing-difference-2.png"
                alt="shape"
              />
              <img
                src="../../assets/images/pricing-difference-icon-2.png"
                alt="shape"
                class="retail-img"
              />
              <div class="retail-info-our-solution-text">
                <h6>Easy Setup & Activation</h6>
                <p>
                  Our solution uses both CCTV cameras and our newly offered AI
                  cameras.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-8 col-lg-4 pb-30">
          <div class="contact-info-item">
            <div class="retail-info-our-solution">
              <img
                src="../../assets/images/pricing-difference-3.png"
                alt="shape"
              />
              <img
                src="../../assets/images/pricing-difference-icon-3.png"
                alt="shape"
                class="retail-img"
              />
              <div class="retail-info-our-solution-text">
                <h6>Multioptional Architecture</h6>
                <p>
                  Our solution is on cloud & also on premise. You can choose
                  according to your needs. Also you have online-offline solution
                  options for your single or distributed branches.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlogCountPeople />
    </div>
  </div>
</template>

<script>
import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";
export default {
  name: "RetailOurSolution",
  components: {
    BlogCountPeople,
  },
};
</script>
