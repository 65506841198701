<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/shelves-cameras-stores/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXCount Shelves by AI Cameras?</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              It has been created as a practical solution in Stores for
              detecting and controlling all products, on store shelves.
            </p>
            <br />
            <p>
              In our system, which is compatible with the ERP system, on every
              frequency(such as 10 minutes), the shelves are detected whether
              the planogram is proper or not and the products are detected and
              at the same time our AI algorithm counts these products on the
              shelves. If a defined shortage of products is defined, our
              software also checks if there are inventories in store warehouses
              and sends sms to warehouse operators. If there is no inventory in
              store warehouse, our software at the same time checks if there is
              order or not; sends alarm e-mail to purchasing department officer
              at the head office.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>Why do you need PIXCount Shelves by AI Cameras?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This software product is needed in Stores. Every operations have
              to check their shelves and shelves planogram on daily basis with
              multiple frequencies. However this is done with people; by reading
              Barcode, this process is done manually. And every shelf has to be
              checked when there is a gap. But how do any operation in stores
              manage this process on every 10 minutes? If this is not done
              without our software, you have to;
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/shelves-cameras-stores/photo-card-2.png"
          alt="shape"
          style="margin-top: 15px"
        />
      </div>
    </div>

    <div class="row pt-60 pb-60">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/shelves-cameras-warehouses/photo-card-3.png"
          alt="shape"
          style="padding: 0px 0px 0px 18px"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6 d-grid justify-content-center">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>Assign people to every area in Stores</h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                These people have to check shelves<br />
                manually on every frequency.
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Use a software to verify whether there<br />
                is a gap; every product is checked by these<br />
                workers and the shelves are fulfilled after <br />
                this inspection.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardShelvesCamerasStores",
};
</script>
