<template>
  <div class="inner-page-header pb-0">
    <div class="container">
      <div class="row">
        <div class="col-sm d-flex align-items-center">
          <div class="inner-header-content-academy">
            <h1>{{ pageTitle }}</h1>
            <h2>Frontend - Backend Training Program</h2>
            <br/>
            <h3>
              It is an 8-week intensive training program that trains <br />
              software developers according to the needs of companies.
            </h3>
            <br />
            <div>
              <router-link
                to="/pixacademy-registration"
                class="btn main-btn-academy"
              >
                Join Now
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <img
            class="goods-ai-warehouses-page-header-img"
            src="../../assets/images/academy1.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["pageTitle"],
};
</script>
