<template>
  <div class="container pb-30">
    <div class="row pt-60">
      <div class="col-12">
        <img src="../../assets/images/saas-candidates/career.png" />
      </div>
      <div class="section-image">
        <img
          style="max-width: 60%"
          src="../../assets/images/pixselect-academy-logo.svg"
          alt="shape"
        />
      </div>
    </div>
  </div>
  <div class="retail-section-card-our-solution">
    <div class="container pb-30">
      <div class="row">
        <div class="col-12">
          <div class="saas-info-card">
            <p>
              Welcome! <br /><br />

              We will prepare you with the education and employment skills that
              will make you an indispensable software developer in the world.<br /><br />

              As PIXSelect Academy, we focus on growing the software community
              in Turkey and empower our business partners with the right talents
              to realize their potential.<br /><br />

              We are aware that the traditional approach to recruitment does not
              realize their potential and is a waste of time.<br /><br />

              As PIXSelect Academy, we have implemented a modern, reliable
              recruitment service model that focuses on speed and quality.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaasCandidatesCard",
};
</script>
