<template>
  <div class="container">
    <div
      style="background-image: linear-gradient(to right, #852dee, #5044ff)"
      class="inner-page-header-academy"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-9 col-lg-9">
            <div class="inner-header-content-demo-request">
              <h2>
                PIXCoding - IT Support Specialist Training and Employment
                Program
              </h2>
              <h3>Start your IT Support Specialist career now!</h3>
              <br />
              <div class="pl-4 mb-10">
                <router-link
                  to="/pixacademy-registration"
                  class="btn main-btn-academy"
                >
                  Join Now
                </router-link>
              </div>
            </div>
          </div>

          <div class="col-sm col-lg d-flex justify-content-center">
            <img
              class="demo-request-img"
              src="../../assets/images/icon-4.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
