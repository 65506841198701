<template>
  <div class="container pt-60">
    <div class="section-title section-title-lg">
      <h2>Usage Areas</h2>
    </div>
    <div class="row count-people-info-text pb-60">
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-1.png"
            alt="partner"
          />
          <h6>Building Entrances</h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-2.png"
            alt="partner"
          />
          <h6>Plazas</h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-3.png"
            alt="partner"
          />
          <h6>Factory Entrances</h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-4.png"
            alt="partner"
          />
          <h6>Technopark Areas</h6>
        </div>
      </div>
      <div class="col-12 col-md-4 col-lg pb-30">
        <div class="usage-areas-card">
          <img
            src="../../assets/images/turnstiles-id-cards/photo-5.png"
            alt="partner"
          />
          <h6>FreeZone Areas</h6>
        </div>
      </div>
    </div>
    <BlogCountPeople />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import BlogCountPeople from "../SingleBlog/BlogCountPeople.vue";

export default defineComponent({
  name: "ClientsTurnstilesFaceRecognition",
  components: {
    BlogCountPeople,
  },
});
</script>
