<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-lg">
        <h2>Use Cases</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase">
            <img src="../../assets/images/retail/usecase-1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase-title">
            <h4>
              IN-STORE SECURITY OPERATIONS REAL-TIME HEALTH & DIAGNOSTIC
              MONITORING
            </h4>
          </div>
          <div class="retail-card-usecase-problem">
            <div class="retail-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="retail-card-usecase-security">
              <p>
                Our Retail Customer has distributed branches. Monitoring of
                devices is a real problem; because sometimes devices are out of
                order and when any incident happends; camera records cannot be
                accessed.
              </p>
            </div>
          </div>
          <div class="retail-card-usecase-solution">
            <div class="retail-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="retail-card-usecase-security">
              <p>
                Our PIXSVMS Retail solution automatically accesses every devices
                & collects data very one hour. Any types of diagnostic alarm
                such as video loss, HDD error, parameter changes, happen; our
                system allows you get an alarm mail. Also administrators can
                access every branch CCTV live view & records; download them with
                a schedule. This helps administrators to have a better control
                on their stores accross the country.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase">
            <img src="../../assets/images/retail/usecase-2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase-title">
            <h4>IN-STORE OPERATIONS - REAL TIME VISITOR COUNTING</h4>
          </div>
          <div class="retail-card-usecase-problem">
            <div class="retail-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="retail-card-usecase-visitor">
              <p>
                Our Retail Customer has a building; which have four floors &
                terrace. On each floor there are also shops. Our customer wants
                to see visitor data on evey floor, main entrance & shop
                entrance.
              </p>
            </div>
          </div>
          <div class="retail-card-usecase-solution">
            <div class="retail-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="retail-card-usecase-visitor">
              <p>
                Our PIXCount People solution offers visitor count data; see
                heatmap layout of shops. Also system allows capacity check alarm
                when capacity exceeded. Daily, monthly graphs combined with
                weather & traffic data allows our customer to monitor their
                performance.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase">
            <img src="../../assets/images/retail/usecase-3.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6 pb-30">
          <div class="retail-card-usecase-title">
            <h4>IN-STORE OPERATIONS - COUNT & MONITOR GOODS ON SHELVES</h4>
          </div>
          <div class="retail-card-usecase-problem">
            <div class="retail-card-usecase-title">
              <h6>PROBLEM</h6>
            </div>
            <div class="retail-card-usecase-count">
              <p>
                Our Retail Customer is usign operation personnel to check
                whether there is a decrease of goods on shelves. However for one
                branch; mininmum 2 personnel check with frequency of two hours.
                This operation is costly and inefficient.
              </p>
            </div>
          </div>
          <div class="retail-card-usecase-solution">
            <div class="retail-card-usecase-title">
              <h6>SOLUTION</h6>
            </div>
            <div class="retail-card-usecase-count">
              <p>
                Our PIXCount Goods by AI Cameras on Market Shelves; installed on
                shelves, monitors and counts every goods on market shelves. With
                a ten minutes frequency; system detects and counts goods and
                checks whether there is any gap; alarms it with an e-mail. Also
                planogram is detected & compared with the default plan; any
                change is reported.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RetailUseCase",
};
</script>
