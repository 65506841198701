<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img src="../../assets/images/bank-cctv/photo-card-1.png" alt="shape" />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXSVMS Banks & ATM CCTV Monitoring?</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              It has been created as a sophisticated solution in Banks for
              security professionals; to detect and control all cctv devices and
              to log every operations done on these devices.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>Why do you need PIXSVMS Banks & ATM CCTV Monitoring?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This software product is needed in Banks Branches and ATM’s. Every
              security operators have to control every cctv devices and when
              there is an incident happens, according to Banking Regulatory
              Supervision Offices; they have to report these incidents.
            </p>
            <br />
            <p>
              This operation is really complicated without a non-compliant
              software.
            </p>
          </div>
        </div>
        <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img
                src="../../assets/images/goods-cameras-warehouses/difference-1.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>All cctv alarms have to be monitored instantly</h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img
                src="../../assets/images/goods-cameras-warehouses/difference-2.png"
                alt="shape"
              />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  You have to define and report the exact and detailed
                  diagnostic when there is;
                </h6>
                <p>
                  - Video Loss <br />
                  - Video Tamper<br />
                  - Video Record has stopped<br />
                  - Video Parameters have changed<br />
                  - Scene Change On Camera
                </p>
              </div>
            </div>
          </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/bank-cctv/photo-card-2.png"
          alt="shape"
          style="height: 815px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardBankCctv",
};
</script>
