<template>
  <div class="container">
    <div class="section-image">
      <img
        style="max-width: 60%"
        src="../../assets/images/pixselect-academy-logo.svg"
        alt="shape"
      />
    </div>
    <div class="row pb-60">
      <div class="col-md-12 col-lg-12">
        <div class="it-support-card">
          <h3>
            AI Follow-up Programme offers a comprehensive learning management system (LMS) that helps companies create, develop and manage their own AI teams. With this innovative platform, you empower your team members with the latest AI developments and gain a high-tech competitive advantage.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AiDeveloperCard",
};
</script>
