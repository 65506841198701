<template>
    <div class="bg-off-white">
      <div class="partner-section pt-60 pb-60">
        <div class="container">
          <div class="section-title section-title-lg">
        <h2>Technology Partners</h2>
      </div>
          <div class="partner-carousel">
            <carousel
              :autoplay="4000"
              :settings="settings"
              :breakpoints="breakpoints"
              :wrap-around="true"
            >
              <slide v-for="slide in carouselItems" :key="slide.id">
                <div class="item">
                  <img style="width: 200px" :src="slide.image" alt="partner" />
                </div>
              </slide>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import { Carousel, Slide } from "vue3-carousel";
  
  import "vue3-carousel/dist/carousel.css";
  
  export default defineComponent({
    name: "OurClients",
    components: {
      Carousel,
      Slide,
    },
    data: () => ({
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      carouselItems: [
        {
          id: 1,
          image: require("../../assets/images/pricing-partner/hikvision.png"),
        },
        {
          id: 2,
          image: require("../../assets/images/pricing-partner/hanwha-samsung.png"),
        },
        {
          id: 3,
          image: require("../../assets/images/pricing-partner/onvif.png"),
        },
        {
          id: 4,
          image: require("../../assets/images/pricing-partner/zebra.png"),
        },
        {
          id: 5,
          image: require("../../assets/images/pricing-partner/assa_abloy.png"),
        },
      ],
      breakpoints: {
        0: {
          itemsToShow: 1,
          snapAlign: "left",
        },
        768: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        992: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1200: {
          itemsToShow: 5,
          snapAlign: "center",
        },
      },
    }),
  });
  </script>
  
  <style scoped>
  .partner-carousel .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
  }
  .partner-carousel {
    margin-top: 60px;
    border-width: 1px;
    border-color: rgb(211, 211, 211);
    border-style: solid;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgb(225, 228, 237, 0.04);
  }
  
  .partner-carousel .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
  }
  .carousel__slide {
    padding: 5px;
  }
  
  .carousel__viewport {
    perspective: 2000px;
  }
  
  .carousel__track {
    transform-style: preserve-3d;
  }
  
  .carousel__slide--sliding {
    transition: 0.5s;
  }
  
  .carousel__slide {
    opacity: 0.9;
    transform: rotateY(-20deg) scale(0.9);
  }
  
  .carousel__slide--active ~ .carousel__slide {
    transform: rotateY(20deg) scale(0.9);
  }
  
  .carousel__slide--prev {
    opacity: 1;
    transform: rotateY(-10deg) scale(0.95);
  }
  
  .carousel__slide--next {
    opacity: 1;
    transform: rotateY(10deg) scale(0.95);
  }
  
  .carousel__slide--active {
    opacity: 1;
    transform: rotateY(0) scale(1.2);
    transition: transform 0.6s ease-in-out;
  }
  </style>
  