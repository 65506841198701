<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>
          How do we overcome this problem with PIXCount Goods by AI Cameras?
        </h2>
        <div class="goods-ai-warehouses-shema-text">
          <p>
            With your PIXCount Goods by AI Cameras, your software with cutting
            edge technology AI algorithms, it is easy to count your goods in
            warehouses, factories. Installed integrated CCTV cameras(see
            Approved Hardware section) with our GPU processors that work with AI
            algorithms or our newly installed AI cameras help you count goods.
            This AI process helps you automatically count and verify goods
            counting.
          </p>
        </div>
      </div>
      <div class="row pb-60">
        <div class="col-12 col-md-6 col-lg-3">
          <div class="goods-card-left-top">
            <div class="goods-card-left-top-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/left-top.png"
                alt="shape"
              />
              <h6>
                Set a count area on the web dashboard where goods have to be
                counted.
              </h6>
            </div>
          </div>
          <div class="goods-card-left-bottom mt-20">
            <div class="goods-card-left-bottom-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/left-bottom.png"
                alt="shape"
              />
              <h6>AI cameras detect and count goods.</h6>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-12 col-lg-6 p-50 d-flex justify-content-center align-items-center"
        >
          <div class="goods-card-center-item">
            <h6>
              Entire process is also matched with records of cctv according to
              start – end time of counting logs
            </h6>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <div class="goods-card-right-top">
            <div class="goods-card-right-top-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/right-top.png"
                alt="shape"
              />
              <h6>
                AI cameras take evidence snapshots after counting every object.
              </h6>
            </div>
          </div>
          <div class="goods-card-right-bottom mt-20">
            <div class="goods-card-right-bottom-info">
              <img
                src="../../assets/images/goods-cameras-warehouses/right-bottom.png"
                alt="shape"
              />
              <h6>Process finishes after every goods pass inside warehouse.</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsCamerasWarehousesSecond",
};
</script>
