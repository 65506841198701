<template>
  <div class="pt-60 pb-60 bg-off-white">
    <div class="container">
      <div class="section-title section-title-xl">
        <h2>
          The power and efficiency of PIXSelect vision AI, can provide
          <br />
          solutions for the retail industry today, such as:
        </h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="retail-card">
            <div class="retail-card-info-img">
              <img
                src="../../assets/images/retail/power-efficiency-card-1.png"
                alt="shape"
              />
            </div>
            <div class="retail-card-info">
              <h5>Warehouse Operations</h5>
              <p>
                PIXCount Goods by Barcode/QR helps you counting your inventory
                anytime. PIXCount Goods by AI cameras helps you count your goods
                automatically.
              </p>
              <img src="../../assets/images/1.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="retail-card">
            <div class="retail-card-info-img">
              <img
                src="../../assets/images/retail/power-efficiency-card-2.png"
                alt="shape"
              />
            </div>
            <div class="retail-card-info">
              <h5>In-Store Operations</h5>
              <p>
                PIXCount People helps you gaining data such as entering visitor,
                heatmap of store layout. PIXCount Shelves by AI Cameras helps to
                get instart data & alarms when there is a loss of goods on
                market shelves.
              </p>
              <img src="../../assets/images/2.png" alt="shape" />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 pb-30">
          <div class="retail-card">
            <div class="retail-card-info-img">
              <img
                src="../../assets/images/retail/power-efficiency-card-3.png"
                alt="shape"
              />
            </div>

            <div class="retail-card-info">
              <h5>In-Store Security Operations</h5>
              <p>
                PIXSVMS - Retail allows you to monitor your cashier instantly
                with live view option, electronic zoom. Also you can get all
                diagnostic data automatically.
              </p>
              <img src="../../assets/images/3.png" alt="shape" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RetailPowerEfficiency",
};
</script>
