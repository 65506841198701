<template>
  <div class="home-page-header">
    <div class="container">
      <div class="partner-carousel">
        <carousel :autoplay="4000" :settings="settings" :wrap-around="true">
          <slide v-for="slide in carouselItems" :key="slide.id">
            <div class="row">
              <div
                class="col-12 col-md-6 col-lg-6 d-flex justify-content-start"
              >
                <img class="home-page-header-img" :src="slide.image" />
              </div>
              <div class="col-12 col-md-6 col-lg-6 d-flex align-items-center">
                <div class="inner-header-content-home">
                  <h1>
                    {{ slide.header1 }}
                    <br />
                    {{ slide.header2 }}
                  </h1>
                  <br />
                  <h3>
                    {{ slide.text1 }}
                    {{ slide.text2 }}
                    {{ slide.text3 }}
                  </h3>
                  <br />
                  <div class="pt-30 d-flex">
                    <router-link :to="slide.url" class="btn main-btn-academy">
                      {{ slide.btnTitle }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "MainBanner",
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    carouselItems: [
      {
        id: 1,
        image: require("../../assets/images/homepage/header.png"),
        header1: "PIXSVMS Bank's & ATM",
        header2: "CCTV Monitoring",
        text1: "It has been created as a sophisticated solution in Banks for",
        text2: "security professionals; to detect and control all cctv devices",
        text3: "and to log every operations done on these devices.",
        url: "/bank-cctv",
        btnTitle: "SOLUTIONS",
      },
      {
        id: 2,
        image: require("../../assets/images/homepage/header-2.png"),
        header1: "PIXSVMS Warehouse & Factory",
        header2: "CCTV Monitoring",
        text1: "It has been created as a sophisticated solution in Banks for",
        text2: "security professionals; to detect and control all cctv devices",
        text3: "and to log every operations done on these devices.",
        url: "/warehouses-cctv",
        btnTitle: "SOLUTIONS",
      },
      {
        id: 3,
        image: require("../../assets/images/homepage/header-3.png"),
        header1: "PIXCount Goods by",
        header2: "Barcode & QR on Ramps",
        text1: "It has been created as a practical solution for counting and",
        text2: "control processes during the transport of goods from one place",
        text3:
          "to another, especially in warehouses and production lines such as factories.",
        url: "/goods-barcode-ramps",
        btnTitle: "SOLUTIONS",
      },
      {
        id: 4,
        image: require("../../assets/images/homepage/header-4.png"),
        header1: "About Us",
        header2: "",
        text1: "As PIXSelect, we determine the video needs of our customers",
        text2: "and introduce our smart management platform through existing",
        text3: "CCTV systems.",
        url: "/about-us",
        btnTitle: "ABOUT US",
      },
    ],
  }),
});
</script>
