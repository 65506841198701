<template>
    <div class="pb-130">
      <div class="retail-section-card-our-solution mb-80">
        <div class="container pt-60">
          <div class="section-title section-title-xl">
            <h2>
                SaaS Cloud Tag Full Membership <br />
            
            </h2>
            <h4>We focused on the features needed to give you the best tagging experience.</h4>
          </div>
          <div class="row">
            <div class="col-12 col-lg-8 pb-30">
              <div class="contact-info-item mb-40">
                <div class="goods-ai-warehouses-info-header">
                  <img
                    src="../../assets/images/goods-cameras-warehouses/difference-1.png"
                    alt="shape"
                  />
                  <div class="goods-ai-warehouses-info-header-text">
                    <h6>
                      Fullfilling the task assigned by the supervisor on a weekly
                      basis,
                    </h6>
                  </div>
                </div>
              </div>
              <div class="contact-info-item mb-40">
                <div class="goods-ai-warehouses-info-header">
                  <img
                    src="../../assets/images/goods-cameras-warehouses/difference-2.png"
                    alt="shape"
                  />
                  <div class="goods-ai-warehouses-info-header-text">
                    <h6>
                      By reporting task results to PIXAI Academy supervisor on a
                      weekly basis,
                    </h6>
                  </div>
                </div>
              </div>
              <div class="contact-info-item mb-40">
                <div class="goods-ai-warehouses-info-header">
                  <img
                    src="../../assets/images/goods-cameras-warehouses/difference-3.png"
                    alt="shape"
                  />
                  <div class="goods-ai-warehouses-info-header-text">
                    <h6>Participating in project meetings for 1 hour a week,</h6>
                  </div>
                </div>
              </div>
              <div class="contact-info-item">
                <div class="goods-ai-warehouses-info-header">
                  <img
                    src="../../assets/images/goods-cameras-warehouses/difference-4.png"
                    alt="shape"
                  />
                  <div class="goods-ai-warehouses-info-header-text">
                    <h6>
                      By participating in a 15-minute scrum meeting with the team
                      on the day of work
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <BlogAiDeveloper /> -->
    </div>
  </template>
  
  <script>
  // import BlogAiDeveloper from "../SingleBlog/BlogAiDeveloperCV.vue";
  export default {
    name: "AiDeveloperContributions",
    components: {
      // BlogAiDeveloper,
    },
  };
  </script>
  