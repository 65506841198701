<template>
    <div>
      <Navbar class="bg-white" />
      <PartnerRequestForm />
      <PageTitleForPartnerRequest />
      <Footer />
    </div>
  </template>
  
  <script>
  import Navbar from "../Layout/Navbar";
  import PageTitleForPartnerRequest from "../PartnerRequest/PageTitleForPartnerRequest.vue"
  import PartnerRequestForm from "../PartnerRequest/PartnerRequestForm.vue";

  import Footer from "../Layout/Footer";
  
  export default {
    name: "DemoRequest",
    components: {
      Navbar,
      PageTitleForPartnerRequest,
      PartnerRequestForm,
      Footer,
    },
  };
  </script>
  