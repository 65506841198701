<template>
  <div class="container pt-60">
    <div class="section-title section-title-xl pb-30">
      <h2>Benefits for our Authorized Resellers</h2>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>Training</h5>
            <p>
              All our authorized resellers are trained online by our
              professionals.
            </p>
          </div>
          <img
            src="../../assets/images/1.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>Cloud Demo</h5>
            <p>
              No need to install anything. Our cloud demo is ready for our
              resellers.
            </p>
          </div>
          <img
            src="../../assets/images/2.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>Marketing Materials</h5>
            <p>
              Our authorized resellers gain access to our marketing materials.
            </p>
          </div>
          <img
            src="../../assets/images/3.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>

    <div class="row pb-30">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>Well-Trained IT Resources</h5>
            <p>
              Our authorized resellers have the opportunity to work with IT
              Resources; trained by our PIXSelect Academy Trainers.
            </p>
          </div>
          <img
            src="../../assets/images/4.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>Continous Income</h5>
            <p>
              Our SAAS solution paid monthly gives our Authorize Resellers a
              continous income.
            </p>
          </div>
          <img
            src="../../assets/images/5.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="partners-card-features-general">
          <div class="partners-card-features">
            <h5>Easy & Simple Subscription</h5>
            <p>
              With our cloud platform your customers will be ready to use our
              software quickly.
            </p>
          </div>
          <img
            src="../../assets/images/6.png"
            alt="shape"
            class="partners-card-features-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BenefitsResellers",
};
</script>
