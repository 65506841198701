<template>
  <div class="bg-off-white">
    <div class="container pt-60">
      <div class="section-title section-title-xl">
        <h2>PIXCount Goods by AI Cameras Features</h2>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>Truck's Detection</h5>
              <p>
                Our AI Camera Detects all movements of trucks approaching
                warehouse ramps such as 'Truck Entered', 'Truck Doors Open' &
                'Truck Doors Closed'.
              </p>
            </div>
            <img src="../../assets/images/1.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>Truck Logs</h5>
              <p>
                Our AI Camera detects and logs of all truck's arrival time,
                departure time and truck's doors opening times.
              </p>
            </div>
            <img src="../../assets/images/2.png" alt="shape" />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>Truck Duration</h5>
              <p>
                We provide the operation time of every truck on each ramp. You
                can easily monitor and compare of each truck acceptance
                operation.
              </p>
            </div>
            <img src="../../assets/images/3.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>Pallet-Package-Box Counting</h5>
              <p>
                Our AI Camera detects objects on ramps during acceptance of
                goods process. Our system also counts these objects and compares
                if any goods are missing or not.
              </p>
            </div>
            <img src="../../assets/images/4.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>SVMS Integration</h5>
              <p>
                The counting place is also matched with the CCTV camera, so the
                process of the counting process is matched with the CCTV video
                link.
              </p>
            </div>
            <img src="../../assets/images/5.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>Process Control</h5>
              <p>
                In case the counting is done one by one by AI camera, evidence
                is created by photographing the outer appearance of the counted
                parcel or pallet.
              </p>
            </div>
            <img src="../../assets/images/6.png" alt="shape" />
          </div>
        </div>
      </div>

      <div class="row pb-30">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>Number Plate Search</h5>
              <p>
                Optional Automatic Plate Recognition Camera can be added to your
                system.
              </p>
            </div>
            <img src="../../assets/images/7.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>Web Integration</h5>
              <p>
                Web dashboard search options by worker id, by plate, by company
                delivering goods, by warehouse id, by time
              </p>
            </div>
            <img src="../../assets/images/8.png" alt="shape" />
          </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
          <div class="goods-ai-warehouses-card">
            <div class="info-card">
              <h5>End-to-end Management</h5>
              <p>
                Every acceptance operation is seen on web dashboard with all
                data matched with snapshots of each good and with entire process
                cctv footage.
              </p>
            </div>
            <img src="../../assets/images/9.png" alt="shape" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardGoodsCamerasWarehousesThird",
};
</script>
