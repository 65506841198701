<template>
  <div class="container">
    <div
      style="background-image: linear-gradient(to right, #ac33ff, #3377ff)"
      class="inner-page-header-academy"
    >
      <div class="container">
        <div class="inner-header-content-academy" style="padding-top: 5px">
          <h2 style="padding-left: 4%">Frontend - Backend Training Program</h2>
          <h3 style="padding-left: 4%">Click the button to register now</h3>
          <br />
          <div style="padding-left: 5%">
            <router-link
              to="/pixacademy-registration"
              class="btn main-btn-academy"
            >
              Join Now
            </router-link>
          </div>
          <div style="position: absolute">
            <img
              style="
                width: 200px;
                margin-left: 475%;
                position: relative;
                top: -200px;
              "
              src="../../assets/images/icon-4.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
