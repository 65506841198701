<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/shelves-cameras-warehouses/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXCount Shelves by AI Cameras?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              It has been created as a practical solution in warehouses for
              detecting and controlling all pallettes and packages, boxes on
              warehouse shelves.
            </p>
            <br />
            <p>
              In our system, which is compatible with the ERP system, on every
              delivery, the delivery details of monitored goods on shelves to
              another company or a branch is verified and checked if this
              delivery is proper according to order details.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>Why do you need PIXCount Shelves by AI Cameras?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This software product is needed in warehouses, retail warehouses
              and factories. Delivering goods, accepting goods and stacking of
              goods are work processes in these areas.
            </p>
            <br />
            <p>
              However when delivering these goods, sometimes this is becoming a
              mess because of wrong delivery. If this happens; you can quickly
              (almost in seconds) find out if you have below answers;
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/shelves-cameras-warehouses/photo-card-2.png"
          alt="shape"
        />
      </div>
    </div>

    <div class="row pt-60 pb-60">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/shelves-cameras-warehouses/photo-card-3.png"
          alt="shape"
          style="padding: 0px 0px 0px 18px"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6 d-grid justify-content-center">
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-1.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>Whose product was it?</h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-2.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Are the goods on shelves monitored <br />
                continuously?
              </h6>
            </div>
          </div>
        </div>
        <div class="contact-info-item">
          <div class="goods-ai-warehouses-info-header">
            <img
              src="../../assets/images/goods-cameras-warehouses/difference-3.png"
              alt="shape"
            />
            <div class="goods-ai-warehouses-info-header-text">
              <h6>
                Is the delivery details of the order <br />
                checked automatically?
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardShelvesCamerasWarehouses",
};
</script>
