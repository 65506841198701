<template>
  <div class="inner-page-header pb-0">
    <div class="container">
      <div class="row">
        <div class="col-sm d-flex align-items-center">
          <div class="inner-header-content-academy">
            <h1>AI Follow-up Programme</h1>
            <h2>Develop Your AI Teams with an Innovative Training Platform</h2> <br>
            <h3>
              AI Follow-up Programme offers an AI Image Processing, Training and Product Preparation system designed to 
              support and empower companies' AI teams. Thanks to this innovative platform, you will be able to train your
              team members with the latest AI algorithms, create and track AI projects, and also manage your projects in
              the cloud, as well as turn them into products with edge processors equipped with the latest AI processors
              you will procure. Prepare your teams and your company for AI transformation!
            </h3>
            <br />
            <!-- <div>
              <router-link to="/pixacademy-registration" class="btn main-btn-academy">
                JOIN NOW
              </router-link>
            </div> <br> -->
          </div>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <img class="goods-ai-warehouses-page-header-img" src="../../assets/images/academy1.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitleAiDeveloper",
};
</script>
