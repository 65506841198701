<template>
  <div class="container pt-60">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/retail-cctv/photo-card-1.png"
          alt="shape"
        />
      </div>
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>What is PIXSVMS Retail CCTV Monitoring?</h4>
          </div>

          <div class="goods-ai-warehouses-card-info-text">
            <p>
              It has been created as a sophisticated solution in Retail for
              professionals; to detect and control all cctv devices and to log
              every operations done on these devices.
            </p>
            <br />
            <p>
              Also for inventory management and monitoring of every operation,
              PIXSVMS Retail CCTV Monitoring enables many features such as live
              and record viewing, electronic zoom, snapshot and logs with
              snapshot options.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-60">
      <div class="col-sm d-flex justify-content-center">
        <img
          src="../../assets/images/goods-cameras-warehouses/seperator.png"
          alt="shape"
        />
      </div>
    </div>
    <div class="row pb-60">
      <div class="col-12 col-md-12 col-lg-6">
        <div class="goods-ai-warehouses-card-info">
          <div class="goods-ai-warehouses-card-info-header">
            <h4>Why do you need PIXSVMS Retail CCTV Monitoring?</h4>
          </div>
          <div class="goods-ai-warehouses-card-info-text">
            <p>
              This software product is needed in Retail Stores. Every security
              operators have to control every cctv devices and when there is an
              incident happens, according to Police Officials; they have to
              report these incidents of cctv records.
            </p>
            <br />
            <p>
              This operation is really complicated without a non-compliant
              software.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-6 goods-ai-warehouses-card-info-img">
        <img
          src="../../assets/images/retail-cctv/photo-card-2.png"
          alt="shape"
        />
      </div>

      <div class="row pt-30">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/1.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>All cctv alarms have to be monitored instantly</h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img src="../../assets/images/2.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  All cameras can be monitored hierarchically and not only been
                  used by security professionals but can also be used by
                  operation personnel such as marketing people with their
                  individual account.
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/3.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  Many users should have access to the system without affecting
                  the security system architecture and mechanism.
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img src="../../assets/images/4.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  You have to define and report the exact and detailed
                  diagnostic when there is;
                </h6>
                <p>
                  - Video Loss <br />
                  - Video Tamper<br />
                  - Video Record has stopped<br />
                  - Video Parameters have changed<br />
                  - Scene Change On Camera
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header bank-cctv-info-header">
              <img src="../../assets/images/5.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  When needed Cctv records can be managed and downloaded easily
                  from head office without affecting sales operations in
                  braches.
                </h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/6.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>Cctv records have to be monitored in details</h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/7.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>Cashier & POS data can be monitored with cctv footage</h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/8.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>Every movement is logged on the system</h6>
              </div>
            </div>
          </div>
          <div class="contact-info-item pt-10">
            <div class="goods-ai-warehouses-info-header">
              <img src="../../assets/images/9.png" alt="shape" />
              <div class="goods-ai-warehouses-info-header-text">
                <h6>
                  You can get alarms when there is a change in camera resolution
                  parameters.
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardRetailCctv",
};
</script>
